import React from "react";
import { useDispatch } from "react-redux";
import { Input, Button, Tooltip } from "antd";
import {
  LeftOutlined,
  SearchOutlined,
  DeploymentUnitOutlined,
} from "@ant-design/icons";
import { toggleOpenTree } from "../../slice";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  search: {
    "& > span": {
      color: "#a7a9ab",
    },
  },
  scada: {
    "& .anticon": {
      color: "#a7a9ab",
    },
  },
}));

export const Header = ({ search, setSearch, isChecked, setIsChecked }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleSearch = (event) => {
    setSearch(event.target.value);
  };

  const toggleChecked = () => {
    setIsChecked(!isChecked);
  };

  return (
    <div style={{ display: "flex", gap: 10, position: "relative" }}>
      <Input
        className={classes.search}
        prefix={<SearchOutlined />}
        allowClear
        defaultValue=""
        value={search}
        style={{ marginBottom: 15, borderRadius: 5 }}
        placeholder="Поиск по имени сущности"
        onChange={handleSearch}
      />
      <Button
        type={isChecked ? "primary" : "default"}
        className={classes.scada}
        onClick={toggleChecked}
        icon={<DeploymentUnitOutlined />}
        style={{ marginRight: 30, borderRadius: 5 }}
      >
        {"Скада"}
      </Button>
      <Tooltip title="Скрыть дерево">
        <Button
          style={{
            position: "absolute",
            borderRadius: "10px 0px 0px 10px",
            right: -20,
            background: "#fff",
            boxShadow: "none",
            border: "1px solid #d9d9d9",
            borderRight: 0,
          }}
          className={classes.search}
          onClick={() => dispatch(toggleOpenTree())}
          icon={<LeftOutlined />}
        />
      </Tooltip>
    </div>
  );
};
