import React, { useEffect, useRef, useState } from "react";
import { Alert, Button } from "antd";
import { makeStyles } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { HotTable } from "@handsontable/react";
import { registerAllModules } from "handsontable/registry";
import "handsontable/dist/handsontable.full.min.css";
import { DC_ID } from "../../constants";
import "./style.css";
import { saveFetchPairs } from "../../../mapping-essence-to-dc/slice";
import { Preview } from "../preview";

registerAllModules();

const useStyles = makeStyles((theme) => ({
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: 20,
    marginBottom: 20,
  },
  alert: {
    height: 32,
    width: "100%",
  },
}));

const filterByTypeNotEquals = (essences, type) => {
  return essences
    ?.filter((essence) => essence.type === type)
    .map((essence) => essence.path);
};

export const Table = ({ essences, data }) => {
  const classes = useStyles();
  const hotTable = useRef();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [notValidRows, setNotValidRows] = useState(0);

  const essencePaths = essences
    ?.filter((essence) => essence.type !== DC_ID)
    .map((essence) => essence.path);
  const dcPaths = essences
    ?.filter((essence) => essence.type === DC_ID)
    .map((essence) => essence.path);

  useEffect(() => {
    validatorTable();
  }, []);

  useEffect(() => {
    validatorTable();
  }, [notValidRows]);

  const handleUpdate = () => {
    dispatch(saveFetchPairs(data.current));
  };

  const dropFilters = () => {
    if (hotTable?.current?.hotInstance) {
      const hot = hotTable?.current?.hotInstance;
      let filter = hot.getPlugin("filters");
      filter.clearConditions();
      filter.filter();
    }
  };

  const checkEmpty = (value) => {
    if (!value || value.length === 0) {
      return false;
    }
    return true;
  };

  const validatorTable = () => {
    const hot = hotTable?.current?.hotInstance;
    const values = data?.current;
    let countNotValidRows = 0;
    if (hot && values?.length && values[0]?.length) {
      values.forEach(([val1, val2], index) => {
        const isValidRow = Boolean(!(checkEmpty(val1) ^ checkEmpty(val2)));
        if (!isValidRow) {
          countNotValidRows += 1;
        }
        hot.setCellMeta(index, 0, "valid", isValidRow);
        hot.setCellMeta(index, 1, "valid", isValidRow);
      });
      if (notValidRows !== countNotValidRows) {
        setNotValidRows(countNotValidRows);
        hot.render();
      }
    }
  };

  return (
    <>
    <Preview open={open} setOpen={setOpen} data={data.current} />
      <div className={classes.header}>
      <div style={{display: 'flex', gap: 10}}>
          <Button
            type={"primary"}
            onClick={handleUpdate}
            disabled={notValidRows}
          >
            {"Сохранить изменения"}
          </Button>
          <Button
            type={"default"}
            onClick={() => setOpen(true)}
            disabled={notValidRows || !data.current}
          >
            {"Превью"}
          </Button>
        </div>
        {notValidRows > 0 && (
          <Alert
            className={classes.alert}
            message={`Количество невалидных строк: ${notValidRows}`}
            type="error"
          />
        )}
      </div>
      <HotTable
        ref={hotTable}
        invalidCellClassName="handsontable-cell-mapping-error"
        colHeaders={["Сущность", "DC"]}
        licenseKey="non-commercial-and-evaluation"
        data={data.current}
        autoColumnSize={true}
        afterChange={validatorTable}
        stretchH={"all"}
        overflow={true}
        filters={true}
        dropdownMenu={[
          { name: "Сбросить фильтры", callback: () => dropFilters() },
          "filter_by_condition",
          "filter_by_value",
          "filter_action_bar",
        ]}
        columns={[
          {
            type: "autocomplete",
            source: essencePaths,
            strict: false,
          },
          {
            type: "autocomplete",
            source: dcPaths,
            strict: false,
          },
        ]}
        rowHeaders={true}
        height={"100%"}
        width={"100%"}
        contextMenu={true}
        autoWrapRow={true}
        columnSorting={true}
        autoWrapCol={true}
        minSpareRows={1}
        multiColumnSorting={true}
        hiddenColumns={{
          columns: [0, 2],
          indicators: true,
        }}
      />
    </>
  );
};
