import React, { useState } from "react";
import { Divider, Button } from "antd";
import { useSelector, useDispatch } from "react-redux";
import {
  activeEssenceSelector,
  saveActiveEssence,
  setActiveEssence,
} from "../../slice";
import { Attributes } from "./attributes";
import { Telemetry } from "./telemetry";
import { ConfirmDeleteModal } from "../confirm-delete";

export const ScadaAttributes = () => {
  const dispatch = useDispatch();
  const activeEssence = useSelector(activeEssenceSelector);
  const [isOpenModal, setOpenModal] = useState(false);

  const handleChangeAttributes = (attributes) => {
    dispatch(setActiveEssence({ ...activeEssence, attributes }));
  };

  const handleChangeTelemetryLayers = (telemetryLayers) => {
    dispatch(setActiveEssence({ ...activeEssence, telemetryLayers }));
  };

  const handleClickSave = async () => {
    dispatch(saveActiveEssence());
  };

  const handleOpenModal = () => {
    setOpenModal(!isOpenModal);
  };

  const isPrimary = activeEssence?.isChanged || activeEssence?.isNew;

  return (
    <>
      <Attributes
        {...activeEssence}
        onChangeAttributes={handleChangeAttributes}
      />
      <Divider style={{ marginBottom: 10 }} />
      <Telemetry
        {...activeEssence}
        handleChangeTelemetryLayers={handleChangeTelemetryLayers}
      />
      <Divider style={{ marginBottom: 10 }} />
      <div style={{ marginTop: 20, display: "flex", gap: 20 }}>
        <Button type={isPrimary ? "primary" : ""} onClick={handleClickSave}>
          {activeEssence?.isNew
            ? "Создать новый объект Скада"
            : "Сохранить изменения"}
        </Button>
        <Button danger type="ghost" onClick={handleOpenModal}>
          Удалить
        </Button>
        <ConfirmDeleteModal open={isOpenModal} setOpen={setOpenModal} />
      </div>
    </>
  );
};
