import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../api";
import { EditAttributes } from "@material-ui/icons";
import { notification } from "antd";
import { openStatusNotification } from "./components/status-notification";

export const NAMESPACE = "scadaCreateEssence";

export const initialState = {
  telemetryClassifiers: {
    data: [],
    status: "notAsked",
  },
  tree: {
    data: [],
    status: "notAsked",
    open: true,
  },
  entities: {
    data: [],
    status: "notAsked",
  },
  activeEssence: undefined,
};

export const startSync = createAsyncThunk(`${NAMESPACE}/startSync`, () =>
  api.mapping.sync.post()
);

export const getTelemetryClassifiers = createAsyncThunk(
  `${NAMESPACE}/getTelemetryClassifiers`,
  (id) => api.scada.telemetryClassifiers(id)
);

export const saveActiveEssence = createAsyncThunk(
  `${NAMESPACE}/saveActiveEssence`,
  async (_, { getState, dispatch }) => {
    const { activeEssence } = getState()[NAMESPACE];
    try {
      const result = await api.scada.saveEntities(activeEssence);
      if (result.code === 200 && !result.error) {
        notification.success({ message: "Изменения сохранены" });
        if (activeEssence.isNew) {
          dispatch(getEntities());
        }
        return;
      }
    } catch (err) {}

    notification.error({ message: "Ошибка сохранения" });
  }
);

export const deleteActiveEssence = createAsyncThunk(
  `${NAMESPACE}/deleteActiveEssence`,
  async (_, { getState, dispatch }) => {
    const { activeEssence } = getState()[NAMESPACE];
    try {
      const result = await api.scada.deleteEntities(activeEssence.id);
      if (result.code === 200 && !result.error) {
        notification.success({
          message: `Сущность ${activeEssence.label} удалена`,
        });
        dispatch(getEntities());
        dispatch(selectNode({ node: undefined }));
        return;
      }
    } catch (err) {}

    notification.error({ message: "Ошибка удаления" });
  }
);

export const getTree = createAsyncThunk(`${NAMESPACE}/getTree`, () =>
  api.scada.tree()
);

export const getEntities = createAsyncThunk(`${NAMESPACE}/getEntities`, () =>
  api.scada.entities()
);

export const selectEssence = createAsyncThunk(
  `${NAMESPACE}/selectEssence`,
  (id) => api.scada.tree()
);

export const getSyncStatus = createAsyncThunk(
  `${NAMESPACE}/getSyncStatus`,
  async () => {
    try {
      const data = await api.mapping.syncCheck.get();
      openStatusNotification(data?.result, data?.error);
    } catch (e) {
      openStatusNotification({}, true);
    }
  }
);

export const slice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {
    toggleOpenTree: (state, { payload }) => {
      state.tree.open = !state.tree.open;
    },
    setActiveEssence: (state, { payload }) => {
      return { ...state, activeEssence: { ...payload, isChanged: true } };
    },
    selectNode: (state, { payload: { node } }) => {
      if (!node || node.type === "client") {
        return (state.activeEssence = undefined);
      }

      const essence = state.entities.data.find(({ id }) => id === node.id);
      if (essence) {
        state.activeEssence = essence;
      }

      if (!essence) {
        const template = {
          id: node.id,
          label: node.label,
          attributes: {
            isRouter: false,
            isVoc: false,
            isChatBot: false,
          },
          telemetryLayers: [],
          isNew: true,
        };

        state.activeEssence = template;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getTelemetryClassifiers.pending, (state) => {
      return {
        ...state,
        telemetryClassifiers: { status: "pending", data: [] },
      };
    });
    builder.addCase(getTelemetryClassifiers.fulfilled, (state, { payload }) => {
      return {
        ...state,
        telemetryClassifiers: { status: "fulfilled", data: payload.result },
      };
    });
    builder.addCase(deleteActiveEssence.fulfilled, (state, { payload }) => {
      return { ...state, activeEssence: undefined };
    });
    builder.addCase(getTree.pending, (state) => {
      return { ...state, tree: { status: "pending", data: [], open: true } };
    });
    builder.addCase(getTree.fulfilled, (state, { payload }) => {
      return {
        ...state,
        tree: { status: "fulfilled", data: payload, open: true },
      };
    });
    builder.addCase(getEntities.pending, (state) => {
      return { ...state, entities: { status: "pending", data: [] } };
    });
    builder.addCase(getEntities.fulfilled, (state, { payload }) => {
      return {
        ...state,
        entities: { status: "fulfilled", data: payload.result },
      };
    });
  },
});

export const { setActiveEssence, selectNode, toggleOpenTree } = slice.actions;

export const getScada = (store) => store[NAMESPACE];

export const treeSelector = (store) => store[NAMESPACE].tree;
export const treeOpenSelector = (store) => store[NAMESPACE].tree?.open ?? true;
export const activeEssenceSelector = (store) => store[NAMESPACE].activeEssence;
export const entitiesSelector = (store) => store[NAMESPACE].entities;
export const telemetryClassifiersSelector = (store) =>
  store[NAMESPACE].telemetryClassifiers;
