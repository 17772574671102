import React, { useEffect, useState, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import highchartsMore from 'highcharts/highcharts-more.js'

import { Paper, Grid, Typography } from '@material-ui/core'

import { useTheme } from '@material-ui/core/styles'

/*
 **-------------------- My Components ---------------------------
 */

import FFTextField from '../../libft/FFTextField'
import FFContainer from '../../libft/FFContainer'
import FFSelect from '../../libft/FFSelect'
import FFLoader from '../../libft/FFLoader'
import FFLoaderComponent from '../../libft/FFLoaderComponent'

/*
 **------------------------- Utils ------------------------------
 */

import Context from '../../context'
import { ApiConnect, urlConfiguration } from '../../ApiConnectNew'
import { SnackbarProvider, useSnackbar } from 'notistack'
import {v4 as uuidv4} from 'uuid'

const useStyles = makeStyles(theme => ({
  root: {
    overflow: 'auto',
    height: '100%',
    margin: 0
  },
  defaultText: {
    color: theme.palette.grey[200]
  },
  margin: {
    margin: theme.spacing(1)
  }
}))

export default function FF_MaxHours (props) {
  const classes = useStyles()

  const defTheme = useTheme()

  const { isLogin } = useContext(Context)

  const { enqueueSnackbar } = useSnackbar()
  const handleClickVariant = (variant, msg) => {
    enqueueSnackbar(
      msg,
      { variant },
      {
        anchorOrigin: { vertical: 'top', horizontal: 'right' }
      }
    )
  }

  const [startDate, setStartDate] = useState(null)
  const [finishDate, setFinishDate] = useState(null)
  const [zone, setZone] = useState(null)
  const [region, setRegion] = useState(null)

  const [maxHours, setMaxHours] = useState(null)
  const [maxHoursLoading, setMaxHoursLoading] = useState(false)
  const [metaChart, setMetaChart] = useState({ value: null, id: uuidv4() })

  const optionZone = [
    { id: '0', label: 'Ценовая зона не определена' },
    { id: '1', label: 'Первая ценовая зона' },
    { id: '2', label: 'Вторая ценовая зона' }
  ]
  const optionRegion = [
    { id: '0', label: 'Неопределенный регион' },
    { id: '100', label: 'Москва' },
    { id: '158', label: 'Московская область' },
    { id: '152', label: 'Регион' }
  ]

  useEffect(() => {
    if (maxHours?.max_hours_fact && maxHours?.max_hours_prediction) {
      let tempMetaChart = {
        chart: {
          type: 'line',
          zoomType: 'x'
        },
        title: {
          text: ''
        },
        xAxis: [
          {
            type: 'datetime',
            plotBands: [],
            labels: {
              formatter: function () {
                return new Date(this.value).toLocaleString()
              }
            }
          }
        ],
        legend: {
          reversed: false
        },
        tooltip: {
          formatter: function () {
            return [
              '<span style="font-size:10px">Основной период: ' +
                new Date(this.x).toLocaleString() +
                '</span><table>' +
                (this.points
                  ? this.points.map(function (point) {
                      return (
                        '<tr><td style="color:' +
                        point.series.color +
                        ';padding:0">' +
                        point.series.name +
                        ': </td>' +
                        '<td style="padding:0"><b>' +
                        point.y +
                        ' Втч</b></td></tr>' +
                        '<tr><td style="color:' +
                        defTheme.palette.success.main +
                        ';padding:0">Часы максимума фактические</td></tr>' +
                        '<tr><td style="color:' +
                        defTheme.palette.warning.main +
                        ';padding:0">Часы максимума прогнозные</td></tr>'
                      )
                      // point.series.name + ': ' + point.y + 'Втч'
                    })
                  : []
                ).join() +
                '</table>'
            ]
          },
          useHTML: true,
          shared: true,
          split: true
        },
        series: [],
        seriesTemp: []
      }
      let x = []
      let temp = new Date(startDate).valueOf()
      while (temp < new Date(finishDate).valueOf() + 86400000 - 1) {
        x = [...x, [temp, 0]]
        temp = temp + 3600000
      }
      tempMetaChart.series = [{ data: x, name: 'Часы максимума' }]

      if (maxHours?.max_hours_fact.length) {
        tempMetaChart.xAxis[0]['plotBands'] = [
          ...tempMetaChart.xAxis[0]['plotBands'].filter(
            obj => obj.name !== 'max_hours_fact'
          ),
          ...maxHours?.max_hours_fact.map(dat => {
            return {
              from: dat.from,
              to: dat.to,
              name: 'max_hours_fact',
              color: defTheme.palette.success.light
            }
          })
        ]
        tempMetaChart.series.push({
          color: defTheme.palette.success.light,
          name: 'Часы максимума фактические',
          data: maxHours?.max_hours_fact.map(dat => [dat.from, -1]),
          type: 'column'
        })
      }
      if (maxHours?.max_hours_prediction.length) {
        tempMetaChart.xAxis[0]['plotBands'] = [
          ...tempMetaChart.xAxis[0]['plotBands'].filter(
            obj => obj.name !== 'max_hours_prediction'
          ),
          ...maxHours?.max_hours_prediction.map(dat => {
            return {
              from: dat.from,
              to: dat.to,
              name: 'max_hours_prediction',
              color: defTheme.palette.warning.light
            }
          })
        ]
        tempMetaChart.series.push({
          color: defTheme.palette.warning.light,
          name: 'Часы максимума прогнозные',
          data: maxHours?.max_hours_prediction.map(dat => [dat.from, 1]),
          type: 'column'
        })
      }
      setMetaChart({ value: tempMetaChart, id: uuidv4() })
    }
  }, [maxHours])

  useEffect(() => {
    if (startDate && finishDate && zone && region) {
      setMetaChart({ value: null, id: uuidv4() })
      setMaxHoursLoading(false)
      setMaxHours(null)

      ApiConnect({
        name: 'getDataPost',
        url: urlConfiguration + '/api/control/data/max_hours/',
        objToGet: {
          start_time: Number(
            String(new Date(startDate).valueOf()).slice(0, 10)
          ),
          stop_time: Number(
            String(new Date(finishDate).valueOf() + 86400000 - 1).slice(0, 10)
          ),
          region: Number(region),
          zone: Number(zone)
        },
        setterEssence: setMaxHours,
        setterLoading: setMaxHoursLoading,
        clickHandle: handleClickVariant,
        isLogin: isLogin
      })
    }
  }, [startDate, finishDate, zone, region])

  const changeValue = (value, id, name, elem) => {
    if (name === 'startDate') setStartDate(value)
    else if (name === 'finishDate') setFinishDate(value)
    else if (name === 'region') setRegion(value)
    else if (name === 'zone') setZone(value)
  }
  return (
    <Context.Provider value={{ changeValue }}>
      <Paper className={classes.root}>
        <FFContainer
          id={'all'}
          components={[
            {
              width: 12,
              elevation: 2,
              shrink: 12,
              component: (
                <React.Fragment>
                  <Grid item xs={12} container>
                    <Grid item xs={3} container direction={'column'}>
                      <FFTextField
                        value={startDate}
                        id={'startDate'}
                        isDate={'date'}
                        name={'startDate'}
                        label={'Начало'}
                      />
                    </Grid>
                    <Grid item xs={3} container direction={'column'}>
                      <FFTextField
                        value={finishDate}
                        id={'finishDate'}
                        isDate={'date'}
                        name={'finishDate'}
                        label={'Конец'}
                      />
                    </Grid>
                    <Grid item xs={3} container direction={'column'}>
                      <FFSelect
                        value={zone}
                        id={'zone'}
                        options={optionZone}
                        name={'zone'}
                        label={'Ценовая зона'}
                      />
                    </Grid>
                    <Grid item xs={3} container direction={'column'}>
                      <FFSelect
                        value={region}
                        id={'region'}
                        options={optionRegion}
                        name={'region'}
                        label={'Регион'}
                      />
                    </Grid>
                  </Grid>
                </React.Fragment>
              )
            },
            {
              width: 12,
              elevation: 2,
              style:
                maxHoursLoading && startDate && finishDate && zone && region
                  ? { justifyContent: 'stretch', alignItems: 'stretch' }
                  : { justifyContent: 'center', alignItems: 'center' },
              component: (
                <FFLoaderComponent
                  key={'FFLoaderComponent'}
                  loading={startDate && finishDate && zone && region}
                  isLoader={
                    <Typography
                      variant='h1'
                      component='h2'
                      className={classes.defaultText}
                    >
                      {'Заполните все параметры'}
                    </Typography>
                  }
                  component={
                    <FFLoaderComponent
                      key={'FFLoaderComponent'}
                      loading={maxHoursLoading && metaChart.value}
                      isLoader={<FFLoader />}
                      component={
                        <HighchartsReact
                          options={metaChart.value}
                          key={metaChart.id}
                        />
                      }
                    />
                  }
                />
              )
            }
          ]}
        />
      </Paper>
    </Context.Provider>
  )
}
