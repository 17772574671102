import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core";
import { Typography, Button, Tooltip } from "antd";
import { SyncOutlined, InfoOutlined } from "@ant-design/icons";
import { activeEssenceSelector, getSyncStatus, startSync } from "../../slice";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 20,
  },
  actionButtons: {
    display: "flex",
    gap: 10,
  },
}));

export const Header = () => {
  const styles = useStyles();

  const { label } = useSelector(activeEssenceSelector);
  const dispatch = useDispatch();

  const handleStartSync = () => {
    dispatch(startSync());
  };

  const handleSyncCheck = () => {
    dispatch(getSyncStatus());
  };

  return (
    <div className={styles.root}>
      <Typography.Title level={4} style={{ textAlign: "start" }}>
        {`Название объекта: ${label}`}
      </Typography.Title>
      <div className={styles.actionButtons}>
        {/* <Tooltip title="Запустить синхронизацию">
          <Button
            type="primary"
            icon={<SyncOutlined />}
            onClick={handleStartSync}
          />
        </Tooltip>
        <Tooltip title="Статус">
          <Button icon={<InfoOutlined />} onClick={handleSyncCheck} />
        </Tooltip> */}
      </div>
    </div>
  );
};
