import React, { useEffect, useState, useContext } from 'react'
import Context from '../context'

import FFRouterComponents from '../components/FFRouterComponents'

import Grid from '@material-ui/core/Grid'

import IconButton from '@material-ui/core/IconButton'
import IconDelete from '@material-ui/icons/Delete'

import {v4 as uuidv4} from 'uuid'

export default function FF_Classifier_step3_formatData_valueList (props) {
  // -------------------------------------------------------------------------------
  //
  //                                   Стили
  //
  // -------------------------------------------------------------------------------

  // -------------------------------------------------------------------------------
  //
  //                                 Мета описание
  //
  // ---------------------------- ---------------------------------------------------

  let metaTable = [
    {
      widthColumn: 4,
      data: [
        {
          type: 'ffListTextFields',
          style: {},
          name_attr: ['key'],
          label_attr: ['Идентификатор']
        }
      ]
    },
    {
      widthColumn: 7,
      data: [
        {
          type: 'ffListTextFields',
          style: {},
          name_attr: ['value'],
          label_attr: ['Наименование']
        }
      ]
    }
  ]

  // -------------------------------------------------------------------------------
  //
  //                                 Состояние
  //
  // ---------------------------- ---------------------------------------------------

  const [valueList, setValueList] = useState([])
  const [valueTemp, setValueTemp] = useState({})
  const { pFormatDataValueList } = useContext(Context)

  // -------------------------------------------------------------------------------
  //
  //                                 Обработчики событий
  //
  // -------------------------------------------------------------------------------

  useEffect(() => {
    let temp =
      props.value && props.value.value && Array.isArray(props.value.value)
        ? props.value.value
        : []
    temp.map(obj => {
      obj.key = obj.id
      obj.id = uuidv4()
    })
    setValueList(temp)
    generateTempValue()
  }, [])

  // Генерация Пустышки
  const generateTempValue = () => {
    setValueTemp({
      id: uuidv4() + '_temp',
      key: '',
      value: ''
    })
  }

  // Удаление значения из списка
  const deleteListValue = id => {
    const temp = valueList.filter(obj => obj.id !== id)
    setValueList(temp)
    pFormatDataValueList(
      props.nameAttr,
      temp.map(obj => {
        return { id: obj.key, value: obj.value }
      })
    )
  }
  // Стандартное изменение
  const handleChangeFFTextFields = (value, parameter_id, name, element) => {
    if (element) {
      let temp
      if (parameter_id && parameter_id.indexOf('_temp') === -1) {
        temp = valueList
        temp.map(obj => {
          if (obj.id === parameter_id) {
            obj[name] = value
          }
        })
        setValueList(temp)
      } else {
        let tempValueTemp = valueTemp
        tempValueTemp[name] = value
        tempValueTemp.id = uuidv4()
        temp = [...valueList, tempValueTemp]
        setValueList([...valueList, tempValueTemp])
        generateTempValue()
      }
      pFormatDataValueList(
        props.nameAttr,
        temp.map(obj => {
          return { id: obj.key, value: obj.value }
        })
      )
    }
    return ''
  }

  return (
    <Context.Provider value={{ handleChangeFFTextFields }}>
      <Grid container item xs={12} spacing={1}>
        {valueList.map(obj => {
          return (
            <React.Fragment>
              {metaTable.map(metaColumn => {
                return (
                  <Grid item xs={metaColumn.widthColumn}>
                    {metaColumn.data.map(metaCell => {
                      return (
                        <FFRouterComponents
                          style={{ margin: 10 }}
                          parameter={obj}
                          meta_cell={metaCell}
                        />
                      )
                    })}
                  </Grid>
                )
              })}
              <Grid item xs={1}>
                <IconButton
                  aria-label='filter'
                  onClick={e => deleteListValue(obj.id)}
                >
                  <IconDelete color='error' fontSize='large' />
                </IconButton>
              </Grid>
            </React.Fragment>
          )
        })}
        {metaTable.map(metaColumn => {
          return (
            <Grid item xs={metaColumn.widthColumn}>
              {metaColumn.data.map(metaCell => {
                return (
                  <FFRouterComponents
                    style={{ margin: 10 }}
                    parameter={valueTemp}
                    meta_cell={metaCell}
                  />
                )
              })}
            </Grid>
          )
        })}
      </Grid>
    </Context.Provider>
  )
}
