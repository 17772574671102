import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { essences, highEssences } from "./mocks";
import { api } from "../../api";

export const NAMESPACE = "mappingEssenceToDC";

export const initialState = {
  consumers: [],
  statusConsumers: "notAsked",
  consumer: undefined,
  essences: [],
  statusEssences: "notAsked",
  data: [],
  initData: [],
  statusData: "notAsked",
  syncStatus: {},
  showAlert: false,
  saveResult: undefined,
  showSaveResult: false,
};

export const getFetchConsumers = createAsyncThunk(
  `${NAMESPACE}/getFetchConsumers`,
  () => api.mapping.consumers()
);

export const saveFetchPairs = createAsyncThunk(
  `${NAMESPACE}/saveFetchPairs`,
  (data, { getState }) => {
    const {
      essences,
      consumer: { id },
    } = getState()[NAMESPACE];

    const dict = {};
    essences.forEach((essence) => {
      dict[essence.path] = essence;
    });

    const result = data
      .map(([path1, path2]) => {
        if (path1 in dict && path2 in dict) {
          return [dict[path1], dict[path2]];
        }
        return null;
      })
      .filter((pair) => pair);

    return api.mapping.pairsEssenceToDc.save(id, result);
  }
);

export const postSync = createAsyncThunk(`${NAMESPACE}/postSync`, () =>
  api.mapping.sync.post()
);

export const syncCheck = createAsyncThunk(`${NAMESPACE}/syncCheck`, () =>
  api.mapping.syncCheck.get()
);

export const getFetchPairs = createAsyncThunk(
  `${NAMESPACE}/getFetchPairs`,
  (consumer) => api.mapping.pairsEssenceToDc.get(consumer?.id)
);

export const getFetchEssences = createAsyncThunk(
  `${NAMESPACE}/getFetchEssences`,
  (consumer) => api.mapping.essences(consumer?.id)
);

export const setConsumer = createAsyncThunk(
  `${NAMESPACE}/setConsumer`,
  async (consumer, { dispatch }) => {
    if (consumer) {
      dispatch(getFetchEssences(consumer));
      dispatch(getFetchPairs(consumer));
    }
    return Promise.resolve(consumer);
  }
);

const slice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {
    updateData: (state, { payload }) => {
      const { essences } = state;
      const dict = {};
      essences.forEach((essence) => {
        dict[essence.path] = essence;
      });

      const data = payload.map((pairs) => {
        return pairs.map((essence) => {
          if (!dict[essence]) {
            return "";
          }
          return essence;
        });
      });

      return { ...state, data };
    },
    setShowAlert: (state, action) => {
      state.showAlert = action.payload;
    },
    setShowSaveResult: (state, action) => {
      state.showSaveResult = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getFetchEssences.pending, (state, { payload }) => {
      return { ...state, essences: [], statusEssences: "pending" };
    });
    builder.addCase(getFetchEssences.fulfilled, (state, { payload }) => {
      return {
        ...state,
        essences: payload?.result ?? [],
        statusEssences: "fulfilled",
      };
    });
    builder.addCase(getFetchConsumers.pending, (state, { payload }) => {
      return { ...state, consumers: [], statusConsumers: "pending" };
    });
    builder.addCase(getFetchConsumers.fulfilled, (state, { payload }) => {
      return {
        ...state,
        consumers: payload?.result ?? [],
        statusConsumers: "fulfilled",
      };
    });
    builder.addCase(getFetchPairs.pending, (state, { payload }) => {
      return { ...state, data: [], statusData: "pending" };
    });
    builder.addCase(
      getFetchPairs.fulfilled,
      (state, { payload: { result = [] } }) => {
        const data = [...result] ?? [];

        const pairs = data.map(([essence1, essence2]) => [
          essence1.path,
          essence2.path,
        ]);
        return {
          ...state,
          initData: pairs,
          data: pairs,
          statusData: "fulfilled",
        };
      }
    );
    builder.addCase(setConsumer.fulfilled, (state, { payload }) => {
      return { ...state, consumer: payload };
    });
    builder.addCase(syncCheck.fulfilled, (state, { payload }) => {
      return { ...state, syncStatus: payload?.result ?? {}, showAlert: true };
    });
    builder.addCase(saveFetchPairs.fulfilled, (state, { payload }) => {
      return { ...state, saveResult: payload, showSaveResult: true };
    });
  },
});

export default slice.reducer;
export const { updateData, setShowAlert, setShowSaveResult } = slice.actions;
export const getScada = (store) => store[NAMESPACE];
