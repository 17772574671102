import React from "react";
import { Typography, Alert, Tabs } from "antd";
import { makeStyles } from "@material-ui/core";
import { useSelector } from "react-redux";
import { activeEssenceSelector } from "../slice";
import { EssenceTable } from "./essence-table";
import { ScadaAttributes } from "./scada-attributes";
import { Preview } from "./preview";
import { DCTable } from "./dc-table";
import { Header } from "./header";
import "./styles.css";

const useStyles = makeStyles((theme) => ({
  placeholder: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

export const Center = () => {
  const styles = useStyles();

  const activeEssence = useSelector(activeEssenceSelector);

  if (!activeEssence) {
    return (
      <div className={styles.placeholder}>
        <Typography.Title level={1} strong style={{ color: "lightgray" }}>
          Выберите сущность
        </Typography.Title>
      </div>
    );
  }

  const { isNew } = activeEssence;

  const items = [
    {
      label: "Мета описание",
      key: 1,
      children: <ScadaAttributes />,
    },
    {
      label: "Маппинг Сущность - Сущность",
      key: 2,
      children: <EssenceTable />,
    },
    {
      label: "Маппинг Сущность - DC",
      key: 3,
      children: <DCTable />,
    },
  ];

  return (
    <div>
      <Header />
      {isNew && (
        <Alert
          message="Создать новую?"
          description="Вы выбрали сущность которая не является объектом Скады"
          type="warning"
          showIcon
          style={{ marginBottom: 20, borderRadius: 10 }}
        />
      )}
      <Tabs
        defaultActiveKey="1"
        key={activeEssence.id}
        size={"small"}
        type="card"
        items={items}
      />
    </div>
  );
};
