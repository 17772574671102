import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core";
import {
  getEntities,
  getTree,
  toggleOpenTree,
  treeOpenSelector,
} from "./slice";
import { useDispatch } from "react-redux";
import { Tooltip, Button } from "antd";
import { Tree, Center } from "./components";
import { RightOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";

export const useStyles = makeStyles((theme) => ({
  root: {
    overflow: "hidden",
    height: "100%",
    position: "relative",
    display: "flex",
    flexDirection: "column",
  },
  header: {
    display: "flex",
    height: 32,
    flexDirection: "row",
    gap: 10,
  },
  content: {
    height: "100%",
    overflow: "hidden",
    display: "grid",
    gridTemplateColumns: ({ open }) => (open ? "30% 70%" : "100%"),
    marginRight: 0,
    "& > div": {
      borderRadius: 10,
      overflow: "overlay",
      padding: 20,
      margin: 20,
    },
    "& > div:nth-of-type(1)": {
      boxShadow: '0px 3px 12px 1px rgba(0, 0, 0, 0.05), 0px 2px 0px 0px rgba(0, 0, 0, 0.1), 0px 0px 5px 0px rgba(0, 0, 0, 0.1)',
      display: ({ open }) => (open ? "" : "none"),
      backgroundColor: theme.palette.background.paper,
    },
    "& > div:nth-of-type(2)": {
      boxShadow: '0px 3px 12px 1px rgba(0, 0, 0, 0.05), 0px 2px 0px 0px rgba(0, 0, 0, 0.1), 0px 0px 5px 0px rgba(0, 0, 0, 0.1)',
      backgroundColor: theme.palette.background.paper,
      marginLeft: ({ open }) => (open ? 0 : 50),
      overflow: "hidden",
    },
  },
}));

export const ScadaCreateEssence = () => {
  const open = useSelector(treeOpenSelector);
  const classes = useStyles({ open });
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getTree());
    dispatch(getEntities());
  }, []);

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <Tree />
        <Center />
        {!open && (
          <Tooltip title="Открыть дерево">
            <Button
              style={{
                position: "absolute",
                top: 30,
                left: 0,
                borderRadius: "0 10px 10px 0",
              }}
              icon={<RightOutlined />}
              onClick={() => dispatch(toggleOpenTree())}
              type="primary"
            />
          </Tooltip>
        )}
      </div>
    </div>
  );
};
