import React, { useEffect, useState, useContext } from "react";
import Context from "./context";
import FFCreatePanel from "./TestConfig";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

import {
  Paper,
  Typography,
  TableContainer,
  Grid,
  Divider,
  IconButton,
  Button,
  Tooltip,
  // Dialog import
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  // Card import
  Card,
  CardHeader,
  CardMedia,
  CardContent,
  CardAction,
  Collapse,
  Avatar,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
} from "@material-ui/core";

import { red } from "@material-ui/core/colors";

import IconSettings from "@material-ui/icons/Settings";
import IconDynamicFeed from "@material-ui/icons/DynamicFeed";
import IconDelete from "@material-ui/icons/Delete";
import IconLibraryAdd from "@material-ui/icons/LibraryAdd";
import IconAdd from "@material-ui/icons/Add";
import IconSave from "@material-ui/icons/Save";
import IconCancel from "@material-ui/icons/Cancel";
import IconRefresh from "@material-ui/icons/Refresh";
import FavoriteIcon from "@material-ui/icons/Favorite";
import ShareIcon from "@material-ui/icons/Share";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import FFSelectTree from "./components/FFSelectTree";
import FFCheckBoxList from "./components/FFCheckBoxList";
import FFLoader from "./libft/FFLoader";
import FFLoaderComponent from "./libft/FFLoaderComponent";
import FFToggle from "./components/FFToggleSimple";
import FFTextFields from "./components/FFTextFields";
import FFRouterComponents from "./components/FFRouterComponents";
import FFTreeEssence from "./components/FFTreeEssence";
import FFTreeEssenceChecked from "./components/FFTreeEssenceChecked";
import FFSwitch_N from "./components/FFSwitch_N";
import FFToogleStyle from "./components/FFToogleStyle";

import { SnackbarProvider, useSnackbar } from "notistack";
import TreeNodeUtils from "tree-node-utils";

import clsx from "clsx";

import cookie from "react-cookies";
import Loader from "./Loader";

import { ApiConnect, urlConfiguration } from "./ApiConnectNew";
import FFDialogDelete from "./libft/FFDialogDelete";
import { api } from "./api/api";

import {v4 as uuidv4} from 'uuid'

const useStyles = makeStyles({
  margin: {
    marginTop: "10px",
    marginLeft: "10px",
    marginBottom: "5px",
  },
  flex: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  flexRow: {
    display: "flex",
    flexDirection: "row",
    height: "100%",
  },
  end: {
    flexGrow: 3,
  },
  tree: {
    padding: 8,
    margin: 8,
  },
  scroller: {
    // maxHeight: '-webkit-fill-available',
    overflow: "overlay",
  },
});

const useStylesPanels = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  expandHeader: {
    backgroundColor: theme.palette.primary.light,
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
  },
}));

export default function FF_Essence(props) {
  // -------------------------------------------------------------------------------
  //
  //                                   Стили
  //
  // -------------------------------------------------------------------------------
  // console.log(props)
  const classes = useStyles();

  const config = {
    childrenField: "children",
    keyField: "id",
  };
  const treeUtils = new TreeNodeUtils(config);

  // -------------------------------------------------------------------------------
  //
  //                                 Состояния
  //
  // -------------------------------------------------------------------------------

  const metaEssence = {
    label: {
      label: "Наименование",
      is_unique: true,
      is_required: true,
      is_type: "string",
    },
    label_eng: {
      label: "Наименование на латинском",
      is_unique: true,
      is_required: true,
      is_type: "string",
    },
    template: {
      label: "Используемый шаблон",
      is_unique: true,
      is_required: true,
      is_type: "string",
    },
    templateLabel: {
      label: "Используемый шаблон",
      is_unique: false,
      is_required: false,
      is_type: "string",
    },
    name: {
      label: "Системное наименование",
      is_unique: true,
      is_required: true,
      is_type: "string",
    },
    description: {
      label: "Описание",
      is_unique: false,
      is_required: false,
      is_type: "string",
    },
  };

  const strAuthorization = "Bearer " + cookie.load("access_token");
  const { isLogin } = useContext(Context);
  const [essence, setEssence] = useState([]);
  const [essenceLoading, setEssenceLoading] = useState(false);
  const [activeEssence, setActiveEssence] = useState(null);
  const [classifierTypeData, setClassifierTypeData] = useState([]);
  const [panels, setPanels] = useState([]);
  const [modernPanel, setModernPanel] = useState([]);
  const [te, setTe] = useState(0);
  const [activePanel, setActivePanel] = useState(null);
  const [edit, setEdit] = useState(false);
  const [open, setOpen] = useState(false);
  const [curParent, setCurParent] = useState("");
  const [copyPanels, setCopyPanels] = useState(null);
  const [activeCopyPanels, setActiveCopyPanels] = useState([]);
  const [nameEssence, setNameEssence] = useState("");
  const [isOpenDialogDelete, setIsOpenDialogDelete] = useState(false);

  useEffect(() => {
    doRefresh();
  }, []);

  useEffect(() => {
    if (activeEssence) {
      console.log(activeEssence);
      ApiConnect({
        name: "getData",
        url:
          urlConfiguration +
          `/api/support/info/?entity=essence&action=select&info=tree&parameters=true&id=${activeEssence.id}`,
        setterEssence: (temp) => {
          setClassifierTypeData(temp);
        },
        // setterLoading: setEssenceLoading,
        isLogin: isLogin,
      });
    }
  }, [activeEssence]);

  // -------------------------------------------------------------------------------
  //
  //                                 Снэк Бар
  //
  // -------------------------------------------------------------------------------

  const { enqueueSnackbar } = useSnackbar();
  const handleClickVariant = (variant, msg) => {
    // variant could be success, error, warning, info, or default
    enqueueSnackbar(
      msg,
      { variant },
      {
        anchorOrigin: { vertical: "top", horizontal: "right" },
      }
    );
  };

  // -------------------------------------------------------------------------------
  //
  //                                 Обработчики событий
  //
  // -------------------------------------------------------------------------------

  // Нажатие на классификационное значение в меню
  const doClickActiveEssence = (objId, obj) => {
    // console.log(objId, obj, essence.id !== objId)
    if (
      essence.id !== objId &&
      obj?.type !== "client" &&
      obj['type-essence'] !== "client"
    ) {
      setPanels([]);
      setModernPanel([]);

      setActiveEssence(obj);
      // setChildren(obj)
      // setClient(null)
      // getData('/api/config/essence/?id=' + objId, setActiveEssence, null)
      // ApiConnect({
      //   name: 'getData',
      //   url: urlConfiguration + '/api/modern_panel/?essence=' + obj.id,
      //   setterEssence: setActiveEssence,
      //   setterLoading: null,
      //   isLogin: isLogin
      // })
      updateModernPanel(obj);
      setActivePanel(null);
      // ApiConnect({
      //   name: 'getDataPost',
      //   url: urlConfiguration + '/api/search/?entity=modern_panel',
      //   objToGet: { essence: obj.id, is_deleted: false },
      //   setterEssence: setPanels,
      //   // setterLoading: null,
      //   clickHandle: handleClickVariant,
      //   isLogin: isLogin
      // })
    } else {
      // console.log('Click ', obj)
      // setClient(obj)
      setActiveEssence(null);
    }
    // console.log(activeEssence)
  };

  // Обработчик для обновления
  const doRefresh = () => {
    setTimeout(() => {
      // getData(
      //   '/api/support/info/?entity=essence&action=select&info=tree',
      //   setEssence,
      //   setEssenceLoading
      // )
      // getData('/api/?entity=template_essence', setTemplates, null)
      api.essence.get_tree({
        isLogin,
        callback: setEssence,
        callbackLoading: setEssenceLoading,
      });
      // ApiConnect({
      //   name: "getData",
      //   url:
      //     urlConfiguration +
      //     "/api/support/info/?entity=essence&action=select&info=tree",
      //   setterEssence: setEssence,
      //   setterLoading: setEssenceLoading,
      //   isLogin: isLogin,
      // });
      // setTe(te + 1)
    }, 100);
  };

  const doAddEssence = () => {
    if (activeEssence) {
      let tempId = uuidv4();
      let es = activeEssence.id;
      let tempPanel = {
        children: [],
        client: activeEssence.client,
        connections: {},
        description: "new_description" + tempId.split("-")[0],
        essence: activeEssence.id,
        id: tempId,
        is_complex: false,
        is_deleted: false,
        label: activeEssence.label + "/Панель_" + tempId.split("-")[0],
        label_eng: "new_label_eng" + tempId.split("-")[0],
        model: null,
        name: "new_name" + tempId.split("-")[0],
        product: null,
        type: "panel",
        position: {
          w: 5,
          h: 20,
          x: 0,
          y: 0,
          maxW: 5,
          minW: 5,
          static: false,
        },
      };
      tempPanel.connections[activeEssence.id] = {
        simpleParam: [],
        streamParam: [],
        timeStreamParam: [],
      };
      ApiConnect({
        name: "createData",
        url: urlConfiguration + "/api/?entity=modern_panel",
        objToCreate: {
          ...tempPanel,
          meta: {
            position: {
              w: 5,
              h: 20,
              x: 0,
              y: 0,
              static: false,
              maxW: 5,
              minW: 5,
            },
            children: [],
            type: "panel",
          },
        },
        // setterLoading: setEssenceLoading,
        isLogin: isLogin,
        clickHandle: handleClickVariant,
      });
      // updateModernPanel()
      setModernPanel([...modernPanel, tempPanel]);
      // console.log(panels)
    } else {
      handleClickVariant("warning", "Выберите сущность");
    }
  };

  const updateModernPanel = (obj) => {
    // setTimeout(() => {

    if (obj === null) {
      obj = activeEssence;
    }
    // console.log(obj)
    if (obj) {
      ApiConnect({
        name: "getData",
        url:
          urlConfiguration +
          "/api/config/modern_panels/copy/?essence=" +
          obj.id,
        setterEssence: (allPanels) => {
          if ("obj" in allPanels && allPanels.obj.length) {
            setModernPanel(allPanels["obj"]);
            setPanels(allPanels["obj"]);
          }
        },
        // setterLoading: setEssenceLoading,
        isLogin: isLogin,
      });
      // setPanels()
    }
    // }, 0)
  };

  const doSaveEssence = (obj) => {
    // console.log(activePanel)
    // console.log({
    //   ...activePanel,
    //   meta: {
    //     position: activePanel.position,
    //     children: activePanel.children,
    //     type: activePanel.type
    //   }
    // })
    if (activePanel) {
      ApiConnect({
        name: "updateDate",
        url: urlConfiguration + "/api/?entity=modern_panel",
        objToUpdate: {
          ...activePanel,
          meta: {
            position: activePanel.position,
            children: activePanel.children,
            type: activePanel.type,
          },
        },
        // setterLoading: setEssenceLoading,
        isLogin: isLogin,
        clickHandle: handleClickVariant,
      });
      updateModernPanel();
    } else {
      handleClickVariant("warning", "Выберите панель управления");
    }
  };

  const doDeleteEssence = () => {
    if (activePanel) {
      // setPanels([])
      ApiConnect({
        name: "deleteDate",
        url:
          urlConfiguration + "/api/?entity=modern_panel&id=" + activePanel.id,
        objToDelete: { id: activePanel.id },
        // setterLoading: setEssenceLoading,
        isLogin: isLogin,
        clickHandle: handleClickVariant,
      });
      setActivePanel(null);

      // updateModernPanel()
      setModernPanel(modernPanel.filter((obj) => obj.id !== activePanel.id));
      setPanels(panels.filter((obj) => obj.id !== activePanel.id));
    } else {
      handleClickVariant("warning", "Выберите панель управления");
    }
    // doRefresh()
  };

  // useEffect(() => {
  //   let temp = modernPanel.fil
  //   {
  //     ...activePanel,
  //     position: activePanel.position,
  //     type: activePanel.type,
  //     children: activePanel.children
  //   }
  // }, [activePanel])

  const handleChangeFFTextFields = (value, id, name, element) => {
    if (element === "checkboxList") {
      setActiveCopyPanels(value);
      // console.log(value)
    }
  };

  const doChangeSelect = (par, obj) => {
    setCopyPanels(null);
    setActiveCopyPanels([]);
    let nodeEssence = treeUtils.getNodeByKey(essence, obj);
    if (
      nodeEssence["template"] &&
      activeEssence["template"] &&
      JSON.stringify(nodeEssence.template) ===
        JSON.stringify(activeEssence.template)
    ) {
      setCurParent(obj);
      ApiConnect({
        name: "getData",
        url:
          urlConfiguration + "/api/config/modern_panels/copy/?essence=" + obj,
        setterEssence: (temp) => {
          "obj" in temp && temp["obj"].length
            ? setCopyPanels(temp["obj"])
            : handleClickVariant(
                "warning",
                "Нет доступных панелей для копирования"
              );
        },
        setterLoading: null,
        isLogin: isLogin,
      });
      // console.log(nodeEssence.template.id, activeEssence.template.id)
    } else {
      setCurParent("");
      handleClickVariant(
        "warning",
        "Шаблон копируемой сущности не соотвествует шаблону сущности в которую копируем"
      );
    }
  };

  const toGoCopy = (e) => {
    if (activeCopyPanels.length) {
      let panels = copyPanels.filter(
        (obj) => activeCopyPanels.indexOf(obj.id) > -1
      );
      panels = panels.map((newPanel) => {
        let exp = new RegExp(newPanel.essence, "g");
        let newPanelEssence = JSON.parse(
          JSON.stringify(newPanel).replace(exp, activeEssence.id)
        );
        Object.keys(newPanelEssence.connections).map((idEssenceConnect) => {
          if (newPanelEssence?.connections?.idEssenceConnect?.timeStreamParam) {
            newPanelEssence.connections.idEssenceConnect.timeStreamParam =
              newPanelEssence.connections.idEssenceConnect.timeStreamParam.map(
                (plots) => {
                  let exp = new RegExp(plots.plot, "g");
                  let newId = uuidv4();
                  return JSON.parse(JSON.stringify(plots).replace(exp, newId));
                }
              );
          }
        });
        return newPanelEssence;
      });
      let newPanels = treeUtils.mapNodes(panels, (currentNode, parentNode) => {
        // console.log(currentNode, parentNode)
        if (currentNode?.type !== "ffChart") {
          currentNode["id"] = uuidv4();
        }
        // if ('connections' in currentNode) {
        //   let oldEssence = currentNode['essence']
        //   let meta = currentNode['connections'][oldEssence]
        //   currentNode['connections'][activeEssence.id] = meta
        //   delete currentNode['connections'][oldEssence]
        // }
        // if ('full_parameters' in currentNode) {
        //   let oldEssence = currentNode['essence']
        //   let meta = currentNode['full_parameters'][oldEssence]
        //   currentNode['full_parameters'][activeEssence.id] = meta
        //   delete currentNode['full_parameters'][oldEssence]
        // }
        // if ('essence' in currentNode) {
        //   currentNode['essence'] = activeEssence.id
        // }
        return currentNode;
        // return current.essence === '111111'
      });
      newPanels.map((newPanel) => {
        let tempId = uuidv4();
        // let tempPanel = JSON.stringify(newPanel).replace(/esence: /g, 'essence: ' + newPanel.essence)

        ApiConnect({
          name: "createData",
          url: urlConfiguration + "/api/?entity=modern_panel",
          objToCreate: {
            ...newPanel,
            name: "new_name" + tempId.split("-")[0],
            label: nameEssence + "/Панель_" + tempId.split("-")[0],
            label_eng: "new_label_eng" + tempId.split("-")[0],
            meta: {
              position: newPanel.position,
              children: newPanel.children,
              type: "panel",
            },
          },
          // setterLoading: setEssenceLoading,
          isLogin: isLogin,
          clickHandle: handleClickVariant,
        });
      });
      // console.log(newPanels)
      // )
      setTimeout(() => {
        updateModernPanel(null);
      }, 500);
      setOpen(false);
      setCurParent("");
    } else {
      handleClickVariant(
        "warning",
        "Выберите хотя бы одну панель для копирования"
      );
    }
  };

  const doAddCopyEssence = () => {
    if (activeEssence) {
      setOpen(true);
      setCopyPanels(null);
      setActiveCopyPanels([]);
      ApiConnect({
        name: "getData",
        url:
          urlConfiguration +
          "/api/control/modern_panels/name/?essence=" +
          activeEssence.id,
        setterEssence: (temp) => setNameEssence(temp["obj"]),
        setterLoading: null,
        isLogin: isLogin,
      });
      setCurParent("");
    } else {
      handleClickVariant(
        "warning",
        "Выберите сущность для которой создаем панель"
      );
    }
  };

  // -------------------------------------------------------------------------------
  //
  //                                    Рендеринг
  //
  // -------------------------------------------------------------------------------

  return (
    <Context.Provider
      value={{
        doClickActiveEssence,
        handleChangeFFTextFields,
        // doClickCheckedEssence,
        // changeTextField,
        // getBackToParent
      }}
    >
      {isOpenDialogDelete && (
        <FFDialogDelete
          open={isOpenDialogDelete}
          setOpen={setIsOpenDialogDelete}
          isAgree={(e) => doDeleteEssence()}
        />
      )}

      <Dialog
        open={open}
        onClose={(e) => {
          setOpen(false);
          setCurParent("");
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Копирование панелей"}
        </DialogTitle>
        <DialogContent>
          <Grid container item xs={12} style={{ width: 400 }}>
            <Grid item xs={12}>
              <Context.Provider value={{ doChangeSelect }}>
                <FFSelectTree
                  key={"FFSelectTree_" + "temp"}
                  stateMeta={{ open: false, active: -1, label: "" }}
                  idCls={"temp"}
                  selectValue={curParent}
                  selectMetaLabel={"Источник копирования панели"}
                  selectNodes={essence}
                />
              </Context.Provider>
              {copyPanels ? (
                <FFCheckBoxList value={activeCopyPanels} names={copyPanels} />
              ) : null}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={(e) => {
              setOpen(false);
              setCurParent("");
            }}
            color="secondary"
          >
            Отмена
          </Button>
          <Button onClick={(e) => toGoCopy(e)} color="primary" autoFocus>
            Создать
          </Button>
        </DialogActions>
      </Dialog>
      <Grid container>
        <Grid item xs={4}></Grid>
        <Grid item xs={8} align="right">
          {/* {true === true ? (
            <Tooltip title='Массовое заполнение параметров'>
              <IconButton
                aria-label='filter'
                onClick={e => {
                  setClickChecked(!clickChecked)
                  setListChecked([])
                  setActiveEssence(null)
                  setActiveParameter('')
                  setEssenceParameter([])
                  localStorage.removeItem('parameterByEssences')
                }}
                className={classes.margin}
                style={{ visibility: isEdit === 0 ? 'visible' : 'hidden' }}
              >
                <IconDynamicFeed color='primary' fontSize='large' />
              </IconButton>
            </Tooltip>
          ) : null} */}
          {edit === false ? (
            <Tooltip title="Создать сущность">
              <IconButton
                aria-label="filter"
                onClick={(e) => doAddEssence()}
                className={classes.margin}
                // style={{ visibility: isEdit === 0 ? 'visible' : 'hidden' }}
              >
                <IconAdd color="primary" fontSize="large" />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title="Назад (отменить)">
              <IconButton
                aria-label="filter"
                className={classes.margin}
                onClick={(e) => {
                  updateModernPanel();
                  setEdit(!edit);
                  // console.log(!edit)
                }}
              >
                <IconCancel color="primary" fontSize="large" />
              </IconButton>
            </Tooltip>
          )}
          {activeEssence ? (
            <Tooltip title="Создать копию панели">
              <IconButton
                aria-label="filter"
                onClick={(e) => doAddCopyEssence()}
                className={classes.margin}
                // style={{ visibility: isEdit === 0 ? 'visible' : 'hidden' }}
              >
                <IconLibraryAdd color="primary" fontSize="large" />
              </IconButton>
            </Tooltip>
          ) : null}
          {activePanel !== null ? (
            <Tooltip title="Сохранить">
              <IconButton
                aria-label="filter"
                onClick={(e) => doSaveEssence()}
                className={classes.margin}
              >
                <IconSave color="primary" fontSize="large" />
              </IconButton>
            </Tooltip>
          ) : null}
          {/* {!clickChecked && essenceParameter.length ? (
            <IconButton
              aria-label='filter'
              onClick={e => doSaveEssenceParameter()}
              className={classes.margin}
            >
              <IconSave color='primary' fontSize='large' />
            </IconButton>
          ) : null}  */}
          {edit === false ? (
            <Tooltip title="Обновить">
              <IconButton
                aria-label="filter"
                // onClick={e => doRefresh()}
                className={classes.margin}
                // style={{ visibility: isEdit === 0 ? 'visible' : 'hidden' }}
              >
                <IconRefresh color="primary" fontSize="large" />
              </IconButton>
            </Tooltip>
          ) : null}
          {edit === false && activePanel !== null ? (
            <Tooltip title="Удалить">
              <IconButton
                aria-label="filter"
                onClick={(e) => setIsOpenDialogDelete(true)}
                className={classes.margin}
                // style={{ visibility: isEdit === 0 ? 'visible' : 'hidden' }}
              >
                <IconDelete color="error" fontSize="large" />
              </IconButton>
            </Tooltip>
          ) : null}
        </Grid>
      </Grid>

      <TableContainer className={clsx(classes.flex, classes.end)}>
        {edit === false ? (
          <MainPanel
            essence={essence}
            essenceLoading={essenceLoading}
            activeEssence={activeEssence}
            setActivePanel={(id) =>
              setActivePanel(modernPanel.filter((obj) => obj.id === id)[0])
            }
            panels={modernPanel}
            // panels={modernPanel}
            toEdit={(id) => {
              // setActivePanel(modernPanel.filter(obj => obj.id === id)[0])
              // console.log(
              //   modernPanel.filter(obj => obj.id === id)[0],
              //   modernPanel,
              //   id
              // )
              setEdit(!edit);
            }}
          />
        ) : activePanel ? (
          <FFCreatePanel
            meta={[
              activePanel,
              // {
              //   ...activePanel,
              //   position: activePanel.meta.position,
              //   children: activePanel.meta.children,
              //   type: 'panel'
              // }
            ]}
            classifierTypeData={classifierTypeData}
            essence={activeEssence}
          />
        ) : (
          <FFLoader />
        )}
      </TableContainer>
    </Context.Provider>
  );
}

const MainPanel = ({
  essence,
  essenceLoading,
  panels,
  activeEssence,
  toEdit,
  ...props
}) => {
  const classes = useStyles();

  return (
    <Grid
      container
      id={"body_grid"}
      className={clsx(classes.flexRow, classes.end)}
    >
      <Grid
        item
        xs={3}
        id={"bodyTree_grid"}
        style={{ padding: 10 }}
        className={clsx(classes.flex, classes.end)}
      >
        <Paper
          elevation={3}
          id={"bodyTree_paper"}
          className={clsx(classes.flex, classes.end)}
          style={
            !(essenceLoading && essence && essence.length)
              ? { justifyContent: "center", alignItems: "center", padding: 10  }
              : { justifyContent: "stretch", alignItems: "stretch", padding: 10 }
          }
        >
          <FFLoaderComponent
            key={"treeEssence"}
            loading={essenceLoading}
            isLoader={<FFLoader />}
            component={<FFTreeEssence essence={essence} />}
          />
        </Paper>
      </Grid>
      <Grid
        item
        xs={9}
        id={"bodyMain_grid"}
        style={{ padding: 10 }}
        className={clsx(classes.flex, classes.end)}
      >
        <Paper
          elevation={3}
          style={{ padding: 8 }}
          className={clsx(classes.flex, classes.end)}
        >
          <FFLoaderComponent
            loading={activeEssence && panels ? true : false}
            isLoader={
              <Typography
                variant="h1"
                component="h2"
                style={{ color: "lightgray", marginTop: "20%" }}
              >
                Сущность не выбрана
              </Typography>
            }
            component={
              <ListPanel
                key={panels.length}
                panels={panels}
                essence={activeEssence}
                key={"ListPanel" + panels.length}
                toEdit={toEdit}
                setActivePanel={props.setActivePanel}
              />
              // <FF_Essence_Card
              //   key={
              //     activeEssence
              //       ? 'FF_Template_Card_' +
              //         activeEssence.id +
              //         '_' +
              //         te +
              //         clickChecked
              //       : 'FF_Template_Card_temp'
              //   }
              //   classifier={classifier}
              //   parameter={parameter}
              //   essence={activeEssence ? activeEssence : null}
              //   children={children}
              //   metaEssence={metaEssence}
              // />
            }
          />
        </Paper>
      </Grid>
    </Grid>
  );
};

const ListPanel = (props) => {
  const classes = useStylesPanels();

  // console.log(props)
  // console.log(props.panels.length ? props.panels[0].id : '')

  const [panels, setPanels] = useState(
    props.panels.filter((obj) => obj.essence === props.essence.id)
  );

  useEffect(() => {
    setPanels(props.panels.filter((obj) => obj.essence === props.essence.id));
    // console.log(props.panels)
  }, [props.essence.id, props.panels]);

  // console.log('111', panels, props.panels, props.essence.id, props.panels.filter(obj => obj.essence === props.essence.id))

  const [te, setTe] = useState(0);

  let metaTable = [
    {
      widthColumn: 6,
      data: [
        {
          type: "ffListTextFields",
          style: {},
          name_attr: ["label", "label_eng"],
          // dis: true,
          label_attr: ["Наименование", "Наименование на латинском"],
        },
      ],
    },
    {
      widthColumn: 6,
      data: [
        {
          type: "ffListTextFields",
          style: {},
          name_attr: ["name", "description"],
          // dis: true,
          label_attr: ["Системное наименование", "Описание"],
        },
      ],
    },
  ];

  const [expanded, setExpanded] = React.useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    props.setActivePanel(panel);
    setExpanded(isExpanded ? panel : false);
  };

  const handleChangeFFTextFields = (value, id, name, element) => {
    if (element) {
      let temp = panels.filter((obj) => obj.id === id)[0];
      temp[name] = value;
      // console.log(temp, value, name, panels)
      setTe(te + 1);
      // setPanels(temp)
    }
    return "";
  };

  return (
    <Context.Provider value={{ handleChangeFFTextFields }}>
      <Grid
        item
        xs={12}
        style={{ overflow: "overlay" }}
        key={
          "GridPanel_" +
          (props.panels.length ? props.panels[0].id : "") +
          panels.length
        }
      >
        {/* {console.log(panels)} */}
        {panels.length ? (
          <Card style={{ margin: 4 }} key={"CardPanels"}>
            <CardHeader
              avatar={
                <Avatar aria-label="recipe" className={classes.avatar}>
                  {panels.length}
                </Avatar>
              }
              action={
                <IconButton aria-label="settings">
                  <MoreVertIcon />
                </IconButton>
              }
              title={`Список доступных панелей для выбранной сущности (${props.essence.label})`}
              subheader="Метаданные панели"
            />
            <CardContent>
              {/* {console.log(panels)} */}
              {panels.map((tempPanel) => {
                return (
                  <ExpansionPanel
                    key={tempPanel.id}
                    expanded={expanded === tempPanel.id}
                    onChange={handleChange(tempPanel.id)}
                  >
                    <ExpansionPanelSummary
                      key={"header" + tempPanel.id}
                      className={classes.expandHeader}
                      // style={{ background: '#e8eaf6' }}
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls={tempPanel.id + "-content"}
                      id={tempPanel.id + "-header"}
                    >
                      {/* <FFToggle
                        id={'toogle'}
                        name={'toggle'}
                        value={0}
                        label={'Активировать'}
                      /> */}
                      <Typography className={classes.heading}>
                        {tempPanel.label}
                      </Typography>
                      <Divider
                        orientation="vertical"
                        style={{ margin: "0px 10px 0px" }}
                        flexItem
                      />
                      <Typography className={classes.secondaryHeading}>
                        {tempPanel.description}
                        {/* I am an expansion panel */}
                      </Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      <Grid item xs={12} container>
                        {metaTable.map((metaColumn, numColumn) => {
                          return (
                            <Grid
                              item
                              xs={metaColumn.widthColumn}
                              key={"GridMainMeta_Column" + numColumn}
                            >
                              {metaColumn.data.map((metaCell, i) => {
                                return (
                                  <FFRouterComponents
                                    key={"FFRouterComponentsMainMeta_Row" + i}
                                    style={{ margin: 10 }}
                                    parameter={tempPanel}
                                    meta_cell={metaCell}
                                  />
                                );
                              })}
                            </Grid>
                          );
                        })}
                        <Grid item xs={6}>
                          <FFTextFields
                            key={"FFTextFields_" + props.essence.id}
                            value={props.essence.label}
                            is_filter={false}
                            name={"essence"}
                            label={"Привязанная сущность"}
                            parameter={props.essence}
                            dis={true}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <FFTextFields
                            key={"FFTextFields_" + props.essence.id}
                            value={""}
                            is_filter={false}
                            name={"product"}
                            label={"Продукт"}
                            parameter={props.essence}
                            dis={true}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Button
                            style={{
                              margin: 4,
                              width: "-webkit-fill-available",
                            }}
                            variant="outlined"
                            color="primary"
                            endIcon={<IconSettings />}
                            onClick={() => props.toEdit(tempPanel.id)}
                          >
                            Перейти к редактированию панели
                          </Button>
                        </Grid>
                      </Grid>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                );
              })}
            </CardContent>
          </Card>
        ) : (
          <Typography
            variant="h1"
            component="h2"
            style={{ color: "lightgray", marginTop: "20%" }}
          >
            Доступные панели отсутствуют
          </Typography>
        )}
      </Grid>
    </Context.Provider>
  );
};
