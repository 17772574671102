import React, { useEffect, useState, useContext } from 'react'
import clsx from 'clsx'
// import ReactDOM from 'react-dom'
// import { Redirect } from 'react-router-dom'

// import { makeStyles } from "@material-ui/core/styles";
// import React from 'react';
// import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'
// import Tabs from '@material-ui/core/Tabs'
// import Tab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'
// import Box from '@material-ui/core/Box'
// import Loader from './Loader'
import Context from './context'
// import FFTreeEssence from './App2'

import FFLoaderComponent from './components/FFLoaderComponent'

// import FFTransportList from './components/FFTransportList'
// import ExpansionPanel from '@material-ui/core/ExpansionPanel'
// import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
// import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
// import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Select from '@material-ui/core/Select'
import { Switch, Checkbox } from '@material-ui/core'

// import Input from '@material-ui/core/Input'

// import Table from '@material-ui/core/Table'
// import TableBody from '@material-ui/core/TableBody'
// import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
// import TableHead from '@material-ui/core/TableHead'
// import TableRow from '@material-ui/core/TableRow'
import Badge from '@material-ui/core/Badge'
// import Avatar from '@material-ui/core/Avatar'
// import Chip from '@material-ui/core/Chip'
// import Divider from '@material-ui/core/Divider'
// import TablePagination from '@material-ui/core/TablePagination'
// import FFPaginationAction from './components/FFPaginationAction'
import { SnackbarProvider, useSnackbar } from 'notistack'
// import FFListSwitch from './components/FFListSwitch';
// import FFSelect from './components/FFSelect'
// import FFTextFields from './components/FFTextFields'
// import FFSelectMulti from './components/FFSelectMulti'
import FFRouterComponents from './components/FFRouterComponents'
// import FFTextFields from './components/FFTextFields'
// import FFSelect from './components/FFSelect'

// import FFCheckList from './components/FFCheckList'
// import InputAdornment from '@material-ui/core/InputAdornment'
// import FFPanelMenu from './components/FFPanelMenu'
// import FFPersistentDrawerRight from './components/FFPersistentDrawerRight'
import Grid from '@material-ui/core/Grid'
// import TextField from '@material-ui/core/TextField'
// import MoreVertIcon from '@material-ui/icons/MoreVert'
// import Drawer from '@material-ui/core/Drawer'
import FFTreeEssence from './components/FFTreeEssence'
// import Button from '@material-ui/core/Button';
// import List from '@material-ui/core/List'
// import Card from '@material-ui/core/Card'
// import FFTester from './FFTester'
// import Divider from '@material-ui/core/Divider';
// import ListItem from '@material-ui/core/ListItem';
// import ListItemIcon from '@material-ui/core/ListItemIcon';
// import ListItemText from '@material-ui/core/ListItemText';
// import InboxIcon from '@material-ui/icons/MoveToInbox';
// import MailIcon from '@material-ui/icons/Mail';
import IconButton from '@material-ui/core/IconButton'

import IconSettings from '@material-ui/icons/Settings'

import IconDelete from '@material-ui/icons/Delete'
// import IconFilter from '@material-ui/icons/FilterList'
// import IconSearch from '@material-ui/icons/Search'
import IconAdd from '@material-ui/icons/Add'
import IconSave from '@material-ui/icons/Save'
import IconRefresh from '@material-ui/icons/Refresh'
// import IconBuild from '@material-ui/icons/Build'
// import IconReplay from '@material-ui/icons/Replay'
// import IconCancel from '@material-ui/icons/Cancel'
// import IconUpdate from '@material-ui/icons/Update'

// import FFFilterLabel from './components/FFFilterLabel'
// import FFToggle from './components/FFToggle'

// import CardHeader from '@material-ui/core/CardHeader'
// import TreeItem from '@material-ui/lab/TreeItem'
// import TreeView from '@material-ui/lab/TreeView'
// import Essence from './essence_action_info.json'
// import CancelIcon from '@material-ui/icons/Cancel';
// import Context from './context'
// import FileCls from './cls.json'
// import FileParameter from './parameter.json'
import { Button } from '@material-ui/core'
// import SwipeableViews from 'react-swipeable-views';
// import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
// import ArrowRightIcon from '@material-ui/icons/ArrowRight'
// import Remove from '@material-ui/icons/Remove'
// import FileBaseLine from './fileSrc/scenarios_model.json'

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Slide from '@material-ui/core/Slide'

import { withStyles } from '@material-ui/core/styles'

import { green } from '@material-ui/core/colors'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import CheckCircle from '@material-ui/icons/CheckCircle'
import DoneOutline from '@material-ui/icons/DoneOutline'
// import FFPanelMenu from "./components/FFPanelMenu";

// import SortableTree from 'react-sortable-tree/dist/index.esm.js'
// import 'react-sortable-tree/style.css'

import cookie from 'react-cookies'
import Loader from './Loader'

// import InputLabel from '@material-ui/core/InputLabel'
import InputAdornment from '@material-ui/core/InputAdornment'
// import FormControl from '@material-ui/core/FormControl'
import SearchIcon from '@material-ui/icons/Search'
import Tooltip from '@material-ui/core/Tooltip'

import FilledInput from '@material-ui/core/FilledInput'
// import { Tooltip } from 'highcharts'
import { ApiConnect, urlConfiguration } from './ApiConnectNew'
import FFDialogDelete from './libft/FFDialogDelete'
import { getIsFull } from '../features'
import { useSelector } from 'react-redux'

import {v4 as uuidv4} from 'uuid'

const useStyles = makeStyles({
  margin: {
    marginTop: '10px',
    marginLeft: '10px',
    marginBottom: '5px'
  },
  padding: {
    // paddingLeft: 20,
    paddingRight: 10
  },
  textField: {
    marginLeft: 1,
    marginRight: 1,
    width: '100%'
  },
  formControl: {
    minWidth: 250
  },
  formControl2: {
    minWidth: 120,
    maxWidth: 300
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  chip: {
    margin: 2
  },
  flex: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
    height: '100%'
  },
  end: {
    flexGrow: 3
  },
  tree: {
    padding: 8,
    margin: 8
  },
  flexEnd: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    flexFlow: 3
  },
  scroller: {
    // maxHeight: '-webkit-fill-available',
    overflow: 'overlay'
  }
})

const GreenCheckbox = withStyles({
  root: {
    color: green[400],
    '&$checked': {
      color: green[600]
    }
  },
  checked: {}
})(props => <Checkbox color='default' {...props} />)

const Transition = React.forwardRef(function Transition (props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})

export default function FF_Classifier () {
  // -------------------------------------------------------------------------------
  //
  //                                   Стили
  //
  // -------------------------------------------------------------------------------

  const classes = useStyles()

  // -------------------------------------------------------------------------------
  //
  //                                 Состояния
  //
  // -------------------------------------------------------------------------------

  const strAuthorization = 'Bearer ' + cookie.load('access_token')
  const { isLogin } = useContext(Context)
  const [loading, setLoading] = useState(true)
  const [essence, setEssence] = useState([])
  const [essenceLoading, setEssenceLoading] = useState(false)
  const [activeEssence, setActiveEssence] = useState()
  const [children, setChildren] = useState({})
  const [isEdit, setIsEdit] = useState(0)
  const [te, setTe] = useState(0)
  const [isOpenDialogDelete, setIsOpenDialogDelete] = useState(false)
  const isFull = useSelector(getIsFull);


  useEffect(() => {
    getData(
      '/api/config/ontological_classifier/tree/',
      setEssence,
      setEssenceLoading
    )
  }, [])

  // -------------------------------------------------------------------------------
  //
  //                                 Запросы в Бэк
  //
  // -------------------------------------------------------------------------------

  const getData = (url, setterEssence, setterLoading) => {
    fetch(urlConfiguration + url, {
      method: 'get',
      headers: new Headers({
        Authorization: strAuthorization,
        'Content-Type': 'application/json'
      })
    })
      .then(response => {
        if (response.status === 401) {
          isLogin(false)
        }
        return response.json()
      })
      .then(temp => {
        setterEssence(temp)
        setTe(uuidv4())
        if (setterLoading) {
          setterLoading(true)
        }
      })
  }

  const createDate = (url, objToCreate) => {
    fetch(urlConfiguration + url, {
      method: 'post',
      body: JSON.stringify(objToCreate),
      headers: new Headers({
        Authorization: strAuthorization,
        'Content-Type': 'application/json'
      })
    })
      .then(response => {
        if (response.status === 401) {
          isLogin(false)
        }
        return response.json()
      })
      .then(temp => {
        handleClickVariant(
          temp['msg'].indexOf('спешно') !== -1 ? 'success' : 'error',
          temp['msg']
        )
      })
  }

  const deleteDate = (url, objToDelete) => {
    fetch(urlConfiguration + url, {
      method: 'delete',
      body: JSON.stringify(objToDelete),
      headers: new Headers({
        Authorization: strAuthorization,
        'Content-Type': 'application/json'
      })
    })
      .then(response => {
        if (response.status === 401) {
          isLogin(false)
        }
        return response.json()
      })
      .then(temp => {
        handleClickVariant(
          temp['msg'].indexOf('спешно') !== -1 ? 'success' : 'error',
          temp['msg']
        )
      })
  }

  const updateDate = (url, objToCreate) => {
    fetch(urlConfiguration + url, {
      method: 'put',
      body: JSON.stringify(objToCreate),
      headers: new Headers({
        Authorization: strAuthorization,
        'Content-Type': 'application/json'
      })
    })
      .then(response => {
        if (response.status === 401) {
          isLogin(false)
        }
        return response.json()
      })
      .then(temp => {
        handleClickVariant(
          temp['msg'].indexOf('спешно') !== -1 ? 'success' : 'error',
          temp['msg']
        )
      })
  }

  const { enqueueSnackbar } = useSnackbar()
  const handleClickVariant = (variant, msg) => {
    // variant could be success, error, warning, info, or default
    enqueueSnackbar(
      msg,
      { variant },
      {
        anchorOrigin: { vertical: 'top', horizontal: 'right' }
      }
    )
  }
  // -------------------------------------------------------------------------------
  //
  //                                 Обработчики событий
  //
  // -------------------------------------------------------------------------------

  // Нажатие на классификационное значение в меню
  const doClickActiveEssence = (objId, obj) => {
    if (essence.id !== objId) {
      setChildren(obj)
      getData(
        '/api/config/ontological_classifier/?id=' + objId,
        setActiveEssence,
        null
      )
    } else {
      setActiveEssence(null)
    }
  }

  // Обработчик на кнопку добавления
  const doAddEssence = () => {
    // console.log(activeEssence)
    let tempId = uuidv4()
    let temp = {
      id: tempId,
      name: 'Cls_value_' + tempId.split('-')[0],
      parent: activeEssence ? activeEssence.id : null,
      label: 'Cls_value_' + tempId.split('-')[0],
      label_eng: 'Cls_value_' + tempId.split('-')[0],
      description: 'Созданное значение',
      is_system: false,
      is_default_value: false,
      value: 'Cls_value_' + tempId.split('-')[0]
    }
    createDate('/api/config/ontological_classifier/', temp)
    doRefresh()
  }

  // Обработчик для обновления
  const doRefresh = () => {
    setTimeout(() => {
      getData(
        '/api/config/ontological_classifier/tree/',
        setEssence,
        setEssenceLoading
      )
      setTe(te + 1)
    }, 100)
  }

  // Обработчик для удаления
  const doDeleteEssence = () => {
    if (activeEssence) {
      deleteDate('/api/config/ontological_classifier/', {
        id: activeEssence.id
      })
    }
    doRefresh()
  }

  const doSaveEssence = () => {
    let tempEssence = activeEssence
    tempEssence.parent =
      tempEssence.parent && 'id' in tempEssence.parent
        ? tempEssence.parent.id
        : null
    updateDate('/api/config/ontological_classifier/', tempEssence)
    doRefresh()
  }
  // Обработчик для текстовых полей
  const changeTextField = (value, parameter_id, name, element) => {
    let temp = activeEssence
    temp[name] = value
    setActiveEssence(temp)
    return ''
  }

  // Обработчик для ввода или переназначения родителя
  const doEditParent = tempParent => {
    let tempActive = activeEssence
    if (tempActive) {
      tempActive.parent = tempParent
    }
    setTe(te + 1)
    setActiveEssence(tempActive)
    let tempEssence = activeEssence
    tempEssence.parent =
      tempEssence.parent && 'id' in tempEssence.parent
        ? tempEssence.parent.id
        : null
    updateDate('/api/config/ontological_classifier/?parent=true', activeEssence)
    doRefresh()
  }

  const doChangeDefaultValue = (nodeId, defaultId) => {
    updateDate('/api/config/ontological_classifier/?change_default=true', {
      root: nodeId,
      default_value: defaultId
    })
    doRefresh()
  }
  // -------------------------------------------------------------------------------
  //
  //                                    Рендеринг
  //
  // -------------------------------------------------------------------------------

  return (
    <Context.Provider
      value={{
        doClickActiveEssence,
        changeTextField,
        doEditParent,
        doChangeDefaultValue
      }}
    >
      {isOpenDialogDelete && (
        <FFDialogDelete
          open={isOpenDialogDelete}
          setOpen={setIsOpenDialogDelete}
          isAgree={e => doDeleteEssence()}
        />
      )}

      <Grid container>
        <Grid item xs={7}></Grid>
        <Grid item xs={5} align='right'>
          {true === true ? (
            <Tooltip title='Создать новое классификационное значение'>
              <IconButton
                aria-label='filter'
                onClick={e => doAddEssence()}
                className={classes.margin}
                disabled={!isFull}
                style={{ visibility: isEdit === 0 ? 'visible' : 'hidden' }}
              >
                <IconAdd color={!isFull ? 'gray' : 'primary'} fontSize='large' />
              </IconButton>
            </Tooltip>
          ) : null}
          {true === true ? (
            <Tooltip title='Сохранить'>
              <IconButton
                aria-label='filter'
                onClick={e => doSaveEssence()}
                className={classes.margin}
                disabled={!isFull}
                style={{ visibility: isEdit === 0 ? 'visible' : 'hidden' }}
              >
                <IconSave color={!isFull ? 'gray' : 'primary'} fontSize='large' />
              </IconButton>
            </Tooltip>
          ) : null}
          {true === true ? (
            <Tooltip title='Обновить дерево'>
              <IconButton
                aria-label='filter'
                onClick={e => doRefresh()}
                className={classes.margin}
                style={{ visibility: isEdit === 0 ? 'visible' : 'hidden' }}
              >
                <IconRefresh color='primary' fontSize='large' />
              </IconButton>
            </Tooltip>
          ) : null}
          {true === true ? (
            <Tooltip title='Удалить'>
              <IconButton
                aria-label='filter'
                onClick={e => setIsOpenDialogDelete(true)}
                className={classes.margin}
                disabled={!isFull}
                style={{ visibility: isEdit === 0 ? 'visible' : 'hidden' }}
              >
                <IconDelete color={!isFull ? 'gray' : 'error'} fontSize='large' />
              </IconButton>
            </Tooltip>
          ) : null}
        </Grid>
      </Grid>

      <TableContainer className={clsx(classes.flex, classes.end)}>
        <Grid
          container
          id={'body_grid'}
          className={clsx(classes.flexRow, classes.end)}
        >
          <Grid
            item
            xs={3}
            id={'bodyTree_grid'}
            style={{ padding: 10 }}
            className={clsx(classes.flex, classes.end)}
          >
            <Paper
              elevation={3}
              id={'bodyTree_paper'}
              className={clsx(classes.flex, classes.end)}
              style={{ padding: 10 }}
            >
              <FFLoaderComponent
                // key={te}
                loading={essenceLoading}
                isLoader={<Loader />}
                component={
                  <FFTreeEssence
                    essence={[essence]}
                    key={'FFClassifier_treeEssence'}
                    id={'FFClassifier_treeEssence'}
                  />
                }
              />
            </Paper>
          </Grid>
          <Grid
            item
            xs={9}
            id={'bodyMain_grid'}
            className={clsx(classes.flex, classes.end)}
          >
            <FFLoaderComponent
              loading={activeEssence ? true : false}
              isLoader={
                <Paper
                  elevation={3}
                  style={{ margin: 8, padding: 8 }}
                  className={clsx(classes.flex, classes.end)}
                >
                  <Typography
                    variant='h1'
                    component='h2'
                    style={{ color: 'lightgray', marginTop: '20%' }}
                  >
                    Классификационное значение не выбрано
                  </Typography>
                </Paper>
              }
              component={
                <FF_Classifier_Card
                  key={
                    activeEssence
                      ? 'FF_Classifier_Card_' + activeEssence.id + te
                      : 'FF_Classifier_Card_temp'
                  }
                  essences={essence}
                  essence={activeEssence ? activeEssence : null}
                  children={children}
                />
              }
            />
          </Grid>
        </Grid>
      </TableContainer>
    </Context.Provider>
  )
}

const FF_Classifier_Card = props => {
  // -------------------------------------------------------------------------------
  //
  //                                   Стили
  //
  // -------------------------------------------------------------------------------

  const classes = useStyles()

  // -------------------------------------------------------------------------------
  //
  //                                 Состояния
  //
  // -------------------------------------------------------------------------------

  const { changeTextField, doEditParent, doChangeDefaultValue } = useContext(
    Context
  )

  let [parentDialog, setParentDialog] = useState(false)
  let [parentObj, setParentObj] = useState({})
  let [defaultValue, setDefaultValue] = useState('')
  let [te, setTe] = useState(0)
  const isFull = useSelector(getIsFull);


  let mataCellParent = {
    type: 'ffListTextFields',
    style: { width: '100px' },
    name_attr: ['label'],
    multiline: true,
    dis: props.essence['is_system'],
    label_attr: ['Родитель']
  }

  let metaTable = [
    {
      type: 'ffListTextFields',
      style: { width: '100px' },
      name_attr: ['name', 'label', 'label_eng', 'value'],
      dis: props.essence['is_system'],
      label_attr: [
        'Системное наименование',
        'Наименование',
        'Наименование на латинском',
        'Значение для модели'
      ]
    },
    {
      type: 'ffTextFields',
      style: { width: '100px' },
      name_attr: ['description'],
      multiline: true,
      dis: props.essence['is_system'],
      label_attr: ['Описание']
    }
  ]

  // -------------------------------------------------------------------------------
  //
  //                                 Обработчики событий
  //
  // -------------------------------------------------------------------------------

  useEffect(() => {
    console.log(props.children)
    let a = props.children.children.filter(obj => obj.is_default_value === true)
    if (a.length > 0) {
      setDefaultValue(a[0].id)
    }
  }, [])

  const doClickEditParent = () => {
    setParentDialog(true)
  }

  const closeParentDialog = () => {
    setParentDialog(false)
  }

  const closeAgreeParentDialog = () => {
    doEditParent(parentObj)
    setParentDialog(false)
  }

  const doClickActiveEssenceDialog = obj => {
    let tempParent = { id: obj.id, label: obj.label, label_eng: obj.label_eng }
    if (tempParent.id === props.essences.id) tempParent = null
    setParentObj(tempParent)
  }

  const handleChangeFFTextFields = (value, parameter_id, name, element) => {
    changeTextField(value, parameter_id, name, element)
    return ''
  }

  const changeDefaultValue = event => {
    let currentId = event.target.value
    props.children.children.map(obj => {
      obj.is_default_value = false
    })
    props.children.children.filter(
      obj => obj.id === currentId
    )[0].is_default_value = true
    setDefaultValue(event.target.value)
    doChangeDefaultValue(props.essence.id, event.target.value)
    setTe(te + 1)
  }

  const onChangeSystem = () => {
    props.essence.is_system = !props.essence.is_system
    changeTextField(props.essence.is_system, '', 'is_system', '')
    setTe(te + 1)
  }

  // -------------------------------------------------------------------------------
  //
  //                                    Рендеринг
  //
  // -------------------------------------------------------------------------------

  return props.essence ? (
    <Context.Provider
      value={{ doClickActiveEssenceDialog, handleChangeFFTextFields }}
    >
      <React.Fragment>
        <Dialog
          open={parentDialog}
          onClose={closeParentDialog}
          aria-labelledby='FFClassifier_FFClassifierCard_Dialog_parentTree'
          aria-describedby='FFClassifier_FFClassifierCard_Dialog_parentTree'
        >
          <DialogTitle id='FFClassifier_FFClassifierCard_DialogTitle_parentTree'>
            {'Укажите нового родителя?'}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id='FFClassifier_FFClassifierCard_DialogContentText_parentTree'>
              <FFLoaderComponent
                loading={true}
                isLoader={<Loader />}
                component={
                  <FFTreeEssence
                    essence={[props.essences]}
                    dialog={true}
                    key={
                      'FFClassifier_FFClassifierCard_DialogContentText_Dialog'
                    }
                    id={
                      'FFClassifier_FFClassifierCard_DialogContentText_Dialog'
                    }
                  />
                }
              />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeAgreeParentDialog} color='secondary'>
              Сохранить
            </Button>
            <Button onClick={closeParentDialog} color='primary'>
              Вернутся к редактированию
            </Button>
          </DialogActions>
        </Dialog>

        <Grid
          item
          xs={12}
          id={'FFClassifier_FFClassifierCard_GridBody'}
          className={classes.end}
        >
          <Paper
            elevation={3}
            style={{ margin: 8, padding: 8 }}
            className={classes.end}
          >
            <FormControlLabel
              style={{ marginLeft: 4, marginBottom: -4 }}
              key={'FFClassifier_FFClassifierCard_isSystem_' + te}
              control={
                <GreenCheckbox
                  key={'FFClassifier_FFClassifierCard_isSystem_' + te}
                  icon={<DoneOutline color='disabled' />}
                  checkedIcon={<CheckCircle />}
                  checked={props.essence.is_system}
                  onChange={onChangeSystem}
                />
              }
              // onChange={(e) => onChangeActive}
              label={
                props.essence.is_system ? 'Системный' : 'Установить системным'
              }
            />
            <Badge
              color='primary'
              className={clsx(classes.margin, classes.padding)}
              badgeContent={
                props.essence.count_parameters
                  ? props.essence.count_parameters
                  : '0'
              }
            >
              <Typography>
                Выбранное значение используется в: параметрах
              </Typography>
            </Badge>
            <Badge
              color='primary'
              className={clsx(classes.margin, classes.padding)}
              badgeContent={
                props.essence.count_templates
                  ? props.essence.count_templates
                  : '0'
              }
            >
              <Typography>, шаблонах</Typography>
            </Badge>
            <Badge
              color='primary'
              className={clsx(classes.margin, classes.padding)}
              badgeContent={
                props.essence.count_essences
                  ? props.essence.count_essences
                  : '0'
              }
            >
              <Typography>и сущностях</Typography>
            </Badge>
            <Grid container>
              <Grid item xs={11}>
                <FFRouterComponents
                  style={{ margin: 10 }}
                  parameter={
                    props.essence.parent
                      ? props.essence.parent
                      : { label: 'Корневой классификатор' }
                  }
                  meta_cell={mataCellParent}
                />
              </Grid>
              <Grid item xs={1} style={{ textAlignLast: 'center' }}>
                <IconButton
                  aria-label='filter'
                  onClick={e => doClickEditParent()}
                  disabled={!isFull}
                >
                  <IconSettings color={!isFull ? 'gray' : 'primary'} fontSize='large' />
                </IconButton>
              </Grid>
            </Grid>
            {metaTable.map(metaCell => {
              return (
                <FFRouterComponents
                  style={{ margin: 10 }}
                  parameter={props.essence}
                  meta_cell={metaCell}
                />
              )
            })}
            {props.essence.parent ? null : (
              <FormControl
                variant='filled'
                style={{ margin: 3, width: '100%' }}
              >
                <InputLabel id='FFClassifier_FFClassifierCard_InputLabel_defaultValue'>
                  Значение по умолчанию
                </InputLabel>
                <Select value={defaultValue} onChange={changeDefaultValue}>
                  {props.children.children.map(objChild => {
                    return (
                      <MenuItem value={objChild.id}>{objChild.label}</MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            )}
          </Paper>
        </Grid>
      </React.Fragment>
    </Context.Provider>
  ) : null
}
