import React from "react";
import { Modal } from "antd";
import { useDispatch } from "react-redux";
import { deleteActiveEssence } from "../../slice";

export const ConfirmDeleteModal = ({ open, setOpen }) => {
  const dispatch = useDispatch();

  const handleClickDelete = () => {
    dispatch(deleteActiveEssence());
  };

  const hideModal = () => {
    setOpen(false);
  };

  return (
    <Modal
      title="Подтверждение"
      open={open}
      onOk={handleClickDelete}
      onCancel={hideModal}
      okText="Да, удаляем"
      cancelText="Отмена"
    >
      Вы действительно хотите удалить объект Скада
    </Modal>
  );
};
