import React, { useEffect, useState, useContext } from 'react'
// import ReactDOM from 'react-dom'
import { Redirect } from 'react-router-dom'

// import { makeStyles } from "@material-ui/core/styles";
// import React from 'react';
// import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'
// import Tabs from '@material-ui/core/Tabs'
// import Tab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'
import FFSteps from './components/FFSteps'
// import Box from '@material-ui/core/Box'
// import Loader from './Loader'
import Context from './context'
// import FFTreeEssence from './App2'
import clsx from 'clsx'

import FFLoaderComponent from './components/FFLoaderComponent'

// import FFTransportList from './components/FFTransportList'
// import ExpansionPanel from '@material-ui/core/ExpansionPanel'
// import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
// import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
// import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Select from '@material-ui/core/Select'
import { Switch, Checkbox } from '@material-ui/core'

// import Input from '@material-ui/core/Input'

// import Table from '@material-ui/core/Table'
// import TableBody from '@material-ui/core/TableBody'
// import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
// import TableHead from '@material-ui/core/TableHead'
// import TableRow from '@material-ui/core/TableRow'
import Badge from '@material-ui/core/Badge'
// import Avatar from '@material-ui/core/Avatar'
// import Chip from '@material-ui/core/Chip'
// import Divider from '@material-ui/core/Divider'
// import TablePagination from '@material-ui/core/TablePagination'
// import FFPaginationAction from './components/FFPaginationAction'
import { SnackbarProvider, useSnackbar } from 'notistack'
// import FFListSwitch from './components/FFListSwitch';
// import FFSelect from './components/FFSelect'
import FFTextFields from './components/FFTextFields'
// import FFSelectMulti from './components/FFSelectMulti'
import FFRouterComponents from './components/FFRouterComponents'
// import FFTextFields from './components/FFTextFields'
// import FFSelect from './components/FFSelect'

// import FFCheckList from './components/FFCheckList'
// import InputAdornment from '@material-ui/core/InputAdornment'
// import FFPanelMenu from './components/FFPanelMenu'
// import FFPersistentDrawerRight from './components/FFPersistentDrawerRight'
import Grid from '@material-ui/core/Grid'
// import TextField from '@material-ui/core/TextField'
// import MoreVertIcon from '@material-ui/icons/MoreVert'
// import Drawer from '@material-ui/core/Drawer'
import FFTreeEssence from './components/FFTreeEssence'
// import Button from '@material-ui/core/Button';
// import List from '@material-ui/core/List'
import Card from '@material-ui/core/Card'
// import FFTester from './FFTester'
// import Divider from '@material-ui/core/Divider';
// import ListItem from '@material-ui/core/ListItem';
// import ListItemIcon from '@material-ui/core/ListItemIcon';
// import ListItemText from '@material-ui/core/ListItemText';
// import InboxIcon from '@material-ui/icons/MoveToInbox';
// import MailIcon from '@material-ui/icons/Mail';
import IconButton from '@material-ui/core/IconButton'

import IconSettings from '@material-ui/icons/Settings'

import IconDelete from '@material-ui/icons/Delete'
// import IconFilter from '@material-ui/icons/FilterList'
// import IconSearch from '@material-ui/icons/Search'
import IconLibraryAdd from '@material-ui/icons/LibraryAdd'
import IconAdd from '@material-ui/icons/Add'
import IconSave from '@material-ui/icons/Save'
import IconRefresh from '@material-ui/icons/Refresh'
import Tooltip from '@material-ui/core/Tooltip'

// import IconBuild from '@material-ui/icons/Build'
// import IconReplay from '@material-ui/icons/Replay'
// import IconCancel from '@material-ui/icons/Cancel'
// import IconUpdate from '@material-ui/icons/Update'

// import FFFilterLabel from './components/FFFilterLabel'
// import FFToggle from './components/FFToggle'

// import CardHeader from '@material-ui/core/CardHeader'
// import TreeItem from '@material-ui/lab/TreeItem'
// import TreeView from '@material-ui/lab/TreeView'
// import Essence from './essence_action_info.json'
// import CancelIcon from '@material-ui/icons/Cancel';
// import Context from './context'
// import FileCls from './cls.json'
// import FileParameter from './parameter.json'
import { Button } from '@material-ui/core'
// import SwipeableViews from 'react-swipeable-views';
// import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
// import ArrowRightIcon from '@material-ui/icons/ArrowRight'
// import Remove from '@material-ui/icons/Remove'
// import FileBaseLine from './fileSrc/scenarios_model.json'

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Slide from '@material-ui/core/Slide'

import { withStyles } from '@material-ui/core/styles'

import { green } from '@material-ui/core/colors'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import CheckCircle from '@material-ui/icons/CheckCircle'
import DoneOutline from '@material-ui/icons/DoneOutline'
// import FFPanelMenu from "./components/FFPanelMenu";

// import SortableTree from 'react-sortable-tree/dist/index.esm.js'
// import 'react-sortable-tree/style.css'

// import FF_Classifier_step3_formatData_valueRange from './commonComponents/FF_Classifier_step3_formatData_valueRange'
// import FF_Classifier_step3_formatData_valueList from './commonComponents/FF_Classifier_step3_formatData_valueList'
// import FF_Classifier_step3_formatData_list from './commonComponents/FF_Classifier_step3_formatData_list'
import FF_Classifier_step3_formatData_valueList from './commonComponents/FF_Classifier_step3_formatData_valueList'
import FF_Classifier_step3_formatData_valueRange from './commonComponents/FF_Classifier_step3_formatData_valueRange'
import FF_Classifier_step3_formatData_intervalRange from './commonComponents/FF_Classifier_step3_formatData_intervalRange'
import FFDialogDelete from './libft/FFDialogDelete'
import cookie from 'react-cookies'
import Loader from './Loader'
import { ApiConnect, urlConfiguration } from './ApiConnectNew'
import { getIsFull } from '../features'
import { useSelector } from 'react-redux'

import {v4 as uuidv4} from 'uuid'

const useStyles = makeStyles({
  margin: {
    marginTop: '10px',
    marginLeft: '10px',
    marginBottom: '5px'
  },
  padding: {
    // paddingLeft: 20,
    paddingRight: 10
  },
  textField: {
    marginLeft: 1,
    marginRight: 1,
    width: '100%'
  },
  formControl: {
    minWidth: 250
  },
  formControl2: {
    minWidth: 120,
    maxWidth: 300
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  chip: {
    margin: 2
  },
  flex: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
    height: '100%'
  },
  end: {
    flexGrow: 3
  },
  tree: {
    padding: 8,
    margin: 8
  },
  flexEnd: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    flexFlow: 3
  },
  scroller: {
    // maxHeight: '-webkit-fill-available',
    overflow: 'overlay'
  }
})

export default function FF_ParametersNew (props) {
  // -------------------------------------------------------------------------------
  //
  //                                   Стили
  //
  // -------------------------------------------------------------------------------

  const classes = useStyles()

  // -------------------------------------------------------------------------------
  //
  //                                 Мета Описание
  //
  // -------------------------------------------------------------------------------

  const metaEssence = {
    label: {
      label: 'Наименование',
      is_unique: true,
      is_required: true,
      is_type: 'string'
    },
    label_eng: {
      label: 'Наименование на латинском',
      is_unique: true,
      is_required: true,
      is_type: 'string'
    },
    name: {
      label: 'Системное наименование',
      is_unique: true,
      is_required: true,
      is_type: 'string'
    },
    description: {
      label: 'Описание',
      is_unique: false,
      is_required: false,
      is_type: 'string'
    },
    active_system_classifier_values: {
      label: 'Другая история',
      is_unique: true,
      is_required: true,
      is_type: 'dict'
    },
    limits: {
      label: 'Другая история',
      is_unique: true,
      is_required: true,
      is_type: 'dict'
    },
    is_control: {
      label: 'Управляющий',
      labelToolTip:
        'Управляющий - признак (может быть только у параметров, для которых источник\
      данных  - стрим) того, что добавив к топику из параметра /on, можно отправить \
      управляющее воздействие на данный параметр.',
      is_unique: false,
      is_required: false,
      is_type: 'boolean'
    },
    is_deleted: {
      label: 'Удален',
      is_unique: false,
      is_required: false,
      is_type: 'boolean'
    },
    is_model: {
      label: 'Модельный',
      labelToolTip:
        'Модельный параметр - признак принадлежности к какой-либо модели. Редактирование\
    после создания невозможно (если есть привязки к сущностям и/или моделям, и/или панелям).',
      is_unique: false,
      is_required: false,
      is_type: 'boolean'
    },
    is_phase: {
      label: 'Токовый',
      labelToolTip:
        'Токовый - признак того, что параметр представляет собой набор значений \
    соотвествующих фазам параметра оборудования. Редактирование после создания невозможно \
    (если есть привязки к сущностям и/или моделям,',
      is_unique: false,
      is_required: false,
      is_type: 'boolean'
    },
    is_required: {
      label: 'Обязательный',
      labelToolTip:
        'Обязательный  - признак, согласно которому при создании сущности \
    и/или параметра он должен быть заполнен.',
      is_unique: false,
      is_required: false,
      is_type: 'boolean'
    },
    is_sum: {
      label: 'Доп.параметр',
      labelToolTip: 'Доп.параметр - признак, наличие которого определяет включение данного\
       параметра для отображения на графиках аналитики вместе с графиками нагрузки',
    //   labelToolTip:
    //     'Суммарный - признак того, что альтернативно можно рассчитать значения  параметра \
    // сущности, сложив значения этого параметра у всех подчиненных сущностей.',
      is_unique: false,
      is_required: false,
      is_type: 'boolean'
    },
    is_system: {
      label: 'Системный',
      labelToolTip:
        'Системный - признак того, что данный параметр \
    используется внутри системы аналитики или управления.',
      is_unique: false,
      is_required: false,
      is_type: 'boolean'
    },
    count_sum: {
      label: 'Количество элементов',
      is_unique: false,
      is_required: false,
      is_type: 'number'
    },
    time_sum_sec: {
      label: 'Время, с',
      is_unique: false,
      is_required: false,
      is_type: 'number'
    },
    len: {
      label: 'Длина',
      is_unique: false,
      is_required: false,
      is_type: 'number'
    },
    interval_start: {
      label: 'Начало интервала',
      is_unique: false,
      is_required: false,
      is_type: 'null'
    },
    interval_end: {
      label: 'Конец интервала',
      is_unique: false,
      is_required: false,
      is_type: 'null'
    },
    is_out: {
      label: 'Выходной',
      is_unique: false,
      is_required: false,
      is_type: 'boolean'
    },
    is_in: {
      label: 'Входной',
      is_unique: false,
      is_required: false,
      is_type: 'boolean'
    }
    // parameters: {
    //   label: 'Список параметров',
    //   is_unique: false,
    //   is_required: false,
    //   is_type: 'list'
    // }
  }

  let isChecked = [
    'is_system',
    'is_required',
    'is_model',
    'is_phase',
    'is_sum',
    'is_control'
  ]

  let workerCls = [
    'p-type-data',
    'p-format-data',
    'p-source-data',
    'aggregation_period',
    'measure_unit',
    'params_type'
  ]

  // -------------------------------------------------------------------------------
  //
  //                                 Состояния
  //
  // -------------------------------------------------------------------------------

  const strAuthorization = 'Bearer ' + cookie.load('access_token')
  const { isLogin } = useContext(Context)
  const [loading, setLoading] = useState(true)
  const [essence, setEssence] = useState([])
  const [essenceLoading, setEssenceLoading] = useState(false)
  const [activeEssence, setActiveEssence] = useState()
  const [children, setChildren] = useState({})
  const [isFinish, setIsFinish] = useState(false)
  const [isEdit, setIsEdit] = useState(0)
  const [classifier, setClassifier] = useState([])
  const [te, setTe] = useState(0)
  const [showEssence, setShowEssence] = useState(false)
  const [isOpenDialogDelete, setIsOpenDialogDelete] = useState(false)
  const isFull = useSelector(getIsFull);

  useEffect(() => {
    getDataPost(
      '/api/config/ontological_classifier/tree/',
      { names: workerCls },
      setClassifier
    )
    doRefresh()
  }, [])

  // -------------------------------------------------------------------------------
  //
  //                                 Запросы в Бэк
  //
  // -------------------------------------------------------------------------------

  const getData = (url, setterEssence, setterLoading) => {
    fetch(urlConfiguration + url, {
      method: 'get',
      headers: new Headers({
        Authorization: strAuthorization,
        'Content-Type': 'application/json'
      })
    })
      .then(response => {
        if (response.status === 401) {
          isLogin(false)
        }
        // else if(response.status !== 200){
        //   handleClickVariant('error', temp['msg'])
        // }
        return response.json()
      })
      .then(temp => {
        if (setterEssence === setActiveEssence) temp = temp[0]
        setterEssence(temp)
        setTe(uuidv4())
        if (setterLoading) {
          setterLoading(true)
        }
      })
  }

  const getDataPost = (url, objToGet, setterEssence) => {
    fetch(urlConfiguration + url, {
      method: 'post',
      body: JSON.stringify(objToGet),
      headers: new Headers({
        Authorization: strAuthorization,
        'Content-Type': 'application/json'
      })
    })
      .then(response => {
        if (response.status === 401) {
          isLogin(false)
        }
        // else if(response.status !== 200){
        //   handleClickVariant('error', temp['msg'])
        // }
        return response.json()
      })
      .then(temp => {
        setterEssence(temp)
      })
  }
  const createData = (url, objToCreate) => {
    fetch(urlConfiguration + url, {
      method: 'post',
      body: JSON.stringify(objToCreate),
      headers: new Headers({
        Authorization: strAuthorization,
        'Content-Type': 'application/json'
      })
    })
      .then(response => {
        if (response.status === 403) {
          isLogin(false)
        }
        // else if(response.status !== 200){
        //   handleClickVariant('error', temp['msg'])
        // }
        return response.json()
      })
      .then(temp => {
        handleClickVariant(
          temp['msg'].indexOf('спешно') !== -1 ? 'success' : 'error',
          temp['msg']
        )
      })
  }

  const deleteDate = (url, objToDelete) => {
    fetch(urlConfiguration + url, {
      method: 'delete',
      body: JSON.stringify(objToDelete),
      headers: new Headers({
        Authorization: strAuthorization,
        'Content-Type': 'application/json'
      })
    })
      .then(response => {
        if (response.status === 401) {
          isLogin(false)
        }
        // else if(response.status !== 200){
        //   handleClickVariant('error', temp['msg'])
        // }
        return response.json()
      })
      .then(temp => {
        handleClickVariant(
          temp['msg'].indexOf('спешно') !== -1 ? 'success' : 'error',
          temp['msg']
        )
      })
  }

  const updateData = (url, objToCreate) => {
    fetch(urlConfiguration + url, {
      method: 'put',
      body: JSON.stringify(objToCreate),
      headers: new Headers({
        Authorization: strAuthorization,
        'Content-Type': 'application/json'
      })
    })
      .then(response => {
        if (response.status === 401) {
          isLogin(false)
        }
        return response.json()
      })
      .then(temp => {
        if (temp['msg'].indexOf('существует') !== -1) {
          handleClickVariant('error',
            temp['msg']
          )
          return;
        }
        localStorage.removeItem('parameterByEssences')
        if ('obj' in temp && temp['obj'].length > 0) {
          let parameterByEssences = {
            clickChecked: false,
            obj: temp['obj'],
            activeParameter: objToCreate.id,
            essenceMassive: true,
          }
          localStorage.setItem(
            'parameterByEssences',
            JSON.stringify(parameterByEssences)
          )
          setShowEssence(true)
        }

        handleClickVariant(
          temp['msg'].indexOf('спешно') !== -1 ? 'success' : 'error',
          temp['msg']
        )
      })
  }

  const { enqueueSnackbar } = useSnackbar()
  const handleClickVariant = (variant, msg) => {
    // variant could be success, error, warning, info, or default
    enqueueSnackbar(
      msg,
      { variant },
      {
        anchorOrigin: { vertical: 'top', horizontal: 'right' }
      }
    )
  }

  // -------------------------------------------------------------------------------
  //
  //                                 Обработчики событий
  //
  // -------------------------------------------------------------------------------

  // Нажатие на классификационное значение в меню
  const doClickActiveEssence = (objId, obj) => {
    setIsFinish(false)
    if (essence.id !== objId) {
      setChildren(obj)
      getData(
        '/api/?entity=parameter_registry&id=' + objId,
        setActiveEssence,
        null
      )
      // getDataPost(
      //   '/api/config/ontological_classifier/tree/',
      //   { names: workerCls },
      //   setClassifier
      // )
    } else {
      setActiveEssence(null)
    }
  }

  // Обработчик на кнопку добавления
  const doAddEssence = () => {
    // console.log(activeEssence)
    let tempId = uuidv4()
    let temp = { id: tempId }
    Object.keys(metaEssence).map(nameAttr => {
      if (metaEssence[nameAttr].is_type === 'string') {
        temp[nameAttr] = nameAttr + '_' + tempId.split('-')[0]
      } else if (metaEssence[nameAttr].is_type === 'dict') {
        temp[nameAttr] = {}
      } else if (metaEssence[nameAttr].is_type === 'boolean') {
        temp[nameAttr] = false
      } else if (metaEssence[nameAttr].is_type === 'number') {
        temp[nameAttr] = 0
      } else if (metaEssence[nameAttr].is_type === 'null') {
        temp[nameAttr] = null
      }
    })
    temp['label'] = '0_New_' + temp['label']
    createData('/api/?entity=parameter_registry', temp)
    doRefresh()
  }

  // Обработчик для обновления
  const doRefresh = () => {
    setTimeout(() => {
      getData(
        '/api/?entity=parameter_registry&is_full=True',
        setEssence,
        setEssenceLoading
      )
      // setTe(te + 1)
    }, 100)
  }

  //Переход в сущности
  const goToEssence = () => {
    props.history.push('/essence')
    props.history.go('/essence')
    setShowEssence(false)
  }

  // Обработчик для удаления
  const doDeleteEssence = () => {
    if (activeEssence) {
      deleteDate('/api/?entity=parameter_registry&id=' + activeEssence.id, null)
    }
    doRefresh()
  }

  // Обработчки для сохранения
  const doSaveEssence = () => {
    let tempEssence = activeEssence
    console.log(tempEssence);
    tempEssence.desactive_system_classifier_values = {}
    updateData('/api/?entity=parameter_registry', tempEssence)

    doRefresh()
  }

  // Обработчик для копирования
  const doAddCopyEssence = () => {
    if (activeEssence) {
      let temp = JSON.parse(JSON.stringify(activeEssence))
      let tempId = uuidv4()
      temp.id = tempId
      temp.name = 'name' + '_' + tempId.split('-')[0]
      temp.label = '0_New_label' + '_' + tempId.split('-')[0]
      temp.label_eng = 'label_eng' + '_' + tempId.split('-')[0]

      createData('/api/?entity=parameter_registry', temp)
      doRefresh()
    } else {
      handleClickVariant('warning', 'Выберите копируемое значение')
    }
  }

  // Обработчик для текстовых полей
  const changeTextField = (value, parameter_id, name, element) => {
    let temp = activeEssence
    temp[name] = value
    setActiveEssence(temp)
    return ''
  }

  const finishSave = value => {
    if (!value) {
      getData(
        '/api/?entity=parameter_registry&id=' + activeEssence.id,
        setActiveEssence,
        null
      )
    }
    setIsFinish(value)
  }

  const toGo = () => {
    return (
      <Switch>
        <Redirect from='/parameters_v2' to='/essence' />
      </Switch>
    )
  }
  // -------------------------------------------------------------------------------
  //
  //                                    Рендеринг
  //
  // -------------------------------------------------------------------------------

  return (
    <Context.Provider
      value={{
        doClickActiveEssence,
        changeTextField,
        finishSave
      }}
    >
      {isOpenDialogDelete && (
        <FFDialogDelete
          open={isOpenDialogDelete}
          setOpen={setIsOpenDialogDelete}
          isAgree={(e) => doDeleteEssence()}
        />
      )}
      <Dialog
        open={showEssence}
        onClose={e => setShowEssence(false)}
        aria-labelledby='FFClassifier_FFClassifierCard_Dialog_parentTree'
        aria-describedby='FFClassifier_FFClassifierCard_Dialog_parentTree'
      >
        <DialogTitle id='FFClassifier_FFClassifierCard_DialogTitle_parentTree'>
          {'К этому параметру привязано ' +
            (JSON.parse(localStorage.getItem('parameterByEssences')) !== null
              ? JSON.parse(localStorage.getItem('parameterByEssences')).obj
                  .length
              : 0) +
            ' сущностей'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='FFClassifier_FFClassifierCard_DialogContentText_parentTree'></DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={goToEssence} color='secondary'>
            Перейти к редактированию сущностей
          </Button>
          <Button onClick={e => setShowEssence(false)} color='primary'>
            Вернутся к редактированию параметров
          </Button>
        </DialogActions>
      </Dialog>

      <Grid container>
        <Grid item xs={4}></Grid>
        <Grid item xs={8} align='right'>
          {activeEssence ? (
            false
          ) : true ? (
            <Tooltip title='Создать новый параметр'>
              <IconButton
                aria-label='filter'
                onClick={e => doAddEssence()}
                className={classes.margin}
                disabled={!isFull}
                style={{ visibility: isEdit === 0 ? 'visible' : 'hidden' }}
              >
                <IconAdd color={!isFull ? 'gray' : 'primary'} fontSize='large' />
              </IconButton>
            </Tooltip>
          ) : null}
          {true === true ? (
            <Tooltip title='Копировать выбранный параметр'>
              <IconButton
                aria-label='filter'
                onClick={e => doAddCopyEssence()}
                className={classes.margin}
                disabled={!isFull}
                style={{ visibility: isEdit === 0 ? 'visible' : 'hidden' }}
              >
                <IconLibraryAdd color={!isFull ? 'gray' : 'primary'} fontSize='large' />
              </IconButton>
            </Tooltip>
          ) : null}
          {isFinish === true ? (
            <Tooltip title='Сохранить'>
              <IconButton
                aria-label='filter'
                onClick={e => doSaveEssence()}
                disabled={!isFull}
                className={classes.margin}
              >
                <IconSave color={!isFull ? 'gray' : 'primary'} fontSize='large' />
              </IconButton>
            </Tooltip>
          ) : null}
          {true === true ? (
            <Tooltip title='Обновить дерево'>
              <IconButton
                aria-label='filter'
                onClick={e => doRefresh()}
                className={classes.margin}
                style={{ visibility: isEdit === 0 ? 'visible' : 'hidden' }}
              >
                <IconRefresh color='primary' fontSize='large' />
              </IconButton>
            </Tooltip>
          ) : null}
          {true === true ? (
            <Tooltip title='Удалить параметр'>
              <IconButton
                aria-label='filter'
                onClick={e => setIsOpenDialogDelete(true)}
                className={classes.margin}
                disabled={!isFull}
                style={{ visibility: isEdit === 0 ? 'visible' : 'hidden' }}
              >
                <IconDelete color={!isFull ? 'gray' : 'error'} fontSize='large' />
              </IconButton>
            </Tooltip>
          ) : null}
          {/* {true === true ? (
            <IconButton
              aria-label='filter'
              onClick={e => {
                let parameterByEssences = { clickChecked: false }
                localStorage.setItem(
                  'parameterByEssences',
                  JSON.stringify(parameterByEssences)
                )
                props.history.push('/essence')
                props.history.go('/essence')
              }}
              className={classes.margin}
            >
              <IconDelete color='primary' fontSize='large' />
            </IconButton>
          ) : null} */}
        </Grid>
      </Grid>

      <TableContainer className={[classes.flex, classes.end]}>
        <Grid
          container
          id={'body_grid'}
          className={[classes.flexRow, classes.end]}
        >
          <Grid
            item
            xs={3}
            id={'bodyTree_grid'}
            style={{ padding: 10 }}
            className={clsx(classes.flex, classes.end)}
          >
            <Paper
              elevation={3}
              id={'bodyTree_paper'}
              className={clsx(classes.flex, classes.end)}
              style={{ padding: 10 }}
            >
              <FFLoaderComponent
                // key={te}
                loading={essenceLoading}
                isLoader={<Loader />}
                component={<FFTreeEssence essence={essence} />}
              />
            </Paper>
          </Grid>
          <Grid
            item
            xs={9}
            id={'bodyMain_grid'}
            className={[classes.flex, classes.end]}
          >
            <FFLoaderComponent
              loading={activeEssence ? true : false}
              isLoader={
                <Paper
                  elevation={3}
                  style={{ margin: 8, padding: 8 }}
                  className={clsx(classes.flex, classes.end)}
                >
                  <Typography
                    variant='h1'
                    component='h2'
                    style={{ color: 'lightgray', marginTop: '20%' }}
                  >
                    Параметр не выбран
                  </Typography>
                </Paper>
              }
              component={
                <FF_Classifier_Card
                  key={
                    activeEssence
                      ? 'FF_Classifier_Card_' + activeEssence.id + '_' + te
                      : 'FF_Classifier_Card_temp'
                  }
                  isChecked={isChecked}
                  essence={activeEssence ? activeEssence : null}
                  children={children}
                  classifier={classifier}
                  metaEssence={metaEssence}
                />
              }
            />
          </Grid>
        </Grid>
      </TableContainer>
    </Context.Provider>
  )
}

const FF_Classifier_Card = props => {
  // -------------------------------------------------------------------------------
  //
  //                                   Стили
  //
  // -------------------------------------------------------------------------------

  const classes = useStyles()

  // -------------------------------------------------------------------------------
  //
  //                                 Состояния
  //
  // ---------------------------- ---------------------------------------------------

  const { changeTextField, finishSave } = useContext(Context)

  const [errorChange, setErrorChange] = useState('')

  let metaTable_step1 = [
    {
      widthColumn: 6,
      data: [
        {
          type: 'ffListTextFields',
          style: {},
          name_attr: ['name', 'label', 'label_eng'],
          // dis: true,
          label_attr: [
            'Системное наименование',
            'Наименование',
            'Наименование на латинском'
          ]
        }
      ]
    },
    {
      widthColumn: 6,
      data: [
        {
          type: 'ffListTextFields',
          style: {},
          name_attr: ['description'],
          // dis: false,
          label_attr: ['Описание']
        }
      ]
    },
    {
      widthColumn: 12,
      data: [
        {
          type: 'ffListToggle',
          type_children: 'chechbox',
          style: classes.cell_2,
          is_line: true,
          name_attr: props.isChecked,
          label_attr: props.metaEssence
        }
      ]
    }
  ]

  // -------------------------------------------------------------------------------
  //
  //                                 Обработчики событий
  //
  // -------------------------------------------------------------------------------

  useEffect(() => {}, [])

  const handleChangeFFTextFields = (value, parameter_id, name, element) => {
    if (name in props.metaEssence) {
      if (props.metaEssence[name]['is_required'] && value === '') {
        setErrorChange('Заполните все обязательные поля')
        return 'Обязательное поле не заполнено!'
      }
      setErrorChange('')
      changeTextField(value, parameter_id, name, element)
    }
    return ''
  }

  const setErrorStep = strError => {
    setErrorChange(strError)
  }

  const goBack = () => {
    setErrorChange('')
  }
  // -------------------------------------------------------------------------------
  //
  //                                    Рендеринг
  //
  // -------------------------------------------------------------------------------

  return props.essence ? (
    <Context.Provider
      value={{ handleChangeFFTextFields, finishSave, goBack, setErrorStep }}
    >
      <Paper
        elevation={3}
        style={{ margin: 8, padding: 8 }}
        // className={classes.end}
        className={[classes.tree, classes.scroller, classes.end]}
      >
        <FFSteps
          errorChange={errorChange}
          labelStep={[
            'Ввод метаданных параметра',
            'Ввод классификационных значений',
            'Ввод дополнительных сведений'
          ]}
          essence={props.essence}
          component={[
            <Grid container item xs={12} spacing={1}>
              {metaTable_step1.map(metaColumn => {
                // console.log(props.essence)
                return (
                  <Grid item xs={metaColumn.widthColumn}>
                    {metaColumn.data.map(metaCell => {
                      if (metaCell.type === 'ffListToggle') {
                        return (
                          <React.Fragment>
                            <Typography
                              style={{ marginTop: 10, marginLeft: 5 }}
                            >
                              Признаки:
                            </Typography>
                            <FFRouterComponents
                              style={{ margin: 10 }}
                              parameter={props.essence}
                              meta_cell={metaCell}
                            />
                          </React.Fragment>
                        )
                      } else {
                        return (
                          <FFRouterComponents
                            style={{ margin: 10 }}
                            parameter={props.essence}
                            meta_cell={metaCell}
                          />
                        )
                      }
                    })}
                  </Grid>
                )
              })}
            </Grid>,
            <FF_Classifier_step2
              essence={props.essence}
              isChecked={props.isChecked}
              classifier={props.classifier}
              metaEssence={props.metaEssence}
            />,
            <React.Fragment>
              <FF_Classifier_step3
                essence={props.essence}
                classifier={props.classifier}
              />
            </React.Fragment>
          ]}
        />
      </Paper>
    </Context.Provider>
  ) : null
}

const FF_Classifier_step2 = props => {
  // -------------------------------------------------------------------------------
  //
  //                                   Стили
  //
  // -------------------------------------------------------------------------------

  const classes = useStyles()

  // -------------------------------------------------------------------------------
  //
  //                                 Мета описание
  //
  // ---------------------------- ---------------------------------------------------

  // let metaMap = {
  //   isChecked: {
  //     is_phase: {
  //       'p-type-data': ['p-type-data-float'],
  //       'p-format-data': ['p-format-data-list'],
  //       'p-source-data': ['p-source-data-stream']
  //     },
  //     is_sum: {
  //       'p-type-data': [
  //         'p-type-data-int',
  //         'p-type-data-bool',
  //         'p-type-data-float',
  //         'p-type-data-deltatime'
  //       ]
  //     },
  //     is_control: {
  //       'p-source-data': ['p-source-data-stream']
  //     }
  //   },
  //   isSelected: {
  //     'p-type-data': {
  //       'p-type-data-string': {
  //         'p-format-data': [
  //           'p-format-data-value-constant',
  //           'p-format-data-list',
  //           'p-format-data-complex',
  //           'p-format-data-dict',
  //           'p-format-data-value-list'
  //         ]
  //       },
  //       'p-type-data-bool': {
  //         'p-format-data': [
  //           'p-format-data-value-constant',
  //           'p-format-data-complex',
  //           'p-format-data-dict',
  //           'p-format-data-value-list'
  //         ]
  //       }
  //     }
  //   }
  // }

  let metaMap = {
    isChecked: {
      is_phase: {
        '1923fd86-85e9-4f62-880e-00406a298c21': [
          '4f71609e-aefb-451c-9980-211bd8a75078'
        ],
        'ce0d110b-0931-44f5-b7e6-cac0693e4720': [
          '7f020101-220c-45e5-9b58-32ca78724f64'
        ],
        '39cd556b-253a-46bf-a407-004c9775217e': [
          'bda792e5-d607-4ff8-b719-c61309db1fd1'
        ]
      },
      is_sum: {
        '1923fd86-85e9-4f62-880e-00406a298c21': [
          '52529645-5971-4cdd-9482-ae8e2644d30d',
          '3eefd146-837a-4fb3-972d-6417ed2a085b',
          '4f71609e-aefb-451c-9980-211bd8a75078',
          'fbcc2ffd-c82b-4729-9c5c-7944be9d6442'
        ]
      },
      is_control: {
        '39cd556b-253a-46bf-a407-004c9775217e': [
          'bda792e5-d607-4ff8-b719-c61309db1fd1'
        ]
      }
    },
    isSelected: {
      '1923fd86-85e9-4f62-880e-00406a298c21': {
        '5bd9d4c4-1185-4bb3-a188-51adebfadfaa': {
          'ce0d110b-0931-44f5-b7e6-cac0693e4720': [
            'd01410b0-b4d9-4214-b358-408ea1c57d43',
            '7f020101-220c-45e5-9b58-32ca78724f64',
            'cfe527f6-769c-4c43-b666-65673ba99169',
            'aa31e079-c0db-4eba-81d5-d8a013b012bf',
            'bb209f0c-a5a2-4ed6-84b4-e9bd4783887b'
          ]
        },
        '3eefd146-837a-4fb3-972d-6417ed2a085b': {
          'ce0d110b-0931-44f5-b7e6-cac0693e4720': [
            'd01410b0-b4d9-4214-b358-408ea1c57d43',
            '7f020101-220c-45e5-9b58-32ca78724f64',
            'cfe527f6-769c-4c43-b666-65673ba99169',
            'aa31e079-c0db-4eba-81d5-d8a013b012bf'
          ]
        }
      }
    }
  }

  let metaTable_step2 = [
    {
      widthColumn: 6,
      data: [
        {
          type: 'ffListTextFields',
          style: {},
          name_attr: [
            'count_sum',
            'time_sum_sec',
            'interval_start',
            'interval_end'
          ],
          label_attr: [
            'Количество значений для суммирования',
            'Время суммирования, сек',
            'Начало интервала',
            'Конец интервала'
          ]
        },
        {
          type: 'ffListToggle',
          type_children: 'chechbox',
          is_line: true,
          style: {},
          name_attr: ['is_in', 'is_out'],
          label_attr: props.metaEssence
        }
      ]
    }
  ]

  // -------------------------------------------------------------------------------
  //
  //                                 Состояния
  //
  // -------------------------------------------------------------------------------

  const [currentMeta, setCurrentMeta] = useState({})
  const [baseClsChecked, setBaseClsChecked] = useState({})
  const [currentEssence, setCurrentEssence] = useState(props.essence)
  const [te, setTe] = useState(0)
  const { setErrorStep } = useContext(Context)
  // -------------------------------------------------------------------------------
  //
  //                                 Обработчики событий
  //
  // -------------------------------------------------------------------------------

  useEffect(() => {
    correctBaseClsByChecked_Selected()
  }, [])

  // Коррекция классификаторов взависимотсти от выбранных значений isSelected
  const correctBaseClsByChecked_Selected = () => {
    let tempActiveCls = currentEssence.active_system_classifier_values
    let tempMeta = {}
    props.classifier.map(obj => {
      tempMeta[obj.id] = JSON.parse(JSON.stringify(obj))
    })

    // Коррекция классификаторов взависимотсти от выбранных значений isChecked
    Object.keys(metaMap['isChecked']).map(nameChecked => {
      if (props.essence[nameChecked] === true) {
        Object.keys(metaMap['isChecked'][nameChecked]).map(nameCls => {
          tempMeta[nameCls].children = tempMeta[nameCls].children.filter(
            clsValue =>
              metaMap['isChecked'][nameChecked][nameCls].includes(clsValue.id)
          )
        })
      }
    })
    Object.keys(tempActiveCls).map(parentActiveCls => {
      if (parentActiveCls in metaMap['isSelected']) {
        if (
          tempActiveCls[parentActiveCls] in
          metaMap['isSelected'][parentActiveCls]
        ) {
          Object.keys(
            metaMap['isSelected'][parentActiveCls][
              tempActiveCls[parentActiveCls]
            ]
          ).map(metaParentCls => {
            tempMeta[metaParentCls].children = tempMeta[
              metaParentCls
            ].children.filter(clsValue =>
              metaMap['isSelected'][parentActiveCls][
                tempActiveCls[parentActiveCls]
              ][metaParentCls].includes(clsValue.id)
            )
          })
        }
      }
    })
    // console.log(tempMeta)
    setCurrentMeta(tempMeta)
    setTe(te + 1)
  }

  // Выбор классификационного значения
  const onChangeClassifier = (e, idCls) => {
    let tempEssence = currentEssence

    tempEssence.active_system_classifier_values[idCls] = e.target.value
    setCurrentEssence(tempEssence)
    correctBaseClsByChecked_Selected()
  }

  // -------------------------------------------------------------------------------
  //
  //                                    Рендеринг
  //
  // -------------------------------------------------------------------------------

  return (
    <Grid container item xs={12} spacing={1}>
      <Grid item xs={props.essence.is_model ? 6 : 12}>
        {Object.keys(currentMeta).map(idCls => {
          return (
            <FormControl
              variant='filled'
              style={{ margin: 3, width: '100%' }}
              key={'FF_Classifier_step2_formControl_' + idCls}
            >
              <InputLabel>{currentMeta[idCls].label}</InputLabel>
              <Select
                key={'FF_Classifier_step2_select_' + idCls}
                value={props.essence.active_system_classifier_values[idCls]}
                onChange={e => onChangeClassifier(e, idCls)}
              >
                {currentMeta[idCls].children.map(objChild => {
                  return (
                    <MenuItem
                      value={objChild.id}
                      id={objChild.id}
                      key={'FF_Classifier_step2_menuItem_' + objChild.id}
                    >
                      {objChild.label}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          )
        })}
      </Grid>
      {props.essence.is_model
        ? metaTable_step2.map(metaColumn => {
            // console.log(props.essence)
            return (
              <Grid item xs={metaColumn.widthColumn}>
                {metaColumn.data.map(metaCell => {
                  return (
                    <FFRouterComponents
                      style={{ margin: 10 }}
                      parameter={props.essence}
                      meta_cell={metaCell}
                    />
                  )
                })}
              </Grid>
            )
          })
        : null}
    </Grid>
  )
}

const FF_Classifier_step3 = props => {
  console.log('adssfdfsdfds', props)
  // -------------------------------------------------------------------------------
  //
  //                                   Стили
  //
  // -------------------------------------------------------------------------------

  const classes = useStyles()

  const { setErrorStep } = useContext(Context)

  // -------------------------------------------------------------------------------
  //
  //                                 Обработчики событий
  //
  // -------------------------------------------------------------------------------

  const pFormatDataValueList = (nameAttr, data, error) => {
    console.log(nameAttr, data, error)
    if (error || error === '') {
      setErrorStep(error)
    }
    let limits = props.essence.limits
    if (props.essence.limits === null) {
      props.essence.limits = {}
    }
    props.essence.limits[nameAttr] = data
  }

  const pFormatParent = (nameAttr, data, error) => {
    // console.log(nameAttr, data, error)
    if (error || error === '') {
      setErrorStep(error)
      if (error !== '') {
        return
      }
    }
    let limits = props.essence.limits
    let dat = JSON.parse(JSON.stringify(data))
    if (nameAttr === 'p-format-data-complex') {
      dat = JSON.parse(data.value)
    }
    if (props.essence.limits === null) {
      props.essence.limits = {}
    }

    if ('id' in dat) {
      delete dat['id']
    }
    props.essence.limits[nameAttr] = dat
  }

  const routerDataFormatSteper = () => {
    let temp = props.essence.active_system_classifier_values
    let limits = props.essence.limits
    let value = { value: null }
    let formatDataId = 'ce0d110b-0931-44f5-b7e6-cac0693e4720'

    let metaId = {
      'c61f705d-3f69-4624-b4fe-db5230352911': {
        nameAttr: 'p-format-data-value-range',
        component: (
          <FF_Classifier_step3_formatData_valueRange
            value={value}
            nameAttr={'p-format-data-value-range'}
          />
        )
      },
      '7f020101-220c-45e5-9b58-32ca78724f64': {
        nameAttr: 'p-format-data-list',
        component: (
          <FF_Classifier_step3_formatData_list
            value={value}
            classifier={
              props.classifier.filter(obj => obj.id === formatDataId)[0]
            }
            nameAttr={'p-format-data-list'}
          />
        )
      },
      'aa31e079-c0db-4eba-81d5-d8a013b012bf': {
        nameAttr: 'p-format-data-dict',
        component: (
          <FF_Classifier_step3_formatData_dict
            value={value}
            classifier={
              props.classifier.filter(obj => obj.id === formatDataId)[0]
            }
            nameAttr={'p-format-data-dict'}
          />
        )
      },
      'cfe527f6-769c-4c43-b666-65673ba99169': {
        nameAttr: 'p-format-data-complex',
        component: (
          <FF_Classifier_step3_formatData_complex
            value={value}
            nameAttr={'p-format-data-complex'}
          />
        )
      },
      'bb209f0c-a5a2-4ed6-84b4-e9bd4783887b': {
        nameAttr: 'p-format-data-value-list',
        component: (
          <FF_Classifier_step3_formatData_valueList
            value={value}
            nameAttr={'p-format-data-value-list'}
          />
        )
      },
      'd01410b0-b4d9-4214-b358-408ea1c57d43': {
        nameAttr: 'p-format-data-value-constant',
        component: () =>
          limits
            ? (limits['p-format-data-value-constant'] = '')
            : (limits = { 'p-format-data-value-constant': '' })
      }
    }
    if (formatDataId in temp) {
      if (temp[formatDataId] in metaId) {
        value.value =
          limits && metaId[temp[formatDataId]].nameAttr in limits
            ? JSON.parse(
                JSON.stringify(limits[metaId[temp[formatDataId]].nameAttr])
              )
            : null
        return metaId[temp[formatDataId]].component
      }
    } else return false
  }

  const routerSourceDataSteper = () => {
    let temp = props.essence.active_system_classifier_values

    let limits = props.essence.limits

    let sourceDataId = '39cd556b-253a-46bf-a407-004c9775217e'

    let metaId = {
      'd9496d11-2e86-43c3-95d6-30e6cbecb3f6': {
        labelAttr: 'Формула',
        nameAttr: 'formula-default'
      },
      '4a201562-80e2-43f6-ae20-f007cc47eabe': {
        labelAttr: 'База данных',
        nameAttr: 'db-default'
      },
      '65107bd1-ff31-4c7e-ab14-b1d09380018c': {
        labelAttr: 'url',
        nameAttr: 'url-default'
      }
    }

    if (sourceDataId in temp) {
      let resultComponent = Object.keys(metaId).map(valueId => {
        if (valueId === temp[sourceDataId]) {
          return (
            <FF_Classifier_step3_sourceData
              labelAttr={metaId[valueId].labelAttr}
              nameAttr={metaId[valueId].nameAttr}
              value={
                limits && metaId[valueId].nameAttr in limits
                  ? JSON.parse(JSON.stringify(limits[metaId[valueId].nameAttr]))
                  : null
              }
            />
          )
        }
      })
      if (resultComponent.length) {
        return resultComponent
      }
    } else return false
  }

  const routerTypeDataSteper = () => {
    let temp = props.essence.active_system_classifier_values

    let limits = props.essence.limits

    let value = { value: null }

    let typeDataId = '1923fd86-85e9-4f62-880e-00406a298c21'

    let metaId = {
      '3eefd146-837a-4fb3-972d-6417ed2a085b': {
        nameAttr: 'bool-value',
        component: (
          <FF_Classifier_step3_typeData_bool
            value={value}
            nameAttr={'bool-value'}
          />
        )
      }
    }
    if (typeDataId in temp) {
      if (temp[typeDataId] in metaId) {
        value.value =
          limits && metaId[temp[typeDataId]].nameAttr in limits
            ? JSON.parse(
                JSON.stringify(limits[metaId[temp[typeDataId]].nameAttr])
              )
            : null
        return metaId[temp[typeDataId]].component
      }
    } else return false
  }

  // -------------------------------------------------------------------------------
  //
  //                                 Мета описание
  //
  // ---------------------------- ---------------------------------------------------

  let metaMap = [
    {
      routerSteper: routerDataFormatSteper(),
      labelBlock: 'Формат данных'
    },
    {
      routerSteper: routerSourceDataSteper(),
      labelBlock: 'Источник данных'
    },
    {
      routerSteper: routerTypeDataSteper(),
      labelBlock: 'Тип данных'
    }
  ]

  // -------------------------------------------------------------------------------
  //
  //                                 Состояние
  //
  // ---------------------------- ---------------------------------------------------

  return (
    <Context.Provider value={{ pFormatDataValueList, pFormatParent }}>
      {metaMap.map(metaBlock => {
        return (
          <FFLoaderComponent
            loading={metaBlock.routerSteper}
            isLoader={null}
            component={
              <>
                <Typography gutterBottom variant='h6' component='h6'>
                  {metaBlock.labelBlock}
                </Typography>
                {metaBlock.routerSteper}
              </>
            }
          />
        )
      })}
    </Context.Provider>
  )
}

// const FF_Classifier_step3_formatData_valueList = props => {
//   // -------------------------------------------------------------------------------
//   //
//   //                                   Стили
//   //
//   // -------------------------------------------------------------------------------

//   const classes = useStyles()

//   // -------------------------------------------------------------------------------
//   //
//   //                                 Мета описание
//   //
//   // ---------------------------- ---------------------------------------------------

//   let metaTable = [
//     {
//       widthColumn: 6,
//       data: [
//         {
//           type: 'ffListTextFields',
//           style: {},
//           name_attr: ['key'],
//           label_attr: ['Идентификатор']
//         }
//       ]
//     },
//     {
//       widthColumn: 6,
//       data: [
//         {
//           type: 'ffListTextFields',
//           style: {},
//           name_attr: ['value'],
//           label_attr: ['Наименование']
//         }
//       ]
//     }
//   ]

//   // -------------------------------------------------------------------------------
//   //
//   //                                 Состояние
//   //
//   // ---------------------------- ---------------------------------------------------

//   const [valueList, setValueList] = useState([])
//   const [valueTemp, setValueTemp] = useState({})
//   const { pFormatDataValueList } = useContext(Context)

//   // -------------------------------------------------------------------------------
//   //
//   //                                 Обработчики событий
//   //
//   // -------------------------------------------------------------------------------

//   useEffect(() => {
//     let temp =
//       props.value && props.value.value && Array.isArray(props.value.value)
//         ? props.value.value
//         : []
//     temp.map(obj => {
//       obj.key = obj.id
//       obj.id = uuidv4()
//     })
//     setValueList(temp)
//     generateTempValue()
//   }, [])

//   const generateTempValue = () => {
//     setValueTemp({
//       id: uuidv4() + '_temp',
//       key: '',
//       value: ''
//     })
//   }

//   const handleChangeFFTextFields = (value, parameter_id, name, element) => {
//     if (element) {
//       let temp
//       if (parameter_id && parameter_id.indexOf('_temp') === -1) {
//         temp = valueList
//         temp.map(obj => {
//           if (obj.id === parameter_id) {
//             obj[name] = value
//           }
//         })
//         setValueList(temp)
//       } else {
//         let tempValueTemp = valueTemp
//         tempValueTemp[name] = value
//         tempValueTemp.id = uuidv4()
//         temp = [...valueList, tempValueTemp]
//         setValueList([...valueList, tempValueTemp])
//         generateTempValue()
//       }
//       pFormatDataValueList(
//         props.nameAttr,
//         temp.map(obj => {
//           return { id: obj.key, value: obj.value }
//         })
//       )
//     }
//     return ''
//   }

//   return (
//     <Context.Provider value={{ handleChangeFFTextFields }}>
//       <Grid container item xs={12} spacing={1}>
//         {valueList.map(obj => {
//           return metaTable.map(metaColumn => {
//             // console.log(obj)
//             return (
//               <Grid item xs={metaColumn.widthColumn}>
//                 {metaColumn.data.map(metaCell => {
//                   return (
//                     <FFRouterComponents
//                       style={{ margin: 10 }}
//                       parameter={obj}
//                       meta_cell={metaCell}
//                     />
//                   )
//                 })}
//               </Grid>
//             )
//           })
//         })}
//         {metaTable.map(metaColumn => {
//           return (
//             <Grid item xs={metaColumn.widthColumn}>
//               {metaColumn.data.map(metaCell => {
//                 return (
//                   <FFRouterComponents
//                     style={{ margin: 10 }}
//                     parameter={valueTemp}
//                     meta_cell={metaCell}
//                   />
//                 )
//               })}
//             </Grid>
//           )
//         })}
//       </Grid>
//     </Context.Provider>
//   )
// }

const FF_Classifier_step3_formatData_dict = props => {
  // -------------------------------------------------------------------------------
  //
  //                                   Стили
  //
  // -------------------------------------------------------------------------------

  const classes = useStyles()

  // -------------------------------------------------------------------------------
  //
  //                                 Мета описание
  //
  // ---------------------------- ---------------------------------------------------

  // -------------------------------------------------------------------------------
  //
  //                                 Состояние
  //
  // ---------------------------- ---------------------------------------------------

  const { pFormatParent } = useContext(Context)
  const [te, setTe] = useState(0)
  const [value, setValue] = useState({})

  // -------------------------------------------------------------------------------
  //
  //                                 Обработчики событий
  //
  // -------------------------------------------------------------------------------

  useEffect(() => {
    let temp = props.value && props.value.value ? props.value.value : null
    if (temp === null) {
      temp = {}
      temp.key = ''
      temp.value = null
      temp['p-format-data'] = ''
    }
    temp.id = uuidv4()
    setTe(te + 1)
    setValue(temp)
  }, [])

  const changeDefaultValue = event => {
    let temp = value
    temp['p-format-data'] = event.target.value
    // console.log(temp)
    setValue(temp)
    setTe(te + 1)
  }

  const handleChangeFFTextFields = (value2, parameter_id, name, element) => {
    if (element) {
      let temp = value
      if (name === 'key' && value2 === '') {
        pFormatParent('p-format-data-dict', value, 'Ключ для словаря не задан')
        return 'Введите ключ для словаря'
      }
      temp[name] = value2
      setValue(temp)
      pFormatParent('p-format-data-dict', value, '')
      // console.log(temp, name)
    }
    if (name === 'key' && value2 === '') {
      pFormatParent('p-format-data-dict', value, 'Ключ для словаря не задан')
      return 'Введите ключ для словаря'
    }
    return ''
  }

  const pFormatDataValueList = (nameAttr, data, error) => {
    let temp = value
    temp['value'] = data
    setValue(temp)
    pFormatParent('p-format-data-dict', value, error)
    // console.log(nameAttr, data)
  }

  const routerComponent = format => {
    if (format === 'p-format-data-value-list') {
      return (
        <React.Fragment>
          <FF_Classifier_step3_formatData_valueList
            value={JSON.parse(JSON.stringify(props.value.value))}
            nameAttr={'p-format-data-value-list'}
          />
        </React.Fragment>
      )
    } else if (format === 'p-format-data-value-range') {
      return (
        <React.Fragment>
          <FF_Classifier_step3_formatData_valueRange
            value={JSON.parse(JSON.stringify(props.value.value))}
            nameAttr={'p-format-data-value-range'}
          />
        </React.Fragment>
      )
    } else if (format === 'p-format-data-interval-range') {
      return (
        <React.Fragment>
          <FF_Classifier_step3_formatData_intervalRange
            value={JSON.parse(JSON.stringify(props.value.value))}
            nameAttr={'p-format-data-interval-range'}
          />
        </React.Fragment>
      )
    }
    return null
  }

  return value ? (
    <Context.Provider
      value={{ handleChangeFFTextFields, pFormatDataValueList }}
    >
      <React.Fragment>
        <Grid container item xs={12} spacing={1}>
          <Grid item xs={6} spacing={1}>
            {/* {console.log(value['length'])} */}
            <FFTextFields
              style={{ margin: 4, width: '100%' }}
              key={'FFTextFields_key' + te}
              value={value['key']}
              is_filter={false}
              name={'key'}
              label={'Ключ'}
              parameter={value}
              dis={false}
            />
          </Grid>
          <Grid item xs={6} spacing={1}>
            <FormControl variant='filled' style={{ margin: 3, width: '100%' }}>
              <InputLabel id='FFParametersNew_InputLabel_select_formatList'>
                Формат элементов списка
              </InputLabel>
              {console.log(props.classifier.children)}
              <Select
                key={'select' + value['p-format-data']}
                value={value['p-format-data']}
                onChange={changeDefaultValue}
              >
                {props.classifier.children.map(objChild => {
                  if (objChild.label.indexOf('Значение (') > -1) {
                    return (
                      <MenuItem value={objChild.name}>
                        {objChild.label}
                      </MenuItem>
                    )
                  }
                })}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <React.Fragment key={value['p-format-data']}>
          {routerComponent(value['p-format-data'])}
        </React.Fragment>
      </React.Fragment>
    </Context.Provider>
  ) : null
}

const FF_Classifier_step3_formatData_complex = props => {
  // -------------------------------------------------------------------------------
  //
  //                                   Стили
  //
  // -------------------------------------------------------------------------------

  const classes = useStyles()

  // -------------------------------------------------------------------------------
  //
  //                                 Мета описание
  //
  // ---------------------------- ---------------------------------------------------

  // -------------------------------------------------------------------------------
  //
  //                                 Состояние
  //
  // ---------------------------- ---------------------------------------------------

  const { pFormatParent } = useContext(Context)
  const [te, setTe] = useState(0)
  const [value, setValue] = useState({})

  // -------------------------------------------------------------------------------
  //
  //                                 Обработчики событий
  //
  // -------------------------------------------------------------------------------

  useEffect(() => {
    let temp = {}
    temp.value =
      props.value && props.value.value ? JSON.stringify(props.value.value) : ''
    temp.id = uuidv4()
    setTe(te + 1)
    setValue(temp)
  }, [])

  const handleChangeFFTextFields = (value2, parameter_id, name, element) => {
    if (element) {
      let temp = value
      try {
        let a = JSON.parse(value2)
      } catch (e) {
        // console.log(e)
        pFormatParent('p-format-data-complex', temp, 'Ошибка в JSON')
        return 'Ошибка в JSON'
      }
      temp[name] = value2
      setValue(temp)
      pFormatParent('p-format-data-complex', temp, '')
    }
    return ''
  }

  return value ? (
    <Context.Provider value={{ handleChangeFFTextFields }}>
      <React.Fragment>
        <Grid container item xs={12} spacing={1}>
          <Grid item xs={12} spacing={1}>
            <FFTextFields
              style={{ margin: 4, width: '100%' }}
              key={'FFTextFields_length' + te}
              value={value.value}
              is_filter={false}
              name={'value'}
              label={'Составной объект'}
              parameter={value}
              dis={false}
            />
          </Grid>
        </Grid>
      </React.Fragment>
    </Context.Provider>
  ) : null
}

const FF_Classifier_step3_formatData_list = props => {
  // -------------------------------------------------------------------------------
  //
  //                                   Стили
  //
  // -------------------------------------------------------------------------------

  const classes = useStyles()

  // -------------------------------------------------------------------------------
  //
  //                                 Мета описание
  //
  // ---------------------------- ---------------------------------------------------

  // -------------------------------------------------------------------------------
  //
  //                                 Состояние
  //
  // ---------------------------- ---------------------------------------------------

  const { pFormatParent } = useContext(Context)
  const [te, setTe] = useState(0)
  const [value, setValue] = useState({})

  // -------------------------------------------------------------------------------
  //
  //                                 Обработчики событий
  //
  // -------------------------------------------------------------------------------

  useEffect(() => {
    let temp = props.value && props.value.value ? props.value.value : null
    if (temp === null) {
      temp = {}
      temp.length = ''
      temp.value = null
      temp['p-format-data'] = ''
    }
    temp.id = uuidv4()
    setTe(te + 1)
    setValue(temp)
  }, [])

  const changeDefaultValue = event => {
    let temp = value
    temp['p-format-data'] = event.target.value
    console.log(temp, 'dddddd')
    setValue(temp)
    pFormatParent('p-format-data-list', temp)
    setTe(te + 1)
  }

  const handleChangeFFTextFields = (value2, parameter_id, name, element) => {
    if (element) {
      let temp = value
      if (isNaN(Number(value2)) && name === 'length') {
        pFormatParent('p-format-data-list', value, 'Введите число')
        return 'Введите число'
      }
      temp[name] = value2
      setValue(temp)
      pFormatParent('p-format-data-list', value, '')
      // console.log(temp)
    }
    return ''
  }

  const pFormatDataValueList = (nameAttr, data) => {
    let temp = value
    temp['value'] = data
    setValue(temp)
    pFormatParent('p-format-data-list', value)
    // console.log(nameAttr, data)
  }

  const routerComponent = format => {
    if (format === 'p-format-data-value-list') {
      return (
        <React.Fragment>
          <FF_Classifier_step3_formatData_valueList
            value={JSON.parse(JSON.stringify(props.value.value))}
            nameAttr={'p-format-data-value-list'}
          />
        </React.Fragment>
      )
    } else if (format === 'p-format-data-value-range') {
      return (
        <React.Fragment>
          <FF_Classifier_step3_formatData_valueRange
            value={JSON.parse(JSON.stringify(props.value.value))}
            nameAttr={'p-format-data-value-range'}
          />
        </React.Fragment>
      )
    } else if (format === 'p-format-data-interval-range') {
      return (
        <React.Fragment>
          <FF_Classifier_step3_formatData_intervalRange
            value={JSON.parse(JSON.stringify(props.value.value))}
            nameAttr={'p-format-data-interval-range'}
          />
        </React.Fragment>
      )
    }
    return null
  }

  return value ? (
    <Context.Provider
      value={{ handleChangeFFTextFields, pFormatDataValueList }}
    >
      <React.Fragment>
        <Grid container item xs={12} spacing={1}>
          <Grid item xs={6} spacing={1}>
            <FFTextFields
              style={{ margin: 4, width: '100%' }}
              key={'FFTextFields_length' + te}
              value={value['length']}
              is_filter={false}
              name={'length'}
              label={'Количество элементов'}
              parameter={value}
              dis={false}
            />
          </Grid>
          <Grid item xs={6} spacing={1}>
            <FormControl variant='filled' style={{ margin: 3, width: '100%' }}>
              <InputLabel id='FFParametersNew_InputLabel_select_formatList'>
                Формат элементов списка
              </InputLabel>
              <Select
                key={'select' + value['p-format-data']}
                value={value['p-format-data']}
                onChange={changeDefaultValue}
              >
                {props.classifier.children.map(objChild => {
                  if (objChild.label.indexOf('Значение (') > -1) {
                    return (
                      <MenuItem value={objChild.name}>
                        {objChild.label}
                      </MenuItem>
                    )
                  }
                })}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <React.Fragment key={value['p-format-data']}>
          {routerComponent(value['p-format-data'])}
        </React.Fragment>
      </React.Fragment>
    </Context.Provider>
  ) : null
}

// const FF_Classifier_step3_formatData_valueRange = props => {
//   // -------------------------------------------------------------------------------
//   //
//   //                                   Стили
//   //
//   // -------------------------------------------------------------------------------

//   const classes = useStyles()

//   // -------------------------------------------------------------------------------
//   //
//   //                                 Мета описание
//   //
//   // ---------------------------- ---------------------------------------------------

//   let metaTable = [
//     {
//       widthColumn: 6,
//       data: [
//         {
//           type: 'ffListTextFields',
//           style: {},
//           name_attr: ['key', 'label', 'value'],
//           label_attr: ['Идентификатор', 'Наименование', 'Значение']
//         }
//       ]
//     }
//   ]

//   const labelSide = { left: 'Ограничение слева', right: 'Ограничение справа' }
//   // -------------------------------------------------------------------------------
//   //
//   //                                 Состояние
//   //
//   // ---------------------------- ---------------------------------------------------

//   const { pFormatDataValueList } = useContext(Context)
//   const [valueRange, setValueRange] = useState({})

//   // -------------------------------------------------------------------------------
//   //
//   //                                 Обработчики событий
//   //
//   // -------------------------------------------------------------------------------

//   useEffect(() => {
//     let temp = {
//       id: uuidv4(),
//       step: 1,
//       left: { id: uuidv4(), value: '', label: '', key: '' },
//       right: { id: uuidv4(), value: '', label: '', key: '' }
//     }
//     if (
//       props.value &&
//       props.value.value &&
//       'left' in props.value.value &&
//       'right' in props.value.value
//     ) {
//       temp.step = props.value.value.step
//       temp.left.key = props.value.value.left.id
//       temp.right.key = props.value.value.right.id
//       temp.left.label = props.value.value.left.label
//       temp.right.label = props.value.value.right.label
//       temp.left.value = props.value.value.left.value
//       temp.right.value = props.value.value.right.value
//     }
//     setValueRange(temp)
//   }, [])

//   const handleChangeFFTextFields = (value, parameter_id, name, element) => {
//     if (element) {
//       let temp = valueRange
//       if (
//         isNaN(Number(value)) &&
//         (name === 'value' || name === 'step') &&
//         (temp.id === parameter_id ||
//           temp.left.id === parameter_id ||
//           temp.right.id === parameter_id)
//       ) {
//         pFormatDataValueList(
//           props.nameAttr,
//           {
//             step: temp.step,
//             left: {
//               id: temp.left.key,
//               label: temp.left.label,
//               value: temp.left.value
//             },
//             right: {
//               id: temp.right.key,
//               label: temp.right.label,
//               value: temp.right.value
//             }
//           },
//           'Введите число'
//         )
//         return 'Введите число'
//       }
//       if (temp.id === parameter_id) {
//         temp[name] = value
//       } else if (temp.left.id === parameter_id) {
//         temp.left[name] = value
//       } else if (temp.right.id === parameter_id) {
//         temp.right[name] = value
//       }
//       pFormatDataValueList(
//         props.nameAttr,
//         {
//           step: temp.step,
//           left: {
//             id: temp.left.key,
//             label: temp.left.label,
//             value: temp.left.value
//           },
//           right: {
//             id: temp.right.key,
//             label: temp.right.label,
//             value: temp.right.value
//           }
//         },
//         ''
//       )
//     }
//     return ''
//   }

//   return (
//     <Context.Provider value={{ handleChangeFFTextFields }}>
//       {Object.keys(valueRange).length ? (
//         <Grid container item xs={12} spacing={1}>
//           <Grid item xs={12} spacing={1}>
//             <FFRouterComponents
//               style={{ margin: 10 }}
//               parameter={valueRange}
//               meta_cell={{
//                 type: 'ffListTextFields',
//                 style: {},
//                 name_attr: ['step'],
//                 label_attr: ['Шаг']
//               }}
//             />
//           </Grid>
//           {['left', 'right'].map(nameSide => {
//             return metaTable.map(metaColumn => {
//               // console.log(valueRange)
//               return (
//                 <Grid item xs={metaColumn.widthColumn}>
//                   <Typography>{labelSide[nameSide]}</Typography>
//                   {metaColumn.data.map(metaCell => {
//                     return (
//                       <FFRouterComponents
//                         style={{ margin: 10 }}
//                         parameter={valueRange[nameSide]}
//                         meta_cell={metaCell}
//                       />
//                     )
//                   })}
//                 </Grid>
//               )
//             })
//           })}
//         </Grid>
//       ) : null}
//     </Context.Provider>
//   )
// }

const FF_Classifier_step3_sourceData = props => {
  // -------------------------------------------------------------------------------
  //
  //                                   Стили
  //
  // -------------------------------------------------------------------------------

  const classes = useStyles()

  // -------------------------------------------------------------------------------
  //
  //                                 Мета описание
  //
  // ---------------------------- ---------------------------------------------------
  let metaTable = [
    {
      widthColumn: 12,
      data: [
        {
          type: 'ffListTextFields',
          style: {},
          name_attr: ['value'],
          label_attr: [props.labelAttr]
        }
      ]
    }
  ]
  // -------------------------------------------------------------------------------
  //
  //                                 Состояние
  //
  // ---------------------------- ---------------------------------------------------

  const { pFormatDataValueList } = useContext(Context)
  const [valueSourceData, setValueSourceData] = useState({})
  const [loading, setLoading] = useState(false)

  // -------------------------------------------------------------------------------
  //
  //                                 Обработчики событий
  //
  // -------------------------------------------------------------------------------

  useEffect(() => {
    // console.log(props)
    let temp = {
      id: uuidv4(),
      value: ''
    }
    if (props.value) {
      temp.value = props.value
    }
    setLoading(true)
    setValueSourceData(temp)
  }, [])

  const handleChangeFFTextFields = (value, parameter_id, name, element) => {
    if (element) {
      // console.log(props.labelAttr, value, parameter_id, name, element)
      let temp = valueSourceData
      temp[name] = value
      pFormatDataValueList(props.nameAttr, value)
      setValueSourceData(temp)
    }
    return ''
  }

  return (
    <Context.Provider value={{ handleChangeFFTextFields }}>
      <Grid container item xs={12} spacing={1}>
        <FFLoaderComponent
          loading={loading}
          isLoader={<Loader />}
          component={
            <FFRouterComponents
              style={{ margin: 10 }}
              parameter={valueSourceData}
              meta_cell={metaTable[0].data[0]}
            />
          }
        />
      </Grid>
    </Context.Provider>
  )
}

const FF_Classifier_step3_typeData_bool = props => {
  // -------------------------------------------------------------------------------
  //
  //                                   Стили
  //
  // -------------------------------------------------------------------------------

  const classes = useStyles()

  // -------------------------------------------------------------------------------
  //
  //                                 Мета описание
  //
  // ---------------------------- ---------------------------------------------------

  let metaTable_step1 = [
    // {
    //   widthColumn: 6,
    //   data: [
    //     {
    //       type: 'ffListTextFields',
    //       style: {},
    //       name_attr: ['label-true', 'label-false'],
    //       // dis: true,
    //       label_attr: [
    //         'Значение True 1',
    //         'Значание False 0',
    //       ]
    //     }
    //   ]
    // },
    {
      widthColumn: 6,
      data: [
        {
          type: 'ffListTextFields',
          style: {},
          name_attr: ['label-true', 'value-true'],
          // dis: false,
          label_attr: ['Наименование True', 'Значение True']
        }
      ]
    },
    {
      widthColumn: 6,
      data: [
        {
          type: 'ffListTextFields',
          style: {},
          name_attr: ['label-false', 'value-false'],
          // dis: false,
          label_attr: ['Наименование False', 'Значение False']
        }
      ]
    }
  ]

  // -------------------------------------------------------------------------------
  //
  //                                 Состояние
  //
  // ---------------------------- ---------------------------------------------------

  const { pFormatDataValueList } = useContext(Context)
  const [valueBoolData, setValueBoolData] = useState({})
  const [loading, setLoading] = useState(false)

  // -------------------------------------------------------------------------------
  //
  //                                 Обработчики событий
  //
  // -------------------------------------------------------------------------------

  useEffect(() => {
    // console.log(props)
    let temp = {
      id: uuidv4(),
      'value-true': '',
      'label-true': '',
      'value-false': '',
      'label-false': ''
    }
    if (props.value && props.value.value) {
      temp['label-true'] = props.value.value['label-true']
      temp['label-false'] = props.value.value['label-false']
      temp['value-true'] = props.value.value['value-true']
      temp['value-false'] = props.value.value['value-false']
    }
    setLoading(true)
    setValueBoolData(temp)
  }, [])

  const handleChangeFFTextFields = (value, parameter_id, name, element) => {
    if (element) {
      let temp = valueBoolData
      temp[name] = value
      pFormatDataValueList(props.nameAttr, {
        'value-true': temp['value-true'],
        'value-false': temp['value-false'],
        'label-true': temp['label-true'],
        'label-false': temp['label-false']
      })
      setValueBoolData(temp)
    }
    return ''
  }

  return (
    <Context.Provider value={{ handleChangeFFTextFields }}>
      <Grid container item xs={12} spacing={1}>
        <FFLoaderComponent
          loading={loading}
          isLoader={<Loader />}
          component={
            <Grid container item xs={12} spacing={1}>
              {metaTable_step1.map(metaColumn => {
                // console.log(props.essence)
                return (
                  <Grid item xs={metaColumn.widthColumn}>
                    {metaColumn.data.map(metaCell => {
                      return (
                        <FFRouterComponents
                          style={{ margin: 10 }}
                          parameter={valueBoolData}
                          meta_cell={metaCell}
                        />
                      )
                    })}
                  </Grid>
                )
              })}
            </Grid>
          }
        />
      </Grid>
    </Context.Provider>
  )
}
