import React from "react";
import { Typography, Select } from "antd";
import { useSelector } from "react-redux";
import { telemetryClassifiersSelector } from "../../slice";

export const Telemetry = ({ telemetryLayers = [], handleChangeTelemetryLayers }) => {
  const { data: classifiers, status } = useSelector(
    telemetryClassifiersSelector
  );

  const handleChange = (values) => {
    handleChangeTelemetryLayers(values);
  };

  return (
    <>
      <Typography.Paragraph strong>Слои телеметрии:</Typography.Paragraph>
      <Select
        mode="multiple"
        allowClear
        value={telemetryLayers}
        style={{ width: "100%" }}
        placeholder="Выберите слои телеметрии"
        onChange={handleChange}
        options={Array.isArray(classifiers) ? classifiers.filter((cls) => cls) : []}
        filterOption={(input, option) =>
          ((option?.label).toLowerCase() ?? "").includes(input.toLowerCase())
        }
        loading={status === "pending"}
        fieldNames={{
          label: "label",
          value: "id",
        }}
      />
    </>
  );
};
