import TreeNodeUtils from "tree-node-utils";

export const treeUtils = new TreeNodeUtils({
  childrenField: "children",
  keyField: "id",
});

export const treeStatistics = (entities) => {
  const statEntity = entities.map(({ id }) => id);
  const statClient = {};
  entities.forEach(({ clientId }) => {
    if (!statClient[clientId]) {
      statClient[clientId] = 0;
    }

    statClient[clientId] += 1;
  });

  return { statEntity, statClient };
};
