import React from "react";
import { makeStyles } from "@material-ui/core";
import { ScadaCreateEssence } from "../../features/scada";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    display: "flex",
    overflow: "hidden",
    flexDirection: "column",
    padding: 10,
    gap: 10,
    margin: theme.spacing(1),
  },
}));

export const CreateEssencePage = () => {
  const styles = useStyles();

  return <ScadaCreateEssence />;
};
