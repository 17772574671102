import React, {
  useEffect,
  useState,
  useContext,
  useLayoutEffect,
  useRef
} from 'react'
// import ReactDOM from 'react-dom'
// import { Redirect } from 'react-router-dom'
import clsx from 'clsx'

// import { makeStyles } from "@material-ui/core/styles";
// import React from 'react';
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
// import Loader from './Loader'
import Context from './context'
// import FFTreeEssence from './App2'
import FFSelectTree from './components/FFSelectTree'
import TreeNodeUtils from 'tree-node-utils'
import FFLoaderComponent from './components/FFLoaderComponent'

import FFTransportList from './components/FFTransportList'
import Divider from '@material-ui/core/Divider'
import Tooltip from '@material-ui/core/Tooltip'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
// import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Select from '@material-ui/core/Select'
import { Switch, Checkbox, Slider } from '@material-ui/core'

// import Input from '@material-ui/core/Input'

// import Table from '@material-ui/core/Table'
// import TableBody from '@material-ui/core/TableBody'
// import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
// import TableHead from '@material-ui/core/TableHead'
// import TableRow from '@material-ui/core/TableRow'
import Badge from '@material-ui/core/Badge'
// import Avatar from '@material-ui/core/Avatar'
// import Chip from '@material-ui/core/Chip'
// import Divider from '@material-ui/core/Divider'
// import TablePagination from '@material-ui/core/TablePagination'
// import FFPaginationAction from './components/FFPaginationAction'
import { SnackbarProvider, useSnackbar } from 'notistack'
// import FFListSwitch from './components/FFListSwitch';
// import FFSelect from './components/FFSelect'
// import FFTextFields from './components/FFTextFields'
// import FFSelectMulti from './components/FFSelectMulti'
import FFRouterComponents from './components/FFRouterComponents'
// import FFTextFields from './components/FFTextFields'
// import FFSelect from './components/FFSelect'

// import FFCheckList from './components/FFCheckList'
// import InputAdornment from '@material-ui/core/InputAdornment'
// import FFPanelMenu from './components/FFPanelMenu'
// import FFPersistentDrawerRight from './components/FFPersistentDrawerRight'
import Grid from '@material-ui/core/Grid'
// import TextField from '@material-ui/core/TextField'
// import MoreVertIcon from '@material-ui/icons/MoreVert'
import Drawer from '@material-ui/core/Drawer'
import FFTreeEssence from './components/FFTreeEssence'
// import Button from '@material-ui/core/Button';
// import List from '@material-ui/core/List'
import Card from '@material-ui/core/Card'
// import FFTester from './FFTester'
// import Divider from '@material-ui/core/Divider';
// import ListItem from '@material-ui/core/ListItem';
// import ListItemIcon from '@material-ui/core/ListItemIcon';
// import ListItemText from '@material-ui/core/ListItemText';
// import InboxIcon from '@material-ui/icons/MoveToInbox';
import MenuIcon from '@material-ui/icons/Menu'
import IconButton from '@material-ui/core/IconButton'

import IconSettings from '@material-ui/icons/Settings'

import IconDelete from '@material-ui/icons/Delete'
// import IconFilter from '@material-ui/icons/FilterList'
// import IconSearch from '@material-ui/icons/Search'
import IconLibraryAdd from '@material-ui/icons/LibraryAdd'
import IconAdd from '@material-ui/icons/Add'
import IconSave from '@material-ui/icons/Save'
import IconRefresh from '@material-ui/icons/Refresh'
import IconSystemUpdateAlt from '@material-ui/icons/SystemUpdateAlt'
// import IconBuild from '@material-ui/icons/Build'
// import IconReplay from '@material-ui/icons/Replay'
// import IconCancel from '@material-ui/icons/Cancel'
// import IconUpdate from '@material-ui/icons/Update'

// import FFFilterLabel from './components/FFFilterLabel'
// import FFToggle from './components/FFToggle'

// import CardHeader from '@material-ui/core/CardHeader'
// import TreeItem from '@material-ui/lab/TreeItem'
// import TreeView from '@material-ui/lab/TreeView'
// import Essence from './essence_action_info.json'
// import CancelIcon from '@material-ui/icons/Cancel';
// import Context from './context'
// import FileCls from './cls.json'
// import FileParameter from './parameter.json'
import { Button, GridListTile, ListSubheader } from '@material-ui/core'
// import SwipeableViews from 'react-swipeable-views';
// import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
// import ArrowRightIcon from '@material-ui/icons/ArrowRight'
// import Remove from '@material-ui/icons/Remove'
// import FileBaseLine from './fileSrc/scenarios_model.json'

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Slide from '@material-ui/core/Slide'

import { withStyles } from '@material-ui/core/styles'

import { green } from '@material-ui/core/colors'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import CheckCircle from '@material-ui/icons/CheckCircle'
import DoneOutline from '@material-ui/icons/DoneOutline'
// import FFPanelMenu from "./components/FFPanelMenu";

// import SortableTree from 'react-sortable-tree/dist/index.esm.js'
// import 'react-sortable-tree/style.css'
import '../../node_modules/react-grid-layout/css/styles.css'
import '../../node_modules/react-resizable/css/styles.css'

import CardHeader from '@material-ui/core/CardHeader'
import CardMedia from '@material-ui/core/CardMedia'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import Collapse from '@material-ui/core/Collapse'
import Avatar from '@material-ui/core/Avatar'
import { red } from '@material-ui/core/colors'
import FavoriteIcon from '@material-ui/icons/Favorite'
import ShareIcon from '@material-ui/icons/Share'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'

import MoreVertIcon from '@material-ui/icons/MoreVert'
import cookie from 'react-cookies'
import Loader from './Loader'
import FFSwitch_N from './components/FFSwitch_N'
import FFToggle from './components/FFToggleSimple'
import FFSlider from './components/FFSliderWButton'

import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import Alert from '@material-ui/lab/Alert'

import FFTextFields from './components/FFTextFields'
import FFSelect from './components/FFSelectSimple'

import { useTheme } from '@material-ui/core/styles'

import { validation_data } from './supportFunction'
import grey from '@material-ui/core/colors/grey'
import FF_Chart from './components/FF_Charts'

import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import highchartsMore from 'highcharts/highcharts-more.js'
import ExportingModule from 'highcharts/modules/exporting'
import {v4 as uuidv4} from 'uuid'

import FFDateRange from './components/FFDateRange'
import { ApiConnect, urlConfiguration } from './ApiConnectNew'

highchartsMore(Highcharts)
ExportingModule(Highcharts)


var ReactGridLayout = require('react-grid-layout')

const useTreeItemStyles = makeStyles(theme => ({
  panel: {
    width: '100%',
    overflow: 'overlay',
    height: '100%',
    borderRadius: 15,
    background: theme.palette.grey[50],
    borderLeft: '2px solid ' + theme.palette.grey[500]
  },
  activePanel: {
    borderLeftColor: theme.palette.primary.main
  },
  panelHeader: {
    borderBottom: '2px solid ' + theme.palette.grey[500],
    background: theme.palette.primary.light,
    height: 40
  },
  grid: {
    width: '100%',
    overflow: 'overlay',
    height: '100%',
    borderRadius: 15,
    background: theme.palette.grey[50],
    border: '1px dashed ' + theme.palette.grey[500]
  },
  ffTypography: {
    borderBottom: '1px solid' + theme.palette.grey[600],
    color: theme.palette.grey[700],
    // color: theme.palette.primary.main,
    background: theme.palette.grey[300],
    borderRadius: '4px 4px 0px 0px',
    padding: 4
  },
  flexEnd: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    flexFlow: 3
  },
  actionButton: {
    width: '-webkit-fill-available',
    margin: 4,
    // color: defTheme.palette.success.main,
    marginTop: 6
  }
}))

// const useStyles = makeStyles({
//   margin: {
//     marginTop: '10px',
//     marginLeft: '10px',
//     marginBottom: '5px'
//   },
//   padding: {
//     // paddingLeft: 20,
//     paddingRight: 10
//   },
//   textField: {
//     marginLeft: 1,
//     marginRight: 1,
//     width: '100%'
//   },
//   formControl: {
//     // maxWidth: '100%',
//     width: '250px',
//     margin: '10px'
//   },
//   formControl2: {
//     minWidth: 120,
//     maxWidth: 300
//   },
//   chips: {
//     display: 'flex',
//     flexWrap: 'wrap'
//   },
//   chip: {
//     margin: 2
//   },
//   flex: {
//     display: 'flex',
//     flexDirection: 'column',
//     height: '100%'
//   },
//   flexRow: {
//     display: 'flex',
//     flexDirection: 'row',
//     height: '100%'
//   },
//   end: {
//     flexGrow: 3
//     // margin: 10
//   },
//   tree: {
//     padding: 8,
//     margin: 8
//   },
//   flexEnd: {
//     display: 'flex',
//     flexDirection: 'column',
//     height: '100%',
//     flexFlow: 3
//   },

// })


function useWindowSize () {
  const [size, setSize] = useState([0, 0])
  useLayoutEffect(() => {
    function updateSize () {
      setSize([window.innerWidth, window.innerHeight])
    }
    window.addEventListener('resize', updateSize)
    updateSize()
    return () => window.removeEventListener('resize', updateSize)
  }, [])
  return size
}

const FF_Exp = props => {
  const theme = useTreeItemStyles()

  // const defTheme = useTheme()
  // const classes = useStyles()

  const { addComponent } = useContext(Context)

  const [active, setActive] = useState(props.active)
  //   const [cu]

  const [meta, setMeta] = useState(props.meta)

  const width = props.width
  const [te, setTe] = useState(1)
  const [helpMeta, setHelpMeta] = useState({})
  const [chart2, setChart2] = useState(0)

  const config = {
    childrenField: 'children',
    keyField: 'id'
  }

  const treeUtils = new TreeNodeUtils(config)

  useEffect(() => {
    let temp = []
    meta.map(currentHelpMeta => {
      let tempPos = currentHelpMeta.position
      tempPos['i'] = currentHelpMeta.id
      tempPos['h'] = true ? JSON.parse(JSON.stringify(tempPos['h'])) : 1
      tempPos['minH'] = 2
      if (currentHelpMeta.id === 'root') {
        tempPos['static'] = true
      }
      temp = [...temp, JSON.parse(JSON.stringify(tempPos))]
    })
    setHelpMeta(temp)
  }, [])

  const refresher = state => {
    let temp = meta
    state.map(obj => {
      temp.map(objTemp => {
        if (objTemp.id === obj.i) {
          objTemp.position = JSON.parse(JSON.stringify(obj))
        }
      })
    })
    // props.getBack(temp, props.parent)
  }

  const handleChangeFFTextFields = (value, id, name, element) => {
    // ла.log(meta, value, id, name)
    treeUtils.getNodeByKey(meta, id)['value'] = value
    return validation_data(name, value)
    // return ''
  }

  const routerAtomChildren = (atomMeta, currentWidth, currentHeight) => {
    if (atomMeta.type === 'ffTextFields') {
      return (
        <FFTextFields
          key={'FFTextFields_' + atomMeta.id}
          value={atomMeta.value}
          is_filter={false}
          name={atomMeta.typeData}
          label={atomMeta.label}
          parameter={atomMeta}
          dis={false}
        />
      )
    } else if (atomMeta.type === 'ffTypography') {
      return (
        <Grid
          container
          item
          xs={12}
          key={atomMeta.id}
          style={{ margin: 4 }}
          className={theme.ffTypography}
        >
          <Typography>{atomMeta.label + ':'}</Typography>
          {atomMeta.source === 'p-source-data-stream' &&
          atomMeta.value === null ? (
            <Loader size={'small'} />
          ) : (
            <Typography style={{ marginLeft: 2, color: 'black' }}>
              {JSON.stringify(atomMeta.value)}
            </Typography>
          )}
        </Grid>
      )
    } else if (atomMeta.type === 'ffSwitch') {
      return (
        <Grid container item xs={12}>
          <Grid item xs={12}>
            <Typography style={{ textAlign: 'center' }}>
              {atomMeta.label}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <FFSwitch_N
              id={atomMeta.id}
              meta={{
                // label: atomMeta.labelValue,
                label: ['False', 'True'],
                // width: parseInt((currentWidth - 4) / atomMeta.label.length),
                width: parseInt((currentWidth - 4) / 2),
                value: atomMeta.value
              }}
            />
          </Grid>
        </Grid>
      )
    } else if (atomMeta.type === 'ffToggle') {
      return (
        <FFToggle
          id={atomMeta.id}
          name={'toggle'}
          value={atomMeta.value}
          label={atomMeta.label}
        />
      )
    } else if (atomMeta.type === 'ffSelect') {
      return (
        <FFSelect
          id={atomMeta.id}
          value={atomMeta.value}
          options={atomMeta.options}
          label={atomMeta.label}
        />
      )
    } else if (atomMeta.type === 'ffRangeOne') {
      return (
        <FFSlider
          id={atomMeta.id}
          value={atomMeta.value}
          options={atomMeta.options}
          label={atomMeta.label}
          type={'One'}
        />
      )
    } else if (atomMeta.type === 'ffRangeTwo') {
      return (
        <FFSlider
          id={atomMeta.id}
          value={atomMeta.value}
          options={atomMeta.options}
          label={atomMeta.label}
          type={'Two'}
        />
      )
    } else if (atomMeta.type === 'ffChart') {
      // const type = { 0: '', 1: 'bar', 2: 'column', 3: 'pie' }
      // let state = {
      //   chart: {
      //     type: type[atomMeta.typeChart],
      //     zoomType: 'x'
      //   },
      //   title: {
      //     text: atomMeta.title,
      //     style: {
      //       color: 'blue',
      //       fontWeight: 'bold',
      //       fontSize: 14
      //     }
      //   },
      //   xAxis: {
      //     // categories: ,
      //     crosshair: true
      //   },
      //   yAxis: {
      //     min: 0,
      //     title: {
      //       text: 'Ось Y'
      //     }
      //   },
      //   legend: {
      //     reversed: false
      //   },
      //   tooltip: {
      //     headerFormat:
      //       '<span style="font-size:10px">{point.key}</span><table>',
      //     pointFormat:
      //       '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
      //       '<td style="padding:0"><b>{point.y:.1f}</b></td></tr>',
      //     footerFormat: '</table>',
      //     shared: true,
      //     useHTML: true
      //   },
      //   series: [
      //     {
      //       type: 'line',
      //       name: atomMeta.label,
      //       data: atomMeta.value
      //     }
      //   ]
      // }
      // let a = atomMeta['parameterSeries'].map(obj => {
      //   return {
      //     type: 'line',
      //     data: obj.value,
      //     name: obj.label,
      //     id: obj.id,
      //     source: obj.source
      //   }
      // })
      atomMeta['value']['series'] = atomMeta['childrenSeries']
      atomMeta['value']['chart'] = {
        ...atomMeta['value']['chart'],
        height: currentHeight
      }

      return (
        // <Grid
        //   item
        //   xs={12}
        //   style={{ margin: 10 }}
        //   onDoubleClick={e => setChart2(chart2 + 12)}
        // >
        <FF_Chart
          atomMeta={atomMeta}
          key={'FF_Chart_' + atomMeta.id + chart2}
        />
        //   {/* <HighchartsReact
        //     options={state}
        //     key={chart2 + atomMeta.id}
        //     // id={chart2 + atomMeta.id}
        //   /> */}
        //   {/* <span>{JSON.stringify(atomMeta)}</span> */}
        // // </Grid>
      )
    } else {
      return null
    }
  }

  const routerPanel = (currentHelpMeta, index) => {
    if (meta[index].type === 'panel') {
      return (
        <FF_Exp
          id={meta[index].id + '_routerPanel'}
          dragStop={idParent =>
            idParent === currentHelpMeta.i && idParent !== active
              ? setActive(idParent)
              : null
          }
          dragStart={idParent =>
            idParent === currentHelpMeta.i && active !== ''
              ? setActive('')
              : null
          }
          parent={currentHelpMeta.i}
          meta={meta[index].children}
          // getBack={(state, parent) => }
          width={(props.width * currentHelpMeta.w) / 10 - 20}
        />
      )
    } else if (meta[index].type === 'grid') {
      return (
        <Grid
          id={meta[index].id + '_routerGrid'}
          container
          item
          xs={12}
          style={{ justifyContent: 'center', alignItems: 'center' }}
        >
          {meta[index].children.map(atomMeta => {
            return (
              <Context.Provider value={{ handleChangeFFTextFields }}>
                <Grid
                  key={'GridAtom' + atomMeta.updateCom}
                  item
                  xs={11}
                  // style={{padding: 4}}
                >
                  <Tooltip
                    title={
                      'tooltip' in atomMeta && atomMeta.tooltip
                        ? atomMeta.tooltip
                        : ''
                    }
                  >
                    {routerAtomChildren(
                      atomMeta,
                      (((props.width * currentHelpMeta.w) / 10) * 10) / 12 - 20,
                      currentHelpMeta.h * (20 + 7) - 20
                    )}
                  </Tooltip>
                </Grid>
              </Context.Provider>
            )
          })}
          <Grid item xs={1}>
            <IconButton
              style={{ padding: 4, float: 'right', margin: 4 }}
              onClick={e => addComponent(meta[index].id, 'QQ')}
            >
              <IconSettings fontSize='small' />
            </IconButton>
          </Grid>
        </Grid>
      )
    } else {
      return null
    }
  }
  return helpMeta.length ? (
    <ReactGridLayout
      className='layout'
      cols={10}
      rowHeight={20}
      // style={{ overflow: 'scroll' }}
      width={width}
      key={props.parent + 'reactGridLayout' + te}
      layout={helpMeta}
      isDraggable={
        helpMeta.filter(currentHelpMeta => currentHelpMeta.i === active).length
          ? false
          : true
      }
      onDrag={e => {
        setHelpMeta(e)
        refresher(e)
      }}
      onResize={e => {
        setHelpMeta(e)
        refresher(e)
      }}
    >
      {helpMeta.length
        ? helpMeta.map((currentHelpMeta, index) => {
            return (
              <div
                key={currentHelpMeta.i}
                id={currentHelpMeta.i + '_panel'}
                onMouseOver={e =>
                  props.parent !== 'root' ? props.dragStop(props.parent) : null
                }
                onMouseOut={e =>
                  props.parent !== 'root' ? props.dragStart(props.parent) : null
                }
              >
                {/* {console.log(theme)} */}
                <Paper
                  elevation={meta[index].type === 'panel' ? 2 : 0}
                  id={currentHelpMeta.i + '_panel_paper'}
                  className={clsx(
                    theme[meta[index].type],
                    meta[index].type === 'panel' && active === currentHelpMeta.i
                      ? theme.activePanel
                      : null
                  )}
                >
                  <Grid container item xs={12} id={currentHelpMeta.i + '_body'}>
                    <Grid
                      container
                      item
                      id={currentHelpMeta.i + '_header'}
                      xs={12}
                      className={
                        meta[index].type === 'panel' ? theme.panelHeader : null
                      }
                    >
                      <Grid item xs={10} id={currentHelpMeta.i + '_hLabel'}>
                        <Typography style={{ textAlign: 'center', margin: 5 }}>
                          {meta[index].label}
                        </Typography>
                      </Grid>
                      {meta[index].type === 'panel' ? (
                        <Grid
                          item
                          xs={2}
                          style={{ textAlign: 'center' }}
                          id={currentHelpMeta.i + '_hAction'}
                        >
                          {currentHelpMeta.i !== 'root' ? (
                            <IconButton
                              style={{ padding: 8, float: 'right' }}
                              onClick={e => {
                                if (currentHelpMeta.h !== 2) {
                                  let temp = helpMeta
                                  temp.map(obj => {
                                    if (obj.i === currentHelpMeta.i) {
                                      obj.h = 2
                                    }
                                  })
                                  setTe(te + 1)
                                  setHelpMeta(temp)
                                } else {
                                  let temp = helpMeta
                                  temp.map(obj => {
                                    if (obj.i === currentHelpMeta.i) {
                                      obj.h = meta[index].position.h
                                    }
                                  })
                                  setTe(te + 1)
                                  setHelpMeta(temp)
                                }
                              }}
                            >
                              {currentHelpMeta.h !== 2 ? (
                                <ExpandMoreIcon fontSize='small' />
                              ) : (
                                <ExpandLessIcon fontSize='small' />
                              )}
                            </IconButton>
                          ) : null}
                          <IconButton
                            style={{ padding: 8, float: 'right' }}
                            onClick={e => addComponent(currentHelpMeta.i, 'QQ')}
                          >
                            <IconSettings fontSize='small' />
                          </IconButton>
                        </Grid>
                      ) : null}
                    </Grid>

                    <Grid key={currentHelpMeta.i + 'grid'} item xs={12}>
                      {routerPanel(currentHelpMeta, index)}
                    </Grid>
                  </Grid>
                </Paper>
              </div>
            )
          })
        : null}
    </ReactGridLayout>
  ) : null
}

const FF_SettingPanel = props => {
  // console.log(props)
  // -------------------------------------------------------------------------------
  //
  //                                   Стили
  //
  // -------------------------------------------------------------------------------

  const theme = useTreeItemStyles()

  const defTheme = useTheme()

  // const classes = useStyles()

  // -------------------------------------------------------------------------------
  //
  //                                 Состояния
  //
  // ---------------------------- ---------------------------------------------------

  const activeNode = props.activeNode
  const typeData = props.classifierTypeData
  const [typeSetting, setTypeSetting] = useState(0)
  const [typeElement, setTypeElement] = useState('panel')
  // console.log(activeNode, 'MAIN')
  const typeMetaElement = {
    panel: {
      id: {
        label: 'Идентификатор',
        is_unique: true,
        is_required: false,
        id_disable: true,
        is_type: 'string',
        type: 'ffTextFields'
      },
      label: {
        label: 'Наименование панели',
        is_unique: true,
        is_required: true,
        is_disable: false,
        is_type: 'string',
        type: 'ffTextFields'
      }
    },
    ffTypography: {
      id: {
        label: 'Идентификатор',
        is_unique: true,
        is_required: false,
        id_disable: true,
        is_type: 'string',
        type: 'ffTextFields'
      },
      essence: {
        label: 'Сущность',
        is_unique: true,
        is_required: true,
        is_disable: false,
        is_type: 'string',
        source: 'typeData',
        type: 'ffSelect'
      },
      parameter: {
        label: 'Параметр',
        is_unique: true,
        is_required: true,
        is_disable: false,
        is_type: 'string',
        source: typeData,
        type: 'ffSelect'
      }
    },
    ffTextFields: {
      id: {
        label: 'Идентификатор',
        is_unique: true,
        is_required: false,
        id_disable: true,
        is_type: 'string',
        type: 'ffTextFields'
      },
      essence: {
        label: 'Сущность',
        is_unique: true,
        is_required: true,
        is_disable: false,
        is_type: 'string',
        source: 'typeData',
        type: 'ffSelect'
      },
      parameter: {
        label: 'Параметр',
        is_unique: true,
        is_required: true,
        is_disable: false,
        is_type: 'string',
        source: typeData,
        type: 'ffSelect'
      }
    },
    ffSwitch: {
      id: {
        label: 'Идентификатор',
        is_unique: true,
        is_required: false,
        id_disable: true,
        is_type: 'string',
        type: 'ffTextFields'
      },
      essence: {
        label: 'Сущность',
        is_unique: true,
        is_required: true,
        is_disable: false,
        is_type: 'string',
        source: typeData,
        type: 'ffSelect'
      },
      parameter: {
        label: 'Параметр',
        is_unique: true,
        is_required: true,
        is_disable: false,
        is_type: 'string',
        source: typeData,
        type: 'ffSelect'
      }
    },
    ffToggle: {
      id: {
        label: 'Идентификатор',
        is_unique: true,
        is_required: false,
        id_disable: true,
        is_type: 'string',
        type: 'ffTextFields'
      },
      essence: {
        label: 'Сущность',
        is_unique: true,
        is_required: true,
        is_disable: false,
        is_type: 'string',
        source: typeData,
        type: 'ffSelect'
      },
      parameter: {
        label: 'Параметр',
        is_unique: true,
        is_required: true,
        is_disable: false,
        is_type: 'string',
        source: typeData,
        type: 'ffSelect'
      }
    },
    ffSelect: {
      id: {
        label: 'Идентификатор',
        is_unique: true,
        is_required: false,
        id_disable: true,
        is_type: 'string',
        type: 'ffTextFields'
      },
      essence: {
        label: 'Сущность',
        is_unique: true,
        is_required: true,
        is_disable: false,
        is_type: 'string',
        source: typeData,
        type: 'ffSelect'
      },
      parameter: {
        label: 'Параметр',
        is_unique: true,
        is_required: true,
        is_disable: false,
        is_type: 'string',
        source: typeData,
        type: 'ffSelect'
      }
    },
    ffRangeOne: {
      id: {
        label: 'Идентификатор',
        is_unique: true,
        is_required: false,
        id_disable: true,
        is_type: 'string',
        type: 'ffTextFields'
      },
      essence: {
        label: 'Сущность',
        is_unique: true,
        is_required: true,
        is_disable: false,
        is_type: 'string',
        source: typeData,
        type: 'ffSelect'
      },
      parameter: {
        label: 'Параметр',
        is_unique: true,
        is_required: true,
        is_disable: false,
        is_type: 'string',
        source: typeData,
        type: 'ffSelect'
      }
    },
    ffRangeTwo: {
      id: {
        label: 'Идентификатор',
        is_unique: true,
        is_required: false,
        id_disable: true,
        is_type: 'string',
        type: 'ffTextFields'
      },
      essence: {
        label: 'Сущность',
        is_unique: true,
        is_required: true,
        is_disable: false,
        is_type: 'string',
        source: typeData,
        type: 'ffSelect'
      },
      parameter: {
        label: 'Параметр',
        is_unique: true,
        is_required: true,
        is_disable: false,
        is_type: 'string',
        source: typeData,
        type: 'ffSelect'
      }
    },
    ffChart: {
      id: {
        label: 'Идентификатор',
        is_unique: true,
        is_required: false,
        id_disable: true,
        is_type: 'string',
        type: 'ffTextFields'
      },
      essence: {
        label: 'Сущность',
        is_unique: true,
        is_required: true,
        is_disable: false,
        is_type: 'string',
        source: typeData,
        type: 'ffSelect'
      },
      title: {
        label: 'Название графика',
        is_unique: true,
        is_required: false,
        id_disable: false,
        is_type: 'string',
        type: 'ffTextFields'
      },
      // dateRange: {
      //   label: 'Временной период',
      //   is_unique: true,
      //   is_required: true,
      //   is_disable: false,
      //   is_type: 'string',
      //   source: typeData,
      //   type: 'ffDateRange'
      // },
      childrenSeries: {
        label: 'Серия',
        type: 'parameterSeries'
      },
      parameter: {
        label: 'Добавить новую серию',
        is_unique: true,
        is_required: true,
        is_disable: false,
        is_type: 'string',
        source: typeData,
        type: 'ffSelect'
      }

      // typeChart: {
      //   label: 'Тип графика',
      //   // is_unique: true,
      //   is_required: false,
      //   id_disable: false,
      //   is_type: 'int',
      //   options: ['Линейный', 'Бар', 'Колоночная', 'Пирог'],
      //   type: 'FFSwitch'
      // }
    }
  }

  //   const [typeData, setTypeData] = useState([
  // 	  {id: 'p-type-data-string', 'Строка'},

  //   ])

  const [metaElement, setMetaElement] = useState(null)
  const [parameters, setParameters] = useState(null)
  // Доступные элементы
  const typeElementList = [
    { id: 'panel', label: 'Панель' },
    { id: 'ffTypography', label: 'Текстовое поле' },
    { id: 'ffTextFields', label: 'Текстовое поле для ввода' },
    { id: 'ffSwitch', label: 'Переключатель' },
    { id: 'ffToggle', label: 'Переключатель (тумблер)' },
    { id: 'ffSelect', label: 'Выбор из списка' },
    { id: 'ffRangeOne', label: 'Выбор значения из диапазона' },
    { id: 'ffRangeTwo', label: 'Выбор интервала из диапазона' },
    { id: 'ffChart', label: 'График' }
  ]
  const [te, setTe] = useState(0)
  const essenceId = props.essenceId
  // const [stateMeta, setStateMeta] = useState(props.essence ? {id: props.essence.id, label: props.essence.label, open: false} : null)
  // -------------------------------------------------------------------------------
  //
  //                          Обработчики событий и функции
  //
  // -------------------------------------------------------------------------------
  // useEffect(() => {
  //   doChangeSelect('', essenceId)
  // }, [])

  useEffect(() => {
    if (typeSetting === 0 && activeNode.type === 'panel') {
      createMetaElement(typeElement)
    } else if (
      (typeSetting === 1 && activeNode.type === 'panel') ||
      (typeSetting === 0 && activeNode.type !== 'panel')
    ) {
      let temp =
        activeNode.type === 'panel' ? activeNode : activeNode.children[0]
      setTypeElement(temp.type)
      getParameters(props.essence.id)
      // getParameters(essenceId)
      // getParameters('essence' in temp ? temp.essence : '')
      setMetaElement(temp)
    }
  }, [typeElement, typeSetting])

  // Событие на изменение значений
  const handleChangeFFTextFields = (value, id, name, element) => {
    if (element) {
      if (id === 'typeSetting') {
        setTypeSetting(value)
      } else if (id === 'meta') {
        let tempMeta = metaElement
        if (name === 'title' && typeElement === 'ffChart') {
          tempMeta['value']['title']['text'] = value
        }
        if (name === 'parameter') {
          let param = props.treeUtils.getNodeByKey(
            typeData,
            tempMeta['essence']
          )
          param = param.parameters.filter(obj => obj.id === value)[0]
          // console.log(tempMeta, typeElement, param)
          if (typeElement === 'ffChart') {
            if (
              tempMeta['childrenSeries'].filter(obj => obj.id === param.id)
                .length == 0
            ) {
              tempMeta['childrenSeries'] = [
                ...tempMeta['childrenSeries'],
                {
                  type: 'line',
                  data: [],
                  // data: param.value,
                  name: param.label,
                  source: param.source,
                  measure_unit: param.measure_unit,
                  parameter: param.id,
                  essence: tempMeta['essence']
                }
              ]
            }
            // tempMeta['value']['series'] = [
            //   ...tempMeta['value']['series'],
            //   { type: 'line', data: param.value, name: param.label }
            // ]
          } else {
            tempMeta['measure_unit'] =
              param?.measure_unit &&
              param?.measure_unit['1'] &&
              param?.measure_unit['1']?.short_name
                ? param?.measure_unit['1']?.short_name
                : null
            tempMeta['label'] = param.label
            tempMeta['value'] = param.value
            tempMeta['source'] = param.source
          }
          tempMeta['typeData'] = param.type
          if (
            ['ffSelect', 'ffRangeOne', 'ffRangeTwo'].indexOf(typeElement) > -1
          )
            tempMeta['options'] = param.options
        }
        setTe(te + 1)
        // if (typeElement !== 'ffChart') {
        tempMeta[name] = value
        // }
        setMetaElement(tempMeta)
      }
    }
    return ''
  }

  // Функция для создания основы меты
  const createMetaElement = type => {
    let metaTemp = {}
    Object.keys(typeMetaElement[type]).map(nameAttr => {
      if (typeMetaElement[type][nameAttr].is_type === 'string') {
        metaTemp[nameAttr] = nameAttr === 'id' ? uuidv4() : ''
      }
      if (typeMetaElement[type][nameAttr].is_type === 'int') {
        metaTemp[nameAttr] = 0
      }
    })
    if (type === 'panel') {
      metaTemp['position'] = {
        w: 5,
        h: 4,
        x: 0,
        y: 0
      }
      metaTemp['type'] = 'panel'
      metaTemp['children'] = []
    } else {
      metaTemp['parameter'] = ''
      metaTemp['essence'] = props.essence.id
      getParameters(props.essence.id)
      if (type === 'ffChart') {
        let metaChart = {
          chart: {
            type: 'line',
            zoomType: 'x'
          },
          title: {
            text: '',
            style: {
              color: 'blue',
              fontWeight: 'bold',
              fontSize: 14
            }
          },
          xAxis: {
            // type: 'datetime',
            // categories: ,
            crosshair: true
          },
          yAxis: [
            {
              title: {
                text: 'Безразмерная базовая ось'
              }
            }
          ],
          legend: {
            reversed: false
          },
          tooltip: {
            headerFormat:
              '<span style="font-size:10px">{point.key}</span><table>',
            pointFormat:
              '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
              '<td style="padding:0"><b>{point.y:.1f}</b></td></tr>',
            footerFormat: '</table>',
            shared: true,
            useHTML: true
          },
          series: [
            // {
            //   type: 'line',
            //   name: atomMeta.label,
            //   data: atomMeta.value
            // }
          ]
        }
        metaTemp['childrenSeries'] = []
        metaTemp['value'] = metaChart
      }
      metaTemp['updateCom'] = uuidv4()
      // setMetaElement(tempMeta)
      // doChangeSelect('', props.essence.id)
    }
    setMetaElement(metaTemp)
  }

  // // Событие на изменение типа элемента
  const changeTypeElement = e => {
    let type = e.target.value
    setTypeElement(type)
    createMetaElement(type)
  }

  // Получить параметры
  const getParameters = id => {
    let tempParameters = props.treeUtils.getNodeByKey(typeData, id)
    setParameters(null)
    if (tempParameters && 'parameters' in tempParameters) {
      // console.log(tempParameters, id)
      tempParameters = tempParameters.parameters.filter(
        obj => JSON.stringify(obj.allow_components).indexOf(typeElement) > -1
      )
      setParameters(tempParameters)
    }
    setTe(te + 1)
  }

  const doChangeSelect = (parentId, id) => {
    let tempMeta = metaElement
    tempMeta['parameter'] = ''
    tempMeta['essence'] = id
    getParameters(id)
    setMetaElement(tempMeta)
    setTe(te + 1)

    // setTe(te + 1)
  }

  const deleteParameterSeries = id => {
    let tempMeta = metaElement
    // console.log('sssss', metaElement)
    tempMeta['childrenSeries'] = tempMeta['childrenSeries'].filter(
      obj => obj.parameter !== id
    )
    // console.log(tempMeta['childrenSeries'], id)
    setMetaElement(tempMeta)
    setTe(te + 1)
  }
  // -------------------------------------------------------------------------------
  //
  //                                 Роутеры компонентов
  //
  // -------------------------------------------------------------------------------

  // Роутер для подкидывания полей для заполнения
  const routerMetaElement = oldMeta => {
    if (metaElement) {
      return (
        <React.Fragment key={metaElement.id}>
          {Object.keys(typeMetaElement[typeElement]).map(nameAttr => {
            const routerElement = () => {
              let typeObj = typeMetaElement[typeElement][nameAttr].type
              if (typeObj === 'ffTextFields')
                return (
                  <FFTextFields
                    key={'FFTextFields_metaId'}
                    value={metaElement[nameAttr]}
                    is_filter={false}
                    name={nameAttr}
                    label={typeMetaElement[typeElement][nameAttr].label}
                    parameter={{ id: 'meta' }}
                    dis={typeMetaElement[typeElement][nameAttr].id_disable}
                  />
                )
              else if (typeObj === 'ffSelect' && nameAttr === 'essence') {
                return (
                  <FFTextFields
                    key={'FFTextFields_metaId'}
                    value={props.essence.label}
                    is_filter={false}
                    name={nameAttr}
                    label={typeMetaElement[typeElement][nameAttr].label}
                    parameter={{ id: 'meta' }}
                    dis={true}
                  />
                )
              } else if (typeObj === 'ffSelect' && nameAttr === 'parameter') {
                return (
                  <FFSelect
                    id={'meta'}
                    name={nameAttr}
                    value={metaElement[nameAttr]}
                    options={parameters}
                    label={typeMetaElement[typeElement][nameAttr].label}
                  />
                )
              } else if (
                typeObj === 'parameterSeries' &&
                'childrenSeries' in metaElement
              ) {
                return metaElement['childrenSeries'].map((obj, index) => {
                  return (
                    <Grid container item xs={12}>
                      <Grid item xs={10}>
                        <FFTextFields
                          key={'FFTextFields_metaId'}
                          value={obj.name}
                          is_filter={false}
                          name={nameAttr}
                          label={'Серия №' + (index + 1)}
                          parameter={{ id: 'meta' }}
                          dis={true}
                        />
                      </Grid>
                      <Grid item xs={2} style={{ alignSelf: 'center' }}>
                        <IconButton
                          aria-label='filter'
                          onClick={e => deleteParameterSeries(obj.parameter)}
                          // className={classes.margin}
                          // style={{ visibility: isEdit === 0 ? 'visible' : 'hidden' }}
                        >
                          <IconDelete color='error' fontSize='small' />
                        </IconButton>
                      </Grid>
                    </Grid>
                  )
                })
              } else if (typeObj === 'FFSwitch') {
                return (
                  <FFSwitch_N
                    name={nameAttr}
                    meta={{
                      label: typeMetaElement[typeElement][nameAttr].options,
                      width: 255 / 4 - 3,
                      value: metaElement[nameAttr]
                    }}
                    id={'meta'}
                  />
                )
              } else if (typeObj === 'ffDateRange') {
                return (
                  <FFDateRange
                    name={nameAttr}
                    id={'meta'}
                    value={metaElement[nameAttr]}
                    label={typeMetaElement[typeElement][nameAttr].label}
                  />
                )
              } else {
                return null
              }
            }
            return (
              <Grid item xs={12}>
                {routerElement()}
              </Grid>
            )
          })}
        </React.Fragment>
      )
    }
  }

  // Роутер для подкидывания карточки
  const routerSetting = () => {
    if (typeSetting === 0 && activeNode.type === 'panel') {
      return (
        <Card style={{ margin: 5 }}>
          <CardHeader
            avatar={
              <Avatar
                aria-label='recipe'
                style={{ backgroundColor: defTheme.palette.success.main }}
              >
                <IconAdd />
              </Avatar>
            }
            title='Создание нового элемента'
            subheader='Метаданные элемента'
          />
          <CardContent>
            <Grid item container xs={12}>
              <Grid item xs={12}>
                <FormControl
                  variant='filled'
                  style={{ width: '-webkit-fill-available', margin: 4 }}
                >
                  <InputLabel id='FFParametersNew_InputLabel_select_formatList'>
                    Тип создаваемого элемента
                  </InputLabel>
                  <Select
                    key={'select'}
                    value={typeElement}
                    onChange={e => changeTypeElement(e)}
                  >
                    {typeElementList.map(objChild => {
                      return (
                        <MenuItem value={objChild.id}>
                          {objChild.label}
                        </MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
              </Grid>
              {routerMetaElement()}
              <Grid xs={12}>
                <Button
                  variant='outlined'
                  className={theme.actionButton}
                  style={{
                    color: defTheme.palette.success.main
                  }}
                  onClick={e =>
                    props.actionSetting(
                      'create',
                      activeNode.id,
                      metaElement,
                      typeElement
                    )
                  }
                >
                  Создать
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      )
    }
    if (
      activeNode.id !== 'root' &&
      ((typeSetting === 1 && activeNode.type === 'panel') ||
        (typeSetting === 0 && activeNode.type !== 'panel'))
    ) {
      return (
        <Card style={{ margin: 5 }}>
          <CardHeader
            avatar={
              <Avatar
                aria-label='recipe'
                style={{ backgroundColor: defTheme.palette.warning.main }}
              >
                <IconSettings />
              </Avatar>
            }
            title='Изменение текущего элемента'
            subheader='Метаданные панели'
          />
          <CardContent>
            <Grid container item xs={12}>
              {routerMetaElement()}
              <Grid xs={12}>
                <Button
                  variant='outlined'
                  className={theme.actionButton}
                  style={{
                    color: defTheme.palette.warning.main
                  }}
                  onClick={e =>
                    props.actionSetting(
                      'update',
                      activeNode.id,
                      metaElement,
                      typeElement
                    )
                  }
                >
                  Изменить
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      )
    } else if (activeNode.id !== 'root') {
      return (
        <Card style={{ margin: 5 }}>
          <CardHeader
            avatar={
              <Avatar
                aria-label='recipe'
                style={{ backgroundColor: defTheme.palette.error.main }}
              >
                <IconDelete />
              </Avatar>
            }
            title={`Элемент "${activeNode.label}"`}
            subheader='Удаление текущего элемента'
          />
          <CardContent>
            {'children' in activeNode && activeNode.children.length ? (
              <Alert severity='error'>{`В состав удаляемого элемента входит как минимум ${activeNode.children.length} элемента)`}</Alert>
            ) : null}
            <Button
              variant='outlined'
              className={theme.actionButton}
              style={{
                color: defTheme.palette.error.main
              }}
              onClick={e =>
                props.actionSetting(
                  'delete',
                  activeNode.id,
                  null,
                  activeNode.type
                )
              }
            >
              Удалить
            </Button>
          </CardContent>
        </Card>
      )
    }
  }

  return (
    <Paper elevation={0} style={{ width: 300 }} className={theme.flexEnd}>
      <Context.Provider value={{ handleChangeFFTextFields }}>
        <Grid item xs={12}>
          <Grid item xs={12}>
            <FFSwitch_N
              meta={{
                label:
                  activeNode.id === 'root'
                    ? ['Создать']
                    : activeNode.type === 'panel'
                    ? ['Создать', 'Изменить', 'Удалить']
                    : ['Изменить', 'Удалить'],
                width:
                  activeNode.id === 'root'
                    ? 300 - 3
                    : activeNode.type === 'panel'
                    ? 300 / 3 - 3
                    : 300 / 2 - 4,
                value: typeSetting
              }}
              id={'typeSetting'}
            />
          </Grid>
          <Grid item xs={12}>
            {routerSetting()}
          </Grid>
        </Grid>
      </Context.Provider>
    </Paper>
  )
}

// export default FF_TE

export default function FF_Main (props) {
  const config = {
    childrenField: 'children',
    keyField: 'id'
  }

  const childGrid = {
    childrenField: 'parameter',
    keyField: 'id'
  }

  const treeUtils = new TreeNodeUtils(config)

  const treeUtilsParameter = new TreeNodeUtils(config)

  // -------------------------------------------------------------------------------
  //
  //                                 Снэк Бар
  //
  // -------------------------------------------------------------------------------

  const { enqueueSnackbar } = useSnackbar()
  const handleClickVariant = (variant, msg) => {
    // variant could be success, error, warning, info, or default
    enqueueSnackbar(
      msg,
      { variant },
      {
        anchorOrigin: { vertical: 'top', horizontal: 'right' }
      }
    )
  }

  // const classes = useStyles()
  const theme = useTreeItemStyles()
  const strAuthorization = 'Bearer ' + cookie.load('access_token')
  const { isLogin } = useContext(Context)
  // layout is an array of objects, see the demo for more complete usage
  const [essence, setEssence] = useState([])
  const [classifierTypeData, setClassifierTypeData] = useState(
    props.classifierTypeData
  )

  const [te, setTe] = useState(0)
  const [meta, setMeta] = useState(props.meta)
  // const [meta, setMeta] = useState([
  //   {
  //     id: 'root',
  //     label: 'Редактирование панели',
  //     type: 'panel',
  //     position: {
  //       w: 10,
  //       h: 40,
  //       x: 0,
  //       y: 0
  //     },
  //     children: []
  //   }
  // ])

  function useInterval (callback, delay) {
    const savedCallback = useRef()

    // Remember the latest callback.
    useEffect(() => {
      savedCallback.current = callback
    }, [callback])

    // Set up the interval.
    useEffect(() => {
      function tick () {
        savedCallback.current()
      }
      if (delay !== null) {
        let id = setInterval(tick, delay)
        return () => clearInterval(id)
      }
    }, [delay])
  }

  const [update1, setUpdate] = useState({})
  useEffect(() => {
    if ('obj' in update1) {
      let updateCol = ['streamParam', 'simpleParam']
      updateCol.map(nameCol => {
        let updateParam =
          nameCol in update1.obj[0] && update1.obj[0][nameCol]
            ? update1.obj[0][nameCol]
            : []
        if (props.essence.id in meta[0]['full_parameters']) {
          Object.keys(updateParam).map(key => {
            if (key in meta[0]['full_parameters'][props.essence.id]) {
              if (nameCol === 'streamParam') {
                meta[0]['full_parameters'][props.essence.id][key].value =
                  String(updateParam[key].value)
                meta[0]['full_parameters'][props.essence.id][key].tooltip =
                  'last_update' in updateParam[key]
                    ? new Date(
                        parseInt(updateParam[key].last_update + '000')
                      ).toLocaleString()
                    : null
              } else {
                meta[0]['full_parameters'][props.essence.id][key].value =
                  updateParam[key]
              }
            }
          })
        }
      })

      let timeStream = update1.obj[0]['timeStreamParam']
      timeStream.map(plot => {
        let nodeChart = treeUtils.getNodeByKey(meta, plot.plot)
        if (nodeChart) {
          // console.log(nodeChart, plot, 'q1qq')
          nodeChart.childrenSeries.map(paramChart => {
            paramChart.data = plot.parameters[paramChart.parameter]
            if (paramChart.data) {
              paramChart.data.map(values => {
                if (Array.isArray(values)) {
                  values[0] = Number(values[0] + '000')
                }
              })
            }
          })
          // console.log(nodeChart)
          nodeChart['updateCom'] = uuidv4()
        }
      })
      // console.log(
      //   meta[0]['full_parameters'][props.essence.id],
      //   'UPDATE',
      //   update1
      // )
      refreshConnection(meta[0], true)
      // setTe(te + 1)
    }
    // console.log(update1)
  }, [update1])

  useInterval(() => {
    // if (
    //   'connections' in meta[0] &&
    //   props.essence.id in meta[0]['connections'] &&
    //   meta[0]['connections'][props.essence.id].length > 0
    // ) {
    // refreshConnection(meta[0], false)
    if ('full_parameters' in meta[0] && true) {
      ApiConnect({
        name: 'getDataPost',
        url: urlConfiguration + '/api/control/hot_values/',
        setterEssence: setUpdate,
        objToGet: [
          {
            essence: props.essence.id,
            simpleParam:
              meta[0]['connections'][props.essence.id]['simpleParam'],
            timeStreamParam: [],
            // meta[0]['connections'][props.essence.id]['timeStreamParam'],
            streamParam: meta[0]['connections'][props.essence.id]['streamParam']
          }
        ],
        // setterLoading: setEssenceLoading,
        isLogin: isLogin
      })
    }
  }, 10000)

  useInterval(() => {
    // if (
    //   'connections' in meta[0] &&
    //   props.essence.id in meta[0]['connections'] &&
    //   meta[0]['connections'][props.essence.id].length > 0
    // ) {
    // refreshConnection(meta[0], false)
    let timeStream = meta[0]['connections'][props.essence.id]['timeStreamParam']
    timeStream.map(plot => {
      plot['time_start'] = Number(
        String(new Date(new Date().toDateString()).valueOf()).slice(0, 10)
      )
      plot['time_stop'] = Number(String(new Date().valueOf()).slice(0, 10))
    })
    if ('full_parameters' in meta[0] && true) {
      ApiConnect({
        name: 'getDataPost',
        url: urlConfiguration + '/api/control/hot_values/',
        setterEssence: setUpdate,
        objToGet: [
          {
            essence: props.essence.id,
            simpleParam:
              meta[0]['connections'][props.essence.id]['simpleParam'],
            timeStreamParam:
              meta[0]['connections'][props.essence.id]['timeStreamParam'],
            streamParam: meta[0]['connections'][props.essence.id]['streamParam']
          }
        ],
        // setterLoading: setEssenceLoading,
        isLogin: isLogin
      })
    }
  }, 60000)

  useEffect(() => {
    // await ApiConnect({
    //   name: 'getData',
    //   url:
    //     urlConfiguration +
    //     '/api/support/info/?entity=essence&action=select&info=tree&parameters=true',
    //   setterEssence: setClassifierTypeData,
    //   // setterLoading: setEssenceLoading,
    //   isLogin: isLogin
    // })
    meta[0].connections[props.essence.id] = {
      simpleParam: [],
      streamParam: [],
      timeStreamParam: []
    }
    refreshConnection(meta[0], true)
    refreshConnection(meta[0], false)
  }, [])

  const getNodeParentId = (brach, id) => {
    if ('children' in brach && brach['children'].length) {
      if (brach['children'][0].id === id) return brach.id
      else return getNodeParentId(brach['children'][0], id)
    } else {
      return null
    }
  }

  const refreshConnection = (branch, is_start) => {
    // console.log(branch)
    // if ('stream_id_values' in meta[0] === false) {
    //   meta[0]['stream_id_values'] = {}
    //   meta[0].connections[props.essence.id] = []
    // }
    if ('children' in branch) {
      branch.children.map(obj => {
        refreshConnection(obj, is_start)
      })
    } else if (branch['type'] === 'ffChart') {
      if (is_start === false) {
        let tempPlot = {
          plot: branch.id,
          time_start: Number(
            String(new Date(new Date().toDateString()).valueOf()).slice(0, 10)
          ),
          // time_stop: String(new Date(new Date().toDateString()).valueOf() + 86400000).slice(0, 10),
          time_stop: Number(String(new Date().valueOf()).slice(0, 10)),
          parameters: []
        }
        branch['childrenSeries'].map(param => {
          // if (param['source'] === 'p-source-data-stream') {
          tempPlot.parameters = [...tempPlot.parameters, param.parameter]
          // }
        })
        if (tempPlot.parameters.length > 0) {
          meta[0].connections[props.essence.id]['timeStreamParam'] = [
            ...meta[0].connections[props.essence.id]['timeStreamParam'],
            tempPlot
          ]
        }
      }
    } else if (branch['type'] !== 'ffChart') {
      if (is_start === false) {
        // console.log(meta[0])
        if (
          'source' in branch &&
          branch['source'] === 'p-source-data-stream' &&
          meta[0].connections[props.essence.id]['streamParam'].indexOf(
            branch.parameter
          ) === -1
        ) {
          meta[0].connections[props.essence.id]['streamParam'] = [
            ...meta[0].connections[props.essence.id]['streamParam'],
            branch.parameter
          ]
        } else if (
          'source' in branch &&
          branch['source'] !== 'p-source-data-stream' &&
          meta[0].connections[props.essence.id]['simpleParam'].indexOf(
            branch.parameter
          ) === -1
        ) {
          meta[0].connections[props.essence.id]['simpleParam'] = [
            ...meta[0].connections[props.essence.id]['simpleParam'],
            branch.parameter
          ]
        }
      } else {
        if (
          'full_parameters' in meta[0] &&
          branch.essence in meta[0].full_parameters &&
          branch.parameter in meta[0].full_parameters[branch.essence]
        ) {
          let param = meta[0].full_parameters[branch.essence][branch.parameter]
          branch['updateCom'] = uuidv4()
          branch['tooltip'] = 'tooltip' in param ? param.tooltip : null
          branch['label'] = param.label
          branch['value'] = param.value
          branch['typeData'] = param.type
          branch['source'] = param.source
          branch['options'] = param.options
          // console.log(branch)
        }
      }
    }
  }

  const [open, setOpen] = useState(false)
  const [activeNode, setActiveNode] = useState('')
  const addComponent = (id, name) => {
    setOpen(true)
    // console.log(treeUtils.filterNodes(meta, obj => obj.parameter === '111'))
    setActiveNode(treeUtils.getNodeByKey(meta, id))
    // let temp = {
    //   id: uuidv4(),
    //   label: 'Нова',
    //   type: 'panel',
    //   position: {
    //     w: 10,
    //     h: 7,
    //     x: 0,
    //     y: 0
    //   },
    //   children: []
    // }
    // let activeNode = treeUtils.getNodeByKey(meta, id)
    // activeNode.children = [...activeNode.children, temp]
    // setTe(te + 1)
  }

  const [currentComponent, setCurrentComponent] = useState('')
  const [width, height] = useWindowSize()

  const actionSetting = (typeAction, idNode, metaElement, typeElement) => {
    // console.log(
    //   typeAction,
    //   idNode,
    //   metaElement,
    //   typeElement,
    //   treeUtils.getNodeByKey(meta, idNode)
    // )
    if (typeAction === 'delete') {
      let parentId = getNodeParentId(
        treeUtils.filterNodes(meta, obj => obj.id === idNode)[0],
        idNode
      )
      let parentNode = treeUtils.getNodeByKey(meta, parentId)
      if (parentNode !== null) {
        parentNode.children = parentNode.children.filter(
          (obj, index) => obj.id !== idNode
        )
      } else {
        setMeta(meta.filter(obj => obj.id !== idNode))
      }
      //   setMeta(meta)
    }
    // if(typeA)
    if (typeAction === 'create' && typeElement === 'panel') {
      let activeNode = treeUtils.getNodeByKey(meta, idNode)
      activeNode.children = [...activeNode.children, metaElement]
    } else if (typeAction === 'create' && typeElement !== 'panel') {
      if (metaElement['parameter'] === '') {
        handleClickVariant('warning', 'Укажите параметр')
        return
      }
      let activeNode = treeUtils.getNodeByKey(meta, idNode)
      metaElement['type'] = typeElement
      if (activeNode !== null) {
        let temp = {
          id: uuidv4(),
          type: 'grid',
          position: {
            x: 0,
            y: 0,
            w: 4,
            h: 3
          },
          children: [metaElement]
        }
        activeNode.children = [...activeNode.children, temp]
      }
      // meta['connections'][props.essence.id]
      // console.log(treeUtils.filterNodes(meta, obj => obj.type === 'grid'))
    }
    // console.log(typeAction, idNode, metaElement, typeElement)
    meta[0].connections[props.essence.id] = {
      simpleParam: [],
      streamParam: [],
      timeStreamParam: []
    }
    refreshConnection(meta[0], false)
    // console.log(meta[0].connections)
    setTe(te + 1)
    setOpen(false)
  }

  // console.log('11111', classifierTypeData)
  return (
    <TableContainer className={theme.flexEnd}>
      <Drawer anchor={'right'} open={open} onClose={e => setOpen(false)}>
        {/* {console.log(classifierTypeData)} */}
        <FF_SettingPanel
          activeNode={activeNode}
          actionSetting={actionSetting}
          classifierTypeData={classifierTypeData}
          treeUtils={treeUtils}
          essence={props.essence}
        />
      </Drawer>
      <Grid container xs={12}>
        {/* <Grid item xs={12}>
          <Button
            variant='outlined'
            color='primary'
            onClick={e => console.log(meta)}
          >
            Получить текущую мету панели
          </Button>
        </Grid> */}
        <Grid item xs={12} style={{ padding: 10 }}>
          <Paper elevation={3} className={theme.flexEnd}>
            <Context.Provider value={{ addComponent }}>
              <FF_Exp
                key={te}
                meta={meta}
                width={(width * 12) / 12 - 20}
                parent={'root'}
                dragStop={idParent => null} //console.log(idParent)}
                dragStart={idParent => null} //console.log(idParent)}
                active='root'
                style={{ padding: 10 }}
              />
            </Context.Provider>
          </Paper>
        </Grid>
      </Grid>
    </TableContainer>
  )
}
