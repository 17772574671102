import React, { useEffect, useState, useContext } from 'react'
import {Grid} from '@material-ui/core'

import Context from '../../../../context'

import FFTextFields from '../../../../components/FFTextFields'

import FF_Classifier_step3_formatData_valueList from './FF_Classifier_step3_formatData_valueList'
import FF_Classifier_step3_formatData_valueRange from './FF_Classifier_step3_formatData_valueRange'

import {v4 as uuidv4} from 'uuid'


export default function FF_Classifier_step3_formatData_dict (props){
  // -------------------------------------------------------------------------------
  //
  //                                   Стили
  //
  // -------------------------------------------------------------------------------


  // -------------------------------------------------------------------------------
  //
  //                                 Мета описание
  //
  // ---------------------------- ---------------------------------------------------

  // -------------------------------------------------------------------------------
  //
  //                                 Состояние
  //
  // ---------------------------- ---------------------------------------------------

  const { pFormatParent } = useContext(Context)
  const [te, setTe] = useState(0)
  const [value, setValue] = useState({})

  // -------------------------------------------------------------------------------
  //
  //                                 Обработчики событий
  //
  // -------------------------------------------------------------------------------

  useEffect(() => {
    let temp = props.value && props.value.value ? props.value.value : null
    if (temp === null) {
      temp = {}
      temp.key = ''
      temp.value = null
      temp['p-format-data'] = ''
    }
    temp.id = uuidv4()
    setTe(te + 1)
    setValue(temp)
  }, [])

  const changeDefaultValue = event => {
    let temp = value
    temp['p-format-data'] = event.target.value
    // console.log(temp)
    setValue(temp)
    setTe(te + 1)
  }

  const handleChangeFFTextFields = (value2, parameter_id, name, element) => {
    if (element) {
      let temp = value
      if (name === 'key' && value2 === '') {
        pFormatParent('p-format-data-dict', value, 'Ключ для словаря не задан')
        return 'Введите ключ для словаря'
      }
      temp[name] = value2
      setValue(temp)
      pFormatParent('p-format-data-dict', value, '')
      // console.log(temp, name)
    }
    if (name === 'key' && value2 === '') {
      pFormatParent('p-format-data-dict', value, 'Ключ для словаря не задан')
      return 'Введите ключ для словаря'
    }
    return ''
  }

  const pFormatDataValueList = (nameAttr, data, error) => {
    let temp = value
    temp['value'] = data
    setValue(temp)
    pFormatParent('p-format-data-dict', value, error)
    // console.log(nameAttr, data)
  }

  const routerComponent = format => {
    if (format === 'p-format-data-value-list') {
      return (
        <React.Fragment>
          <FF_Classifier_step3_formatData_valueList
            value={JSON.parse(JSON.stringify(props.value.value))}
            nameAttr={'p-format-data-value-list'}
          />
        </React.Fragment>
      )
    } else if (format === 'p-format-data-value-range') {
      return (
        <React.Fragment>
          <FF_Classifier_step3_formatData_valueRange
            value={JSON.parse(JSON.stringify(props.value.value))}
            nameAttr={'p-format-data-value-range'}
          />
        </React.Fragment>
      )
    }
    return null
  }

  return value ? (
    <Context.Provider
      value={{ handleChangeFFTextFields, pFormatDataValueList }}
    >
      <React.Fragment>
        <Grid container item xs={12} spacing={1}>
          <Grid item xs={12} spacing={1}>
            {/* {console.log(value['length'])} */}
            <FFTextFields
              style={{ margin: 4, width: '100%' }}
              key={'FFTextFields_key' + te}
              value={value['key']}
              is_filter={false}
              name={'key'}
              label={'Ключ'}
              parameter={value}
              dis={false}
            />
          </Grid>
          <Grid item xs={6} spacing={1}>
            {/* <FormControl variant='filled' style={{ margin: 3, width: '100%' }}>
              <InputLabel id='FFParametersNew_InputLabel_select_formatList'>
                Формат элементов списка
              </InputLabel>
              <Select
                key={'select' + value['p-format-data']}
                value={value['p-format-data']}
                onChange={changeDefaultValue}
              >
                {props.classifier.children.map(objChild => {
                  if (objChild.label.indexOf('Значение (') > -1) {
                    return (
                      <MenuItem value={objChild.name}>
                        {objChild.label}
                      </MenuItem>
                    )
                  }
                })}
              </Select>
            </FormControl> */}
          </Grid>
        </Grid>
        <React.Fragment key={value['p-format-data']}>
          {routerComponent(value['p-format-data'])}
        </React.Fragment>
      </React.Fragment>
    </Context.Provider>
  ) : null
}
