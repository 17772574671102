import React, {
  useEffect,
  useState,
  useContext,
  useLayoutEffect,
  useRef
} from 'react'

/*
 **------------------------- CORE ------------------------------
 */
import {
  Paper,
  Typography,
  Grid,
  IconButton,
  Button,
  Tooltip,
  Drawer,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Chip
} from '@material-ui/core'
import PropTypes from 'prop-types'
import { makeStyles, withStyles, formatMs } from '@material-ui/core/styles'
import { useTheme } from '@material-ui/core/styles'

/*
 **------------------------ ICONs ------------------------------
 */
import IconDynamicFeed from '@material-ui/icons/DynamicFeed'
import IconSave from '@material-ui/icons/Save'
import IconAccountTree from '@material-ui/icons/AccountTree'
import FlashAutoIcon from '@material-ui/icons/FlashAuto'
import CompareIcon from '@material-ui/icons/Compare'

/*
 **-------------------- My Components ---------------------------
 */
import FFSelectTree from '../../components/FFSelectTree'
import FFSelect from '../../components/FFSelectSimple'
import FFLoaderComponent from '../../components/FFLoaderComponent'
import FFTreeEssence from '../../components/FFTreeEssence'
import FFMaterialTable from '../../components/FFMaterialTable'
import FFContainerColumn from '../../components/FFContainerColumn'
import Loader from '../../Loader'
import CSVReader from 'react-csv-reader'

/*
 **------------------------- UTILs ------------------------------
 */
import clsx from 'clsx'
import cookie from 'react-cookies'

import { SnackbarProvider, useSnackbar } from 'notistack'
import TreeNodeUtils from 'tree-node-utils'
import { ApiConnect, urlConfiguration } from '../../ApiConnectNew'
import Context from '../../context'
import MaterialTable from 'material-table'
import {v4 as uuidv4} from 'uuid'

const useStyles = makeStyles(theme => ({
  margin: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  drawer: {
    padding: theme.spacing(1),
    margin: theme.spacing(1),
    width: 360,
    overflow: 'overlay',
    overflowX: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1
  },
  tableCell: {
    border: '1px solid',
    borderColor: theme.palette.grey[300],
    verticalAlign: 'center',
    textAlign: 'center'
  },
  labelText: {
    textOverflow: 'ellipsis',
    whiteSpace: 'wrap'
  }
}))

export default function FF_Main (props) {
  // -------------------------------------------------------------------------------
  //
  //                                   Стили
  //
  // -------------------------------------------------------------------------------
  const classes = useStyles()

  const config = {
    childrenField: 'children',
    keyField: 'id'
  }
  const treeUtils = new TreeNodeUtils(config)

  const defTheme = useTheme()

  // -------------------------------------------------------------------------------
  //
  //                                 Состояния
  //
  // -------------------------------------------------------------------------------

  const strAuthorization = 'Bearer ' + cookie.load('access_token')

  const { isLogin } = useContext(Context)

  const [essence, setEssence] = useState([])
  const [essenceLoading, setEssenceLoading] = useState(false)
  const [activeEssence, setActiveEssence] = useState(null)

  const [data, setData] = useState([])
  const [te, setTe] = useState(0)

  const [openAdd, setOpenAdd] = useState(false)
  const [atomMeta, setAtomMeta] = useState({
    object: '',
    essence: '',
    parameter: ''
  })
  const [row, setRow] = useState(null)
  const [object, setObject] = useState([])
  const [ess, setEss] = useState([])
  const [parameter, setParameter] = useState([])
  const [openTree, setOpenTree] = useState(false)
  const [topic, setTopic] = useState(false)
  const [stream, setStream] = useState(true)

  // -------------------------------------------------------------------------------
  //
  //                                 Снэк Бар
  //
  // -------------------------------------------------------------------------------

  const { enqueueSnackbar } = useSnackbar()
  const handleClickVariant = (variant, msg) => {
    // variant could be success, error, warning, info, or default
    enqueueSnackbar(
      msg,
      { variant },
      {
        anchorOrigin: { vertical: 'top', horizontal: 'right' }
      }
    )
  }

  // -------------------------------------------------------------------------------
  //
  //                                 Обработчики событий
  //
  // -------------------------------------------------------------------------------

  useEffect(() => {
    setData([])
  }, [activeEssence])

  const checkerTopic = tempData => {
    setStream(false)
    const attr = 'topic'
    let obj = {}
    obj[attr] = tempData.map(obj => {
      return [obj[attr], obj['controller']]
    })
    // console.log(obj)
    ApiConnect({
      name: 'getDataPost',
      url:
        urlConfiguration +
        '/api/mapping/topic_stream_parameter/check_unique/list/?check=' +
        attr,
      objToGet: obj,
      setterEssence: temp => {
        if (temp?.elements) {
          temp.elements.forEach(obj => {
            tempData.forEach(a => {
              if (
                a[attr] === obj[attr] &&
                a['controller'] === obj['controller']
              ) {
                a.is_uniq = obj.is_unique
                a.controller_is_exist = obj.controller_is_exist
                a.is_match_controller = obj.is_match_controller
              }
            })
          })
        }
        checkerStream(tempData)
      },
      setterLoading: null,
      isLogin: isLogin,
      clickHandle: handleClickVariant
    })
  }

  const checkerStream = tempData => {
    const attr = 'stream'
    let obj = {}
    obj[attr] = tempData.map(obj => obj[attr])
    ApiConnect({
      name: 'getDataPost',
      url:
        urlConfiguration +
        '/api/mapping/topic_stream_parameter/check_unique/list/?check=' +
        attr,
      objToGet: obj,
      setterEssence: temp => {
        if (temp?.elements) {
          temp.elements.forEach(obj => {
            tempData.forEach(a => {
              if (a[attr] === obj[attr]) {
                a.is_uniq = obj.is_unique
              }
            })
          })
        }
        setData([...tempData])
        setStream(true)
      },
      setterLoading: null,
      isLogin: isLogin,
      clickHandle: handleClickVariant
    })
  }

  const isUnic = attr => {
    let tempTopic = data.map(obj => obj[attr])
    tempTopic.map((topic, indexTopic) => {
      if (topic !== null && tempTopic.indexOf(topic, indexTopic + 1) > -1) {
        data[tempTopic.indexOf(topic, indexTopic)].is_uniq = false
      }
    })
  }

  // useEffect(() => {
  //   setTe(te + 1)
  // }, [data])

  useEffect(() => {
    ApiConnect({
      name: 'getData',
      url:
        urlConfiguration +
        '/api/mapping/topic_stream_parameter/tree/controllers/',
      setterEssence: setEssence,
      setterLoading: setEssenceLoading,
      isLogin: isLogin
    })
  }, [])

  // Событие на загрузку файла
  const handleForce = (fileData, fileInfo = null) => {
    setData([])
    const newData = fileData.map(obj => {
      const mask = {
        object: '',
        essence: '',
        parameter: '',
        is_uniq: false,
        is_match_controller: false,
        controller_is_exist: false,
        linkToParameter: '',
        tc: obj.tc | 1
      }
      return { ...obj, ...mask }
    })

    checkerTopic(newData)
    // setData(newData)
  }

  const papaparseOptions = {
    header: true,
    dynamicTyping: true,
    skipEmptyLines: true,
    transformHeader: header => header.toLowerCase().replace(/\W/g, '_')
  }

  const doClickActiveEssence = (objId, obj, type) => {
    if (type === 'essence' && obj.type.indexOf('control') > -1) {
      setActiveEssence(obj)
    }
  }

  // Событие на генерирование стримов
  const doGoFlashAuto = () => {
    let newData = data
    newData.forEach(obj => {
      obj.stream = obj.stream ? obj.stream : uuidv4()
    })
    setData([...newData])
  }

  // Сохранение позиции для дерева сущностей
  const doGoPositionScroll = tempPosition => {}

  // Сохранение выбранного значения в дереве сущностей
  const doClickMetaSelect = tempSelect => {}

  const handleChangeFFTextFields = (value, id, name, type) => {
    if (type) {
      let tempAtomMeta = atomMeta
      tempAtomMeta[name] = value
      setAtomMeta(tempAtomMeta)
    }
    return ''
  }

  const doGo = row => {
    setRow(row)
    ApiConnect({
      name: 'getData',
      url:
        urlConfiguration +
        '/api/mapping/topic_stream_parameter/tree/client_object/',
      setterEssence: temp => {
        setObject(temp)
        let tempRow = JSON.parse(JSON.stringify(row))
        if (tempRow.parameter !== '') {
          setAtomMeta({
            object: tempRow['object'],
            essence: tempRow['essence'],
            parameter: tempRow['parameter']
          })
        } else {
          setAtomMeta({ ...atomMeta, parameter: '' })
        }
        setOpenAdd(true)
      },
      setterLoading: null,
      isLogin: isLogin,
      clickHandle: handleClickVariant
    })
  }

  const goCheck = () => {
    checkerTopic(data)
  }
  // Событие на выбор из иерархического выпадающего меню
  const doChangeSelect = (par, objId, obj) => {
    let tempAtomMeta = atomMeta
    if (par === 'object') {
      tempAtomMeta[par] = objId
      setEss(null)
      setParameter(null)
      tempAtomMeta['essence'] = ''
      tempAtomMeta['parameter'] = ''
      ApiConnect({
        name: 'getData',
        url:
          urlConfiguration +
          '/api/mapping/topic_stream_parameter/tree/object_children/?obj=' +
          objId,
        setterEssence: setEss,
        setterLoading: null,
        isLogin: isLogin
      })
    }
    if (par === 'essence') {
      tempAtomMeta[par] = objId
      setParameter(null)
      tempAtomMeta['parameter'] = ''
      ApiConnect({
        name: 'getData',
        url:
          urlConfiguration +
          '/api/mapping/topic_stream_parameter/tree/essence_parameters_empty/?essence=' +
          objId,
        setterEssence: setParameter,
        setterLoading: null,
        isLogin: isLogin
      })
    }
    setAtomMeta(tempAtomMeta)
  }

  // Событие на сохранение
  const doSave = () => {
    let tempData = data.map(obj => {
      if (obj.is_uniq && obj.controller_is_exist) {
        let temp = {
          topic: {
            name: obj?.topic ? obj.topic : '',
            is_numeric: obj.is_numeric,
            is_subscribe: obj?.is_subscribe ? obj?.is_subscribe : false,
            tc: obj.tc,
            controller: obj.controller
          },
          stream: {
            is_create: obj.stream ? true : false,
            is_new: true,
            id: '',
            name: obj.stream,
            description: obj?.description ? obj?.description : ''
          },
          parameter: {
            is_create:
              obj.essence !== '' && obj.parameter !== '' ? true : false,
            essence: obj.essence,
            parameter: obj.parameter
          }
        }
        return temp
      }
    })
    tempData = tempData.filter(obj => obj)
    if (tempData.length === 0) {
      handleClickVariant(
        'warning',
        `Нам нечего сохранять, топики либо отсутсвуют, либо они не все неуникальные`
      )
      return
    }
    if (data.length - tempData.length > 0) {
      handleClickVariant(
        'warning',
        ` Топики не отправлены на сохранение (кол-во топиков - ${data.length -
          tempData.length})`
      )
    }
    ApiConnect({
      name: 'createData',
      url:
        urlConfiguration + '/api/mapping/topic_stream_parameter/create/list/',
      objToCreate: tempData,
      isLogin: isLogin,
      clickHandle: handleClickVariant
    })
  }

  return (
    <Context.Provider
      value={{
        doClickActiveEssence,
        doGoPositionScroll,
        doClickMetaSelect,
        handleChangeFFTextFields
      }}
    >
      <Dialog
        open={openAdd}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        maxWidth={'xs'}
      >
        <DialogTitle id='alert-dialog-title'>
          {'Привязка параметра к стриму'}
        </DialogTitle>
        <DialogContent>
          <Grid container xs={12}>
            <Context.Provider value={{ doChangeSelect }}>
              <Grid item xs={12}>
                <FFSelectTree
                  stateMeta={{ open: false, active: -1, label: '' }}
                  idCls={'object'}
                  name={'object'}
                  selectValue={atomMeta['object']}
                  selectMetaLabel={'Объект'}
                  selectNodes={object ? object : []}
                />
              </Grid>
              <Grid
                item
                xs={12}
                key={
                  (ess?.children ? ess.children : []).length +
                  'FFSelectTreeEssence'
                }
              >
                <FFSelectTree
                  stateMeta={{ open: false, active: -1, label: '' }}
                  idCls={'essence'}
                  name={'essence'}
                  selectValue={atomMeta['essence']}
                  selectMetaLabel={'Сущности'}
                  selectNodes={ess?.children ? ess.children : []}
                />
              </Grid>
            </Context.Provider>

            <Grid
              item
              xs={12}
              key={
                (parameter ? parameter : []).length + 'FFSelectTreeParameter'
              }
            >
              <FFSelect
                value={atomMeta['parameter']}
                label={'Параметры'}
                options={parameter ? parameter : []}
                name={'parameter'}
                id={'parameter'}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={e => {
              let tempData = data
              if (
                !(
                  atomMeta.parameter !== '' &&
                  atomMeta.object !== '' &&
                  atomMeta.essence !== ''
                )
              ) {
                handleClickVariant('warning', 'Заполните все поля')
                return
              }
              atomMeta.linkToParameter =
                treeUtils.getNodeByKey(object, atomMeta.object).label +
                '/' +
                treeUtils.getNodeByKey(ess.children, atomMeta.essence).label +
                '/' +
                treeUtils.getNodeByKey(parameter, atomMeta.parameter).label
              tempData[row.tableData.id] = { ...row, ...atomMeta }
              setData(tempData)
              setTe(te + 1)
              setOpenAdd(false)
            }}
            color='primary'
            autoFocus
          >
            {'Применить'}
          </Button>
          <Button onClick={e => setOpenAdd(false)} color='primary'>
            {'Отменить'}
          </Button>
        </DialogActions>
      </Dialog>
      {/*
      <Drawer anchor={'left'} open={openTree} onClose={e => setOpenTree(false)}>
        <Paper elevation={3} id={'bodyTree_paper'} className={classes.drawer}>
          <FFLoaderComponent
            key={'treeEssence'}
            loading={essenceLoading}
            isLoader={<Loader />}
            component={
              <FFTreeEssence
                essence={essence}
                type={'essence'}
                doubleClick={1}
                activeList={activeEssence ? [activeEssence] : []}
                active={activeEssence?.id}
              />
            }
          />
        </Paper>
      </Drawer> */}

      <Grid container>
        {/* <Grid item xs={8} align='left'>
          <Tooltip title='Отобразить дерево'>
            <IconButton
              aria-label='filter'
              onClick={e => setOpenTree(true)}
              className={classes.margin}
            >
              <IconAccountTree color='primary' fontSize='large' />
            </IconButton>
          </Tooltip>
          {activeEssence ? (
            <Chip
              color={'default'}
              label={activeEssence.label}
              onDelete={e => setActiveEssence(null)}
            />
          ) : null}
        </Grid> */}
        <Grid item xs={12} align='right'>
          {true ? (
            <Tooltip title='Массовое редактирование типиков'>
              <IconButton
                aria-label='filter'
                onClick={e => props.toGoBack()}
                className={classes.margin}
              >
                <IconDynamicFeed color='primary' fontSize='large' />
              </IconButton>
            </Tooltip>
          ) : null}
          {data.length ? (
            <Tooltip title='Сгенирировать стримы'>
              <IconButton
                aria-label='filter'
                onClick={e => doGoFlashAuto()}
                className={classes.margin}
              >
                <FlashAutoIcon color='primary' fontSize='large' />
              </IconButton>
            </Tooltip>
          ) : null}
          {data.length ? (
            <Tooltip title='Проверка на уникальность'>
              <IconButton
                aria-label='filter'
                onClick={e => goCheck()}
                className={classes.margin}
              >
                <CompareIcon color='primary' fontSize='large' />
              </IconButton>
            </Tooltip>
          ) : null}
          {data.length ? (
            <Tooltip title='Сохранить'>
              <IconButton
                aria-label='filter'
                onClick={e => doSave()}
                className={classes.margin}
              >
                <IconSave color='primary' fontSize='large' />
              </IconButton>
            </Tooltip>
          ) : null}
        </Grid>
      </Grid>
      <FFContainerColumn
        components={[
          {
            width: 12,
            component: (
              <FFLoaderComponent
                key={'Panels&Active'}
                loading={true || activeEssence?.id}
                isLoader={
                  <Typography
                    variant='h1'
                    component='h2'
                    style={{ color: 'lightgray', marginTop: '20%' }}
                  >
                    Выберите контроллер (массовое заполнение)
                  </Typography>
                }
                component={
                  <Grid item xs={12} style={{ overflow: 'overlay' }}>
                    <Grid item xs={12} style={{ margin: defTheme.spacing(1) }}>
                      <Paper
                        variant='outlined'
                        style={{ padding: defTheme.spacing(1) }}
                      >
                        <Grid container item xs={12}>
                          <Typography
                            variant='h7'
                            component='h7'
                            style={{
                              color: defTheme.palette.grey[900],
                              marginRight: defTheme.spacing(1)
                            }}
                          >
                            Выберите файл для загрузки
                          </Typography>
                          <CSVReader
                            cssClass='react-csv-input'
                            label=''
                            style={{ margin: defTheme.spacing(1) }}
                            onFileLoaded={handleForce}
                            parserOptions={papaparseOptions}
                          />
                        </Grid>
                      </Paper>
                    </Grid>
                    <Grid item xs={12} style={{ margin: defTheme.spacing(1) }}>
                      <FFLoaderComponent
                        loading={stream}
                        isLoader={<Loader />}
                        component={
                          <FFMaterialTable
                            key={`${te}_Table`}
                            title={'Пакетное редактирование топиков и стримов'}
                            data={data}
                            setData={setData}
                            doGo={row => doGo(row)}
                          />
                        }
                      />
                    </Grid>
                  </Grid>
                }
              />
            )
          }
        ]}
      />
    </Context.Provider>
  )
}
