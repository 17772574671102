import React, { useEffect, useState, useContext } from 'react'

import Context from '../../../../context'

import { Grid, Typography } from '@material-ui/core'

import FFRouterComponents from '../../../../components/FFRouterComponents'

import {v4 as uuidv4} from 'uuid'

export default function FF_Classifier_step3_formatData_valueRange (props) {
  // -------------------------------------------------------------------------------
  //
  //                                   Стили
  //
  // -------------------------------------------------------------------------------

  // -------------------------------------------------------------------------------
  //
  //                                 Мета описание
  //
  // ---------------------------- ---------------------------------------------------

  let metaTable = [
    {
      widthColumn: 6,
      data: [
        {
          type: 'ffListTextFields',
          style: {},
          name_attr: ['key', 'label', 'value'],
          label_attr: ['Идентификатор', 'Наименование', 'Значение']
        }
      ]
    }
  ]

  const labelSide = { left: 'Ограничение слева', right: 'Ограничение справа' }
  // -------------------------------------------------------------------------------
  //
  //                                 Состояние
  //
  // ---------------------------- ---------------------------------------------------

  const { pFormatDataValueList } = useContext(Context)
  const [valueRange, setValueRange] = useState({})

  // -------------------------------------------------------------------------------
  //
  //                                 Обработчики событий
  //
  // -------------------------------------------------------------------------------

  useEffect(() => {
    let temp = {
      id: uuidv4(),
      step: 1,
      left: { id: uuidv4(), value: '', label: '', key: '' },
      right: { id: uuidv4(), value: '', label: '', key: '' }
    }
    if (
      props.value &&
      props.value.value &&
      'left' in props.value.value &&
      'right' in props.value.value
    ) {
      temp.step = props.value.value.step
      temp.left.key = props.value.value.left.id
      temp.right.key = props.value.value.right.id
      temp.left.label = props.value.value.left.label
      temp.right.label = props.value.value.right.label
      temp.left.value = props.value.value.left.value
      temp.right.value = props.value.value.right.value
    }
    setValueRange(temp)
  }, [])

  const handleChangeFFTextFields = (value, parameter_id, name, element) => {
    if (element) {
      let temp = valueRange
      if (
        isNaN(Number(value)) ||
        ((name === 'value' || name === 'step') &&
          ((temp.id === parameter_id && Number(value) <= 0) ||
            (temp.left.id === parameter_id && isNaN(Number(value))) ||
            (temp.right.id === parameter_id && isNaN(Number(value)))))
      ) {
        pFormatDataValueList(
          props.nameAttr,
          {
            step: temp.step,
            left: {
              id: temp.left.key,
              label: temp.left.label,
              value: temp.left.value
            },
            right: {
              id: temp.right.key,
              label: temp.right.label,
              value: temp.right.value
            }
          },
          'Введите число'
        )
        return 'Введите число'
      }
      if (temp.id === parameter_id) {
        temp[name] = Number(value)
      } else if (temp.left.id === parameter_id) {
        temp.left[name] = Number(value)
      } else if (temp.right.id === parameter_id) {
        temp.right[name] = Number(value)
      }
      pFormatDataValueList(
        props.nameAttr,
        {
          step: temp.step,
          left: {
            id: temp.left.key,
            label: temp.left.label,
            value: temp.left.value
          },
          right: {
            id: temp.right.key,
            label: temp.right.label,
            value: temp.right.value
          }
        },
        ''
      )
    }
    return ''
  }

  return (
    <Context.Provider value={{ handleChangeFFTextFields }}>
      {Object.keys(valueRange).length ? (
        <Grid
          container
          item
          xs={12}
          spacing={1}
          key={'GridContainer' + props.id}
        >
          <Grid item xs={12} spacing={1}>
            <FFRouterComponents
              style={{ margin: 10 }}
              parameter={valueRange}
              meta_cell={{
                type: 'ffListTextFields',
                style: {},
                name_attr: ['step'],
                label_attr: ['Шаг']
              }}
            />
          </Grid>
          {['left', 'right'].map(nameSide => {
            return metaTable.map(metaColumn => {
              return (
                <Grid item xs={metaColumn.widthColumn}>
                  <Typography>{labelSide[nameSide]}</Typography>
                  {metaColumn.data.map(metaCell => {
                    return (
                      <FFRouterComponents
                        style={{ margin: 10 }}
                        parameter={valueRange[nameSide]}
                        meta_cell={metaCell}
                      />
                    )
                  })}
                </Grid>
              )
            })
          })}
        </Grid>
      ) : null}
    </Context.Provider>
  )
}
