import React, { useEffect } from "react";
import moment from "moment";
import { isNil } from "ramda";
import { notification } from "antd";
import { useDispatch } from "react-redux";
import {
  getScada,
  setShowAlert,
} from "../../../mapping-essence-to-essence/slice";
import { useSelector } from "react-redux";

const KEY_NOTIFICATION_STATUS = "notification/status";

export const openStatusNotification = (status, error) => {
  if (error) {
    notification.error({
      key: KEY_NOTIFICATION_STATUS,
      message: "Статус",
      description: "Ошибка синхронизации",
    });
    return;
  }

  const {
    last_success: lastSuccess,
    last_error: lastError,
    is_running: isRunning,
  } = status ?? {};
  const message = isRunning
    ? "Выполняется синхронизация"
    : "Синхронизация сейчас не выполняется";

  const description = {};
  if (!isNil(lastSuccess)) {
    description.success = `Последняя синхронизация: ${moment(
      lastSuccess
    ).format("YYYY-MM-DD HH:mm")}`;
  }

  if (!isNil(lastError)) {
    description.error = `Последняя неуспешная попытка синхронизации: ${moment(
      lastError
    ).format("YYYY-MM-DD HH:mm")}`;
  }

  let descriptionComponent = null;
  if (description?.error && description?.success) {
    descriptionComponent = (
      <div>
        {description.success}
        <br />
        {description.error}
      </div>
    );
  } else if (description.error) {
    descriptionComponent = description.error;
  } else if (description.success) {
    descriptionComponent = description.success;
  }

  notification.info({
    key: KEY_NOTIFICATION_STATUS,
    duration: 3,
    message,
    description: descriptionComponent,
    onClose: () => notification.destroy(),
  });
};
