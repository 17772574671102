import React from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core";
import { Spin } from "antd";
import { treeSelector } from "../../slice";
import { TreeComponent } from "./tree";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

export const Tree = () => {
  const { status, data, open } = useSelector(treeSelector);
  const styles = useStyles();

  if (status === "fulfilled" && data?.length) {
    return <TreeComponent data={data} />;
  }

  return (
    <div className={styles.root} style={{ display: open ? "" : "none" }}>
      <Spin size="large" tip="Загружаем дерево ..." />
    </div>
  );
};
