import React, { useRef } from "react";
import { useSelector } from "react-redux";
import { Typography, Spin } from "antd";
import { makeStyles } from "@material-ui/core";
import classNames from "classnames";
import { getScada } from "../../../mapping-essence-to-dc/slice";
import { Table } from "./table";

export const useStyles = makeStyles((theme) => ({
  helperText: {
    color: theme.palette.divider,
    fontWeight: "bold",
    alignSelf: "center",
    textAlign: "center",
  },
  container: {
    position: "relative",
    display: "flex",
    flexFlow: "nowrap column",
    overflow: "hidden",
    flexGrow: 1,
    alignItems: "stretch",
    justifyContent: "stretch",
    margin: theme.spacing(1),
  },
  body: {
    display: "grid",
    flexGrow: 1,
    padding: 8,
    overflow: "hidden",
  },
  center: {
    alignItems: "center",
    justifyContent: "center",
  },
}));

export const DCTable = () => {
  const classes = useStyles();

  const { essences, statusEssences, data: init } = useSelector(getScada);
  const data = useRef(JSON.parse(JSON.stringify(init.length ? init : [[null, null]])));

  if (statusEssences === "pending") {
    return (
      <div className={classNames(classes.container, classes.center)}>
        <Spin size="large" tip="Загружаем сущности ..." />
      </div>
    );
  }

  if (!essences) {
    return (
      <div className={classNames(classes.container, classes.center)}>
        <Typography.Title level={1} strong style={{ color: "lightgray" }}>
          Нет доступных сущностей
        </Typography.Title>
      </div>
    );
  }

  return (
    <div
      style={{
        height: "100%",
        display: "grid",
        gridTemplateRows: "60px 1fr",
        paddingBottom: 20,
      }}
    >
      <Table essences={essences} data={data} isEssenceToDc={true} />
    </div>
  );
};
