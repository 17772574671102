import React, { useEffect, useState, useContext } from 'react'
import Context from '../../../../context'

import FFTextFields from '../../../../components/FFTextFields'

import {Grid} from '@material-ui/core'

import IconButton from '@material-ui/core/IconButton'
import IconDelete from '@material-ui/icons/Delete'


import FF_Classifier_step3_formatData_valueList from './FF_Classifier_step3_formatData_valueList'
import FF_Classifier_step3_formatData_valueRange from './FF_Classifier_step3_formatData_valueRange'
import FF_Classifier_step3_formatData_intervalRange from './FF_Classifier_step3_formatData_intervalRange'

import {v4 as uuidv4} from 'uuid'

export default function FF_Classifier_step3_formatData_list (props) {
  // -------------------------------------------------------------------------------
  //
  //                                   Стили
  //
  // -------------------------------------------------------------------------------

  // -------------------------------------------------------------------------------
  //
  //                                 Мета описание
  //
  // ---------------------------- ---------------------------------------------------

  // -------------------------------------------------------------------------------
  //
  //                                 Состояние
  //
  // ---------------------------- ---------------------------------------------------

  const { pFormatParent } = useContext(Context)
  const [te, setTe] = useState(0)
  const [value, setValue] = useState({})

  // -------------------------------------------------------------------------------
  //
  //                                 Обработчики событий
  //
  // -------------------------------------------------------------------------------

  useEffect(() => {
    let temp = props.value.value
    if (!props.value.value) {
      temp = {}
      temp.length = ''
      temp.value = null
      temp['p-format-data'] = ''
    }
    temp.id = uuidv4()
    setTe(te + 1)
    setValue(temp)
  }, [])

  const changeDefaultValue = event => {
    let temp = value
    temp['p-format-data'] = event.target.value
    setValue(temp)
    setTe(te + 1)
  }

  const handleChangeFFTextFields = (value2, parameter_id, name, element) => {
    if (element) {
      let temp = value
      if (isNaN(Number(value2)) && name === 'length') {
        pFormatParent('p-format-data-list', value, 'Введите число')
        return 'Введите число'
      }
      if (name === 'length'){
        value2 = Number(value2)
      }
      temp[name] = value2
      setValue(temp)
      pFormatParent('p-format-data-list', value, 'length')
    }
    return ''
  }

  const pFormatDataValueList = (nameAttr, data) => {
    let temp = value
    temp['value'] = data
    setValue(temp)
    pFormatParent('p-format-data-list', temp)
  }

  const routerComponent = format => {
    console.log(format)
    if (format === 'p-format-data-value-list') {
      return (
        <React.Fragment>
          <FF_Classifier_step3_formatData_valueList
            key={'FF_Classifier_step3_formatData_valueList' + props.id}
            value={JSON.parse(JSON.stringify(props.value.value))}
            nameAttr={'p-format-data-value-list'}
          />
        </React.Fragment>
      )
    }
    else if (format === 'p-format-data-value-range') {
      return (
        <React.Fragment>
          <FF_Classifier_step3_formatData_valueRange
            value={JSON.parse(JSON.stringify(props.value.value))}
            nameAttr={'p-format-data-value-range'}
          />
        </React.Fragment>
      )
    }
    else if (format === 'p-format-data-interval-range') {
      return (
        <React.Fragment>
          <FF_Classifier_step3_formatData_intervalRange
            value={JSON.parse(JSON.stringify(props.value.value))}
            nameAttr={'p-format-data-interval-range'}
          />
        </React.Fragment>
      )
    }
    return null
  }

  return value ? (
    <Context.Provider
      value={{ handleChangeFFTextFields, pFormatDataValueList }}
    >
      <React.Fragment>
        <Grid container item xs={12} spacing={1}>
          <Grid item xs={12}>
            <FFTextFields
              style={{ width: '100%' }}
              key={'FFTextFields_length' + te}
              value={value['length']}
              is_filter={false}
              name={'length'}
              label={'Количество элементов'}
              parameter={value}
              dis={false}
            />
          </Grid>
          <Grid item xs={6} spacing={1}>
            {/* <FormControl variant='filled' style={{ margin: 3, width: '100%' }}>
              <InputLabel id='FFParametersNew_InputLabel_select_formatList'>
                Формат элементов списка
              </InputLabel>
              <Select
                key={'select' + value['p-format-data']}
                value={value['p-format-data']}
                onChange={changeDefaultValue}
              >
                {props.classifier.children.map(objChild => {
                  if (objChild.label.indexOf('Значение (') > -1) {
                    return (
                      <MenuItem value={objChild.name}>
                        {objChild.label}
                      </MenuItem>
                    )
                  }
                })}
              </Select> */}
            {/* </FormControl> */}
          </Grid>
        </Grid>
        <React.Fragment key={value['p-format-data']}>
          {routerComponent(value['p-format-data'])}
        </React.Fragment>
      </React.Fragment>
    </Context.Provider>
  ) : null
}
