import React, { useEffect, useState, useContext } from 'react'
// import ReactDOM from 'react-dom'
// import { Redirect } from 'react-router-dom'
import clsx from 'clsx'

// import { makeStyles } from "@material-ui/core/styles";
// import React from 'react';
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
// import Loader from './Loader'
import Context from './context'
import FFSwitch_N from './components/FFSwitch_N'

// import FFTreeEssence from './App2'

import FFLoaderComponent from './components/FFLoaderComponent'

import FFTransportList from './components/FFTransportList'
import Divider from '@material-ui/core/Divider'
import Tooltip from '@material-ui/core/Tooltip'
// import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
// import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
// import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Select from '@material-ui/core/Select'
import { Switch, Checkbox, Drawer } from '@material-ui/core'

// import Input from '@material-ui/core/Input'

// import Table from '@material-ui/core/Table'
// import TableBody from '@material-ui/core/TableBody'
// import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
// import TableHead from '@material-ui/core/TableHead'
// import TableRow from '@material-ui/core/TableRow'
import Badge from '@material-ui/core/Badge'
// import Avatar from '@material-ui/core/Avatar'
// import Chip from '@material-ui/core/Chip'
// import Divider from '@material-ui/core/Divider'
// import TablePagination from '@material-ui/core/TablePagination'
// import FFPaginationAction from './components/FFPaginationAction'
import { SnackbarProvider, useSnackbar } from 'notistack'
// import FFListSwitch from './components/FFListSwitch';
// import FFSelect from './components/FFSelect'
// import FFTextFields from './components/FFTextFields'
// import FFSelectMulti from './components/FFSelectMulti'
import FFRouterComponents from './components/FFRouterComponents'
// import FFTextFields from './components/FFTextFields'
// import FFSelect from './components/FFSelect'

// import FFCheckList from './components/FFCheckList'
// import InputAdornment from '@material-ui/core/InputAdornment'
// import FFPanelMenu from './components/FFPanelMenu'
// import FFPersistentDrawerRight from './components/FFPersistentDrawerRight'
import Grid from '@material-ui/core/Grid'
// import TextField from '@material-ui/core/TextField'
// import MoreVertIcon from '@material-ui/icons/MoreVert'
// import Drawer from '@material-ui/core/Drawer'
import FFTreeEssence from './components/FFTreeEssence'
// import Button from '@material-ui/core/Button';
// import List from '@material-ui/core/List'
import Card from '@material-ui/core/Card'
// import FFTester from './FFTester'
// import Divider from '@material-ui/core/Divider';
// import ListItem from '@material-ui/core/ListItem';
// import ListItemIcon from '@material-ui/core/ListItemIcon';
// import ListItemText from '@material-ui/core/ListItemText';
// import InboxIcon from '@material-ui/icons/MoveToInbox';
// import MailIcon from '@material-ui/icons/Mail';
import IconButton from '@material-ui/core/IconButton'

import IconSettings from '@material-ui/icons/Settings'

import IconDelete from '@material-ui/icons/Delete'
// import IconFilter from '@material-ui/icons/FilterList'
import IconSearch from '@material-ui/icons/Search'
import IconLink from '@material-ui/icons/Link'
import IconLinkOff from '@material-ui/icons/LinkOff'

import IconLibraryAdd from '@material-ui/icons/LibraryAdd'
import IconAdd from '@material-ui/icons/Add'
import IconSave from '@material-ui/icons/Save'
import IconRefresh from '@material-ui/icons/Refresh'
import IconSystemUpdateAlt from '@material-ui/icons/SystemUpdateAlt'
// import IconBuild from '@material-ui/icons/Build'
// import IconReplay from '@material-ui/icons/Replay'
// import IconCancel from '@material-ui/icons/Cancel'
// import IconUpdate from '@material-ui/icons/Update'

// import FFFilterLabel from './components/FFFilterLabel'
// import FFToggle from './components/FFToggle'

// import CardHeader from '@material-ui/core/CardHeader'
// import TreeItem from '@material-ui/lab/TreeItem'
// import TreeView from '@material-ui/lab/TreeView'
// import Essence from './essence_action_info.json'
// import CancelIcon from '@material-ui/icons/Cancel';
// import Context from './context'
// import FileCls from './cls.json'
// import FileParameter from './parameter.json'
import { Button } from '@material-ui/core'
// import SwipeableViews from 'react-swipeable-views';
// import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
// import ArrowRightIcon from '@material-ui/icons/ArrowRight'
// import Remove from '@material-ui/icons/Remove'
// import FileBaseLine from './fileSrc/scenarios_model.json'

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Slide from '@material-ui/core/Slide'

import { withStyles } from '@material-ui/core/styles'

import { green } from '@material-ui/core/colors'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import CheckCircle from '@material-ui/icons/CheckCircle'
import DoneOutline from '@material-ui/icons/DoneOutline'
// import FFPanelMenu from "./components/FFPanelMenu";

import CardHeader from '@material-ui/core/CardHeader'
import CardMedia from '@material-ui/core/CardMedia'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import Collapse from '@material-ui/core/Collapse'
import Avatar from '@material-ui/core/Avatar'

// import SortableTree from 'react-sortable-tree/dist/index.esm.js'
// import 'react-sortable-tree/style.css'

import TextField from '@material-ui/core/TextField'

import cookie from 'react-cookies'
import Loader from './Loader'
import { Link } from 'react-router-dom'
import { ApiConnect, urlConfiguration } from './ApiConnectNew'
import FFDialogDelete from './libft/FFDialogDelete'

import {v4 as uuidv4} from 'uuid'

const useStyles = makeStyles({
  margin: {
    marginTop: '10px',
    marginLeft: '10px',
    marginBottom: '5px'
  },
  padding: {
    // paddingLeft: 20,
    paddingRight: 10
  },
  textField: {
    marginLeft: 1,
    marginRight: 1,
    width: '100%'
  },
  formControl: {
    minWidth: 250
  },
  formControl2: {
    minWidth: 120,
    maxWidth: 300
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  chip: {
    margin: 2
  },
  flex: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
    height: '100%'
  },
  end: {
    flexGrow: 3
  },
  tree: {
    padding: 8,
    margin: 8
  },
  flexEnd: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    flexFlow: 3
  },
  scroller: {
    // maxHeight: '-webkit-fill-available',
    overflow: 'overlay'
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)'
  }
})

export default function FF_Model () {
  // -------------------------------------------------------------------------------
  //
  //                                   Стили
  //
  // -------------------------------------------------------------------------------

  const classes = useStyles()

  // -------------------------------------------------------------------------------
  //
  //                                 Состояния
  //
  // -------------------------------------------------------------------------------

  const metaEssence = {
    label: {
      label: 'Наименование',
      is_unique: true,
      is_required: true,
      is_type: 'string'
    },
    name: {
      label: 'Системное наименование',
      is_unique: true,
      is_required: true,
      is_type: 'string'
    },
    description: {
      label: 'Описание',
      is_unique: false,
      is_required: false,
      is_type: 'string'
    },
    label_eng: {
      label: 'Наименование на латинском',
      is_unique: true,
      is_required: false,
      is_type: 'string'
    },
    lib: {
      label: 'Библиотека на Си',
      is_unique: true,
      is_required: false,
      is_type: 'string'
    },
    func: {
      label: 'Функция на Си',
      is_unique: false,
      is_required: false,
      is_type: 'string'
    }
  }

  const strAuthorization = 'Bearer ' + cookie.load('access_token')
  const { isLogin } = useContext(Context)
  const [loading, setLoading] = useState(true)
  const [essence, setEssence] = useState([])
  const [essenceLoading, setEssenceLoading] = useState(false)
  const [activeEssence, setActiveEssence] = useState()
  const [children, setChildren] = useState({})
  const [isEdit, setIsEdit] = useState(0)
  const [te, setTe] = useState(0)
  const [classifier, setClassifier] = useState([])
  const [parameter, setParameter] = useState([])
  const [doGoLink, setDoGoLink] = useState(false)
  const [linkModel, setLinkModel] = useState({})
  const [currentModel, setCurrentModel] = useState(0)
  const [dialogToCreate, setDialogToCreate] = useState(false)
  const [tempModel, setTempModel] = useState('')
  const [dialogToUpdate, setDialogToUpdate] = useState(false)
  const [listByUpdate, setListByUpdate] = useState([])
  const nameDialog = ['id', 'name', 'label']
  const [numAttr, setNumAttr] = useState(0)
  const [isOpenDialogDelete, setIsOpenDialogDelete] = useState(false)
  useEffect(() => {
    getData(
      '/api/config/ontological_classifier/tree/?is_level=True',
      setClassifier,
      null
    )
    getData('/api/?entity=parameter_registry', setParameter, null)
    doRefresh()
  }, [])

  // -------------------------------------------------------------------------------
  //
  //                                 Запросы в Бэк
  //
  // -------------------------------------------------------------------------------

  const getData = (url, setterEssence, setterLoading) => {
    fetch(urlConfiguration + url, {
      method: 'get',
      headers: new Headers({
        Authorization: strAuthorization,
        'Content-Type': 'application/json'
      })
    })
      .then(response => {
        if (response.status === 401) {
          isLogin(false)
        }
        return response.json()
      })
      .then(temp => {
        if (setterEssence === setActiveEssence) temp = temp[0]
        if (setterEssence === setClassifier) temp = temp.children
        setterEssence(temp)
        setTe(uuidv4())
        if (setterLoading) {
          setterLoading(true)
        }
      })
  }

  const createData = (url, objToCreate) => {
    fetch(urlConfiguration + url, {
      method: 'post',
      body: JSON.stringify(objToCreate),
      headers: new Headers({
        Authorization: strAuthorization,
        'Content-Type': 'application/json'
      })
    })
      .then(response => {
        if (response.status === 401) {
          isLogin(false)
        }
        return response.json()
      })
      .then(temp => {
        handleClickVariant(
          temp['msg'].indexOf('спешно') !== -1 ? 'success' : 'error',
          temp['msg']
        )
      })
  }

  const deleteDate = (url, objToDelete) => {
    fetch(urlConfiguration + url, {
      method: 'delete',
      body: JSON.stringify(objToDelete),
      headers: new Headers({
        Authorization: strAuthorization,
        'Content-Type': 'application/json'
      })
    })
      .then(response => {
        if (response.status === 401) {
          isLogin(false)
        }
        return response.json()
      })
      .then(temp => {
        handleClickVariant(
          temp['msg'].indexOf('спешно') !== -1 ? 'success' : 'error',
          temp['msg']
        )
      })
  }

  const updateDate = (url, objToCreate) => {
    fetch(urlConfiguration + url, {
      method: 'put',
      body: JSON.stringify(objToCreate),
      headers: new Headers({
        Authorization: strAuthorization,
        'Content-Type': 'application/json'
      })
    })
      .then(response => {
        if (response.status === 401) {
          isLogin(false)
        }
        return response.json()
      })
      .then(temp => {
        handleClickVariant(
          temp['msg'].indexOf('спешно') !== -1 ? 'success' : 'error',
          temp['msg']
        )
        if (
          url.indexOf('/api/model_interface/update') > -1 &&
          'obj' in temp &&
          temp['obj'].length
        ) {
          setListByUpdate(temp['obj'])
          setDialogToUpdate(true)
        }
      })
  }

  const { enqueueSnackbar } = useSnackbar()
  const handleClickVariant = (variant, msg) => {
    // variant could be success, error, warning, info, or default
    enqueueSnackbar(
      msg,
      { variant },
      {
        anchorOrigin: { vertical: 'top', horizontal: 'right' }
      }
    )
  }
  // -------------------------------------------------------------------------------
  //
  //                                 Обработчики событий
  //
  // -------------------------------------------------------------------------------

  // Нажатие на классификационное значение в меню
  const doClickActiveEssence = (objId, obj) => {
    if (!doGoLink) {
      if (essence.id !== objId) {
        setChildren(obj)
        getData('/api/?entity=model&id=' + objId, setActiveEssence, null)
      } else {
        setActiveEssence(null)
      }
    } else {
      setActiveEssence(null)
      setLinkModel(null)
      setCurrentModel(0)
      if (essence.id !== objId) {
        getData('/api/?entity=essence&id=' + objId, setActiveEssence, null)
        getData('/api/model_interface/?essence=' + objId, setLinkModel, null)
      }
    }
  }

  // Обработчик на кнопку добавления
  const doAddEssence = () => {
    // console.log(activeEssence)
    if (!doGoLink) {
      let tempId = uuidv4()
      let temp = {
        id: tempId,
        name: ' Model_' + tempId.split('-')[0],
        label: 'Model_' + tempId.split('-')[0],
        label_eng: 'Model_' + tempId.split('-')[0],
        description: 'Автоматически Созданная модель',
        active_parameters: [],
        is_deleted: false,
        lib: 'temp',
        func: 'temp'
      }
      createData('/api/?entity=model', temp)
    } else {
      if (linkModel && linkModel['allowed'] && linkModel['allowed'].length) {
        setDialogToCreate(true)
      } else {
        handleClickVariant('warning', 'Доступных моделей нет')
      }
    }
    doRefresh(doGoLink)
  }

  // Обработчик для копирования
  const doAddCopyEssence = () => {
    if (activeEssence) {
      let temp = JSON.parse(JSON.stringify(activeEssence))
      let tempId = uuidv4()
      temp.id = tempId
      temp.name = 'name' + '_' + tempId.split('-')[0]
      temp.label = '0_New_label' + '_' + tempId.split('-')[0]
      temp.label_eng = 'label_eng' + '_' + tempId.split('-')[0]

      createData('/api/?entity=model', temp)
      doRefresh()
    } else {
      handleClickVariant('warning', 'Выберите копируемое значение')
    }
  }

  // Обработчик для обновления
  const doRefresh = (toGo = false) => {
    setTimeout(() => {
      if (!toGo) {
        getData(
          '/api/?entity=model&is_full=True',
          setEssence,
          setEssenceLoading
        )
        // setTe(te + 1)
      } else {
        getData(
          '/api/support/info/?entity=essence&action=select&info=tree',
          setEssence,
          setEssenceLoading
        )
      }
    }, 0)
  }

  // Обработчик для удаления
  const doDeleteEssence = () => {
    if (activeEssence && !doGoLink) {
      deleteDate('/api/?entity=model&id=' + activeEssence.id, {
        id: activeEssence.id
      })
      setActiveEssence(null)
    } else if (activeEssence && doGoLink) {
      deleteDate(
        '/api/model_interface/?essence=' +
          activeEssence.id +
          '&model=' +
          linkModel['connections'][currentModel].id,
        null
      )
      setTimeout(() => {
        getData(
          '/api/model_interface/?essence=' + activeEssence.id,
          setLinkModel,
          null
        )
      }, 100)
    }
    doRefresh(doGoLink)
  }

  const doSaveEssence = () => {
    if (!doGoLink) {
      updateDate('/api/?entity=model', activeEssence)
    } else {
      updateDate(
        '/api/model_interface/?essence=' +
          activeEssence.id +
          '&model=' +
          linkModel['connections'][currentModel].id,
        linkModel['connections'][currentModel]
      )

      // console.log(linkModel['connections'][currentModel])
    }
    doRefresh(doGoLink)
  }

  // Обновление параметров по шаблону
  const doUpdateParameter = () => {
    updateDate(
      '/api/config/template/list_essence_update/?template=' + activeEssence.id,
      null
    )
  }

  // Обработчик для текстовых полей
  const changeTextField = (value, parameter_id, name, element) => {
    let temp = activeEssence
    temp[name] = value
    setActiveEssence(temp)
    return ''
  }

  // Переход в привязку
  const clickDoGoLink = () => {
    setActiveEssence(null)
    setLinkModel(null)
    doRefresh(!doGoLink)
    setDoGoLink(!doGoLink)
  }

  const closeAgreeParentDialog = () => {
    // console.log({...linkModel['allowed'].filter(obj => obj.id === tempModel)[0], is_active: true})
    createData(
      '/api/model_interface/?essence=' +
        activeEssence.id +
        '&model=' +
        tempModel,
      {
        ...linkModel['allowed'].filter(obj => obj.id === tempModel)[0],
        is_active: true
      }
    )
    setTempModel('')
    setTimeout(() => {
      getData(
        '/api/model_interface/?essence=' + activeEssence.id,
        setLinkModel,
        null
      )
    }, 100)
    setDialogToCreate(false)
  }

  const doUpdateModel = () => {
    setListByUpdate([])
    setDialogToUpdate(false)
    updateDate('/api/model_interface/update/?model=' + activeEssence.id, null)
  }

  const handleChangeFFTextFields = (value, id, name, element) => {
    if (element) {
      setNumAttr(value)
    }
  }
  // useEffect(() => {
  //   console.log(tempModel, 'ddd')
  //   setTempModel('')
  // }, dialogToCreate)
  // -------------------------------------------------------------------------------
  //
  //                                    Рендеринг
  //
  // -------------------------------------------------------------------------------

  return (
    <Context.Provider
      value={{
        doClickActiveEssence,
        changeTextField
      }}
    >
      {isOpenDialogDelete && (
        <FFDialogDelete
          open={isOpenDialogDelete}
          setOpen={setIsOpenDialogDelete}
          isAgree={e => doDeleteEssence()}
        />
      )}

      {dialogToUpdate && listByUpdate.length > 0 ? (
        <Dialog
          open={dialogToUpdate}
          onClose={e => {
            setDialogToUpdate(false)
            setListByUpdate([])
          }}
          // aria-labelledby='dialogUpdateModel'
          // aria-describedby='dialogUpdateModel'
        >
          {/* <DialogTitle id='dialogUpdateModel'>
            {'Список обновленных сущностей'}
          </DialogTitle> */}
          <DialogContent>
            <Grid item xs={12}>
              <Card>
                <CardHeader
                  avatar={
                    <Avatar
                      aria-label='recipe'
                      className={classes.avatar}
                      style={{ background: '#3f51b5' }}
                    >
                      {listByUpdate.length}
                    </Avatar>
                  }
                  title={'Список обновленных сущностей'}
                  subheader={'Атрибут: ' + nameDialog[numAttr]}
                />

                <CardContent>
                  <Grid container item xs={12}>
                    <Grid item xs={12}>
                      <Context.Provider value={{ handleChangeFFTextFields }}>
                        <FFSwitch_N
                          id={'boolChildtoCopy'}
                          meta={{
                            label: [
                              'UUID',
                              'Сист. наименование',
                              'Наименование'
                            ],
                            width: '170',
                            value: numAttr
                          }}
                        />
                      </Context.Provider>
                    </Grid>
                    {listByUpdate.map(obj => {
                      return (
                        <Grid item xs={12}>
                          <Typography>{obj[nameDialog[numAttr]]}</Typography>
                        </Grid>
                      )
                    })}
                  </Grid>
                </CardContent>
              </Card>
            </Grid>

            {/* <DialogContentText>
              <Grid container item xs={12}>
                  <Grid item xs={12}>
                    <Typography>Наименование сущностей</Typography>
                    <Drawer />
                  </Grid>
                  {listByUpdate.map(obj => {
                    return (
                      <Grid item xs={12}>
                        <Typography>{obj.label}</Typography>
                      </Grid>
                    )
                  })}
                </Grid>
            </DialogContentText> */}
          </DialogContent>
          <DialogActions>
            <Button
              onClick={e => {
                setDialogToUpdate(false)
                setListByUpdate([])
              }}
              color='primary'
            >
              Закрыть
            </Button>
          </DialogActions>
        </Dialog>
      ) : null}
      {linkModel && linkModel['allowed'] ? (
        <Dialog
          open={dialogToCreate}
          onClose={e => {
            setDialogToCreate(false)
            setTempModel('')
          }}
          aria-labelledby='FFClassifier_FFClassifierCard_Dialog_parentTree'
          aria-describedby='FFClassifier_FFClassifierCard_Dialog_parentTree'
        >
          <DialogTitle id='FFClassifier_FFClassifierCard_DialogTitle_parentTree'>
            {'Выберите доступную модель'}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id='FFClassifier_FFClassifierCard_DialogContentText_parentTree'>
              <FormControl
                variant='filled'
                style={{ margin: 3, width: '100%' }}
              >
                <InputLabel id='FFParametersNew_InputLabel_select_formatList'>
                  Модель
                </InputLabel>
                <Select
                  key={'select'}
                  value={tempModel}
                  onChange={e => setTempModel(e.target.value)}
                >
                  {linkModel['allowed'].map(objChild => {
                    return (
                      <MenuItem value={objChild.id}>{objChild.label}</MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => closeAgreeParentDialog()} color='secondary'>
              Создать
            </Button>
            <Button
              onClick={e => {
                setDialogToCreate(false)
                setTempModel('')
              }}
              color='primary'
            >
              Вернутся к редактированию
            </Button>
          </DialogActions>
        </Dialog>
      ) : null}

      <Grid container>
        <Grid item xs={4}></Grid>
        <Grid item xs={8} align='right'>
          {true === true ? (
            <Tooltip title='Создать модель'>
              <IconButton
                aria-label='filter'
                onClick={e => doAddEssence()}
                className={classes.margin}
                style={{ visibility: isEdit === 0 ? 'visible' : 'hidden' }}
              >
                <IconAdd color='primary' fontSize='large' />
              </IconButton>
            </Tooltip>
          ) : null}
          {activeEssence && !doGoLink ? (
            <Tooltip title='Копировать существующую модель'>
              <IconButton
                aria-label='filter'
                onClick={e => doAddCopyEssence()}
                className={classes.margin}
                style={{ visibility: isEdit === 0 ? 'visible' : 'hidden' }}
              >
                <IconLibraryAdd color='primary' fontSize='large' />
              </IconButton>
            </Tooltip>
          ) : null}
          {activeEssence && !doGoLink ? (
            <Tooltip title='Обновить привязки'>
              <IconButton
                aria-label='filter'
                onClick={e => doUpdateModel()}
                className={classes.margin}
                style={{ visibility: isEdit === 0 ? 'visible' : 'hidden' }}
              >
                <IconSystemUpdateAlt color='primary' fontSize='large' />
              </IconButton>
            </Tooltip>
          ) : null}
          {true == true ? (
            <Tooltip title='Связать модели и сущности'>
              <IconButton
                aria-label='filter'
                onClick={e => clickDoGoLink()}
                className={classes.margin}
              >
                {!doGoLink ? (
                  <IconLink color='primary' fontSize='large' />
                ) : (
                  <IconLinkOff color='primary' fontSize='large' />
                )}
              </IconButton>
            </Tooltip>
          ) : null}
          {activeEssence ? (
            <Tooltip title='Сохранить'>
              <IconButton
                aria-label='filter'
                onClick={e => doSaveEssence()}
                className={classes.margin}
              >
                <IconSave color='primary' fontSize='large' />
              </IconButton>
            </Tooltip>
          ) : null}
          {true === true && !doGoLink ? (
            <Tooltip title='Обновить'>
              <IconButton
                aria-label='filter'
                onClick={e => doRefresh()}
                className={classes.margin}
                style={{ visibility: isEdit === 0 ? 'visible' : 'hidden' }}
              >
                <IconRefresh color='primary' fontSize='large' />
              </IconButton>
            </Tooltip>
          ) : null}
          {activeEssence ? (
            <Tooltip title='Удалить выбранную модель'>
              <IconButton
                aria-label='filter'
                onClick={e => setIsOpenDialogDelete(true)}
                className={classes.margin}
                style={{ visibility: isEdit === 0 ? 'visible' : 'hidden' }}
              >
                <IconDelete color='error' fontSize='large' />
              </IconButton>
            </Tooltip>
          ) : null}
        </Grid>
      </Grid>

      <TableContainer className={[classes.flex, classes.end]}>
        <Grid
          container
          id={'body_grid'}
          className={[classes.flexRow, classes.end]}
        >
          <Grid
            item
            xs={3}
            id={'bodyTree_grid'}
            style={{ padding: 10 }}
            className={clsx(classes.flex, classes.end)}
          >
            <Paper
              elevation={3}
              id={'bodyTree_paper'}
              className={clsx(classes.flex, classes.end)}
              style={{ padding: 10 }}
            >
              <FFLoaderComponent
                // key={te}
                loading={essenceLoading}
                isLoader={<Loader />}
                component={<FFTreeEssence essence={essence} />}
              />
            </Paper>
          </Grid>
          <Grid
            item
            xs={9}
            id={'bodyMain_grid'}
            className={[classes.flex, classes.end]}
          >
            <FFLoaderComponent
              loading={activeEssence ? true : false}
              isLoader={
                <Paper
                  elevation={3}
                  style={{ margin: 10, padding: 8 }}
                  className={clsx(classes.flex, classes.end)}
                >
                  <Typography
                    variant='h1'
                    component='h2'
                    style={{ color: 'lightgray', marginTop: '20%' }}
                  >
                    {!doGoLink ? 'Модель не выбрана' : 'Сущность не выбрана'}
                  </Typography>
                </Paper>
              }
              component={
                !doGoLink ? (
                  <FF_Template_Card
                    key={
                      activeEssence
                        ? 'FF_Template_Card_' + activeEssence.id + '_' + te
                        : 'FF_Template_Card_temp'
                    }
                    classifier={classifier}
                    parameter={parameter}
                    essence={activeEssence ? activeEssence : null}
                    children={children}
                    metaEssence={metaEssence}
                  />
                ) : (
                  <FFModelLink
                    linkModel={linkModel}
                    parameter={parameter}
                    currentModel={currentModel}
                    changeCurrentModel={newValue => setCurrentModel(newValue)}
                  />
                )
              }
            />
          </Grid>
        </Grid>
      </TableContainer>
    </Context.Provider>
  )
}

const FFModelLink = props => {
  // -------------------------------------------------------------------------------
  //
  //                                   Стили
  //
  // -------------------------------------------------------------------------------

  const classes = useStyles()
  const colors = ['#3f51b5', '#f44336', '#e91e63', '#9c27b0']

  // -------------------------------------------------------------------------------
  //
  //                                 Состояния
  //
  // ---------------------------- ---------------------------------------------------
  const [currentTab, setCurrentTab] = useState(
    props.currentModel ? props.currentModel : 0
  )
  const [linkModel, setLinkModel] = useState({})
  const [te, setTe] = useState(0)
  const [search, setSearch] = useState('')
  // console.log(props)
  // -------------------------------------------------------------------------------
  //
  //                                 Обработчики событий
  //
  // -------------------------------------------------------------------------------

  // useEffect(() => {
  //   console.log(currentTab)
  // }, [currentTab])

  useEffect(() => {
    // console.log(props)
    setLinkModel(props.linkModel)

    // setCurrentTab(0)
  })

  const onChangeParameters = (e, modelId, key) => {
    let temp = linkModel
    // console.log((e.target.value, modelId, key))
    let model = temp['connections'].filter(obj => obj.id === modelId)[0]
      .parameters
    model[key] = e.target.value
    setLinkModel(temp)
    setTe(te + 1)
  }

  return (
    <Paper
      elevation={3}
      style={{ margin: 8, padding: 8 }}
      className={[classes.tree, classes.scroller, classes.end]}
    >
      {/* <Grid item xs={12} spacing={1}> */}
      {linkModel &&
      linkModel['connections'] &&
      linkModel['connections'].length ? (
        <React.Fragment>
          <Tabs
            value={currentTab}
            onChange={(event, newValue) => {
              setCurrentTab(newValue)
              props.changeCurrentModel(newValue)
              setSearch('')
            }}
            indicatorColor='primary'
            textColor='primary'
            variant='fullWidth'
            // className={classes.main}
            aria-label='full width tabs example'
          >
            {linkModel['connections'].map((model, index) => {
              return <Tab label={model.label} {...a11yProps(index)} />
            })}
          </Tabs>
          {linkModel['connections'].map((model, index) => {
            return (
              <TabPanelHor value={currentTab} index={index}>
                <Grid container item xs={12} spacing={1}>
                  {model && model.link ? (
                    <Grid item xs={12}>
                      <Card>
                        <CardHeader
                          avatar={
                            <Avatar
                              aria-label='recipe'
                              className={classes.avatar}
                              style={{ background: colors[0] }}
                            >
                              {'Li'}
                            </Avatar>
                          }
                          title={'Ссылка на внутренний или внешний сервис'}
                          subheader='Атрибут link'
                        />

                        <CardContent>
                          <Typography>
                            {model.link.msg + ' ('}
                            <Tooltip title={model.link.url_label}>
                              <Link to={model.link.url}>
                                {'перейдите по ссылке'}
                                {/* {model.link.url_label} */}
                              </Link>
                            </Tooltip>
                            {').'}
                          </Typography>
                        </CardContent>
                      </Card>
                    </Grid>
                  ) : null}
                  {model && model.parameters ? (
                    <Grid item xs={12}>
                      <Card>
                        <CardHeader
                          avatar={
                            <Avatar
                              aria-label='recipe'
                              className={classes.avatar}
                              style={{ background: colors[1] }}
                            >
                              {Object.keys(model.parameters).length}
                            </Avatar>
                          }
                          title={
                            'Блок сопоставления параметров модели и параметров сущности'
                          }
                          subheader='Атрибут parameters'
                          action={
                            <TextField
                              id={'Seacrh' + '_text'}
                              style={{ marginRight: 8, width: '200px' }}
                              value={search}
                              onChange={e => setSearch(e.target.value)}
                              label={'Поиск'}
                              variant='filled'
                            />
                          }
                        />
                        <CardContent>
                          <Grid container item xs={12}>
                            {Object.keys(model.parameters).map(key => {
                              let temp = props.parameter.filter(
                                obj => obj.id === key
                              )
                              if (
                                temp.length > 0 &&
                                (temp[0].label
                                  .toLowerCase()
                                  .indexOf(search.toLowerCase()) > -1 ||
                                  temp[0].name
                                    .toLowerCase()
                                    .indexOf(search.toLowerCase()) > -1)
                              ) {
                                return (
                                  <Grid container item xs={12}>
                                    <Grid
                                      item
                                      xs={6}
                                      style={{ padding: 4 }}
                                      key={'ModelParam' + key}
                                    >
                                      <TextField
                                        id={key + '_text'}
                                        style={{ margin: 4, width: '100%' }}
                                        label={'Параметр из модели'}
                                        // onClick={props.doClick ? (e) => props.doClick(e) : null}
                                        // multiline
                                        // rowsMax="10"
                                        value={
                                          props.parameter.filter(
                                            obj => obj.id === key
                                          ).length
                                            ? props.parameter.filter(
                                                obj => obj.id === key
                                              )[0].label
                                            : key +
                                              ' - этого UUID нет в Реестре'
                                        }
                                        // onChange={handleChange}
                                        variant='filled'
                                        disabled={true}
                                      />
                                      {/*
                                    {props.parameter.filter(
                                      obj => obj.id === key
                                    ).length
                                      ? props.parameter.filter(
                                          obj => obj.id === key
                                        )[0].label
                                      : key} */}
                                    </Grid>
                                    <Grid
                                      item
                                      xs={6}
                                      style={{ padding: 4 }}
                                      key={'EssenceParam' + key}
                                    >
                                      <FormControl
                                        key={model.parameters[key]}
                                        variant='filled'
                                        style={{ margin: 4, width: '100%' }}
                                      >
                                        <InputLabel id='FFClassifier_FFClassifierCard_InputLabel_defaultValue'>
                                          {'Параметр из сущности'}
                                        </InputLabel>
                                        <Select
                                          key={model.parameters[key]}
                                          value={model.parameters[key]}
                                          onChange={e =>
                                            onChangeParameters(e, model.id, key)
                                          }
                                        >
                                          <MenuItem value={null}>
                                            {'Пусто'}
                                          </MenuItem>
                                          {props.parameter.map(objChild => {
                                            return (
                                              <MenuItem value={objChild.id}>
                                                {objChild.label}
                                              </MenuItem>
                                            )
                                          })}
                                        </Select>
                                      </FormControl>

                                      {/* {model.parameters[key]} */}
                                    </Grid>
                                  </Grid>
                                )
                              }
                            })}
                          </Grid>
                        </CardContent>
                      </Card>
                    </Grid>
                  ) : null}
                </Grid>
              </TabPanelHor>
            )
          })}
        </React.Fragment>
      ) : (
        <Typography
          variant='h1'
          component='h2'
          style={{ color: 'lightgray', marginTop: '20%' }}
        >
          {'Пока ни одна модель еще не привязана'}
        </Typography>
      )}
      {/* </Grid> */}
    </Paper>
  )
}

function TabPanel (props) {
  const { children, value, index, ...other } = props

  return (
    <Typography
      component='div'
      role='tabpanel'
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Card
          p={3}
          // style={{ marginRight: '10px', marginLeft: '10px', padding: 10 }}
        >
          {children}
        </Card>
      )}
    </Typography>
  )
}

function TabPanelHor (props) {
  const { children, value, index, ...other } = props

  return (
    <Typography
      component='div'
      role='tabpanel'
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
}

function a11yProps (index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`
  }
}

const FF_Template_Card = props => {
  // -------------------------------------------------------------------------------
  //
  //                                   Стили
  //
  // -------------------------------------------------------------------------------

  const classes = useStyles()

  // -------------------------------------------------------------------------------
  //
  //                                 Состояния
  //
  // ---------------------------- ---------------------------------------------------

  const { changeTextField } = useContext(Context)
  const [value2, setValue2] = React.useState(0)
  const [errorChange, setErrorChange] = useState('')

  let metaTable = [
    {
      widthColumn: 4,
      data: [
        {
          type: 'ffListTextFields',
          style: {},
          name_attr: ['name', 'label', 'label_eng'],
          // dis: true,
          label_attr: [
            'Системное наименование',
            'Наименование',
            'Наименование на латинском'
          ]
        }
      ]
    },
    {
      widthColumn: 4,
      data: [
        {
          type: 'ffListTextFields',
          style: {},
          name_attr: ['lib', 'func'],
          // dis: true,
          label_attr: ['Библиотека Си', 'Функция Си']
        }
      ]
    },
    {
      widthColumn: 4,
      data: [
        {
          type: 'ffListTextFields',
          style: {},
          name_attr: ['description'],
          // dis: false,
          label_attr: ['Описание']
        }
      ]
    }
  ]

  // console.log(props.metaEssence)
  // -------------------------------------------------------------------------------
  //
  //                                 Обработчики событий
  //
  // -------------------------------------------------------------------------------

  useEffect(() => {}, [])

  // Событие на изменение текстового
  const handleChangeFFTextFields = (value, parameter_id, name, element) => {
    if (props.metaEssence[name]['is_required'] && value === '') {
      setErrorChange('Заполните все обязательные поля')
      return 'Обязательное поле не заполнено!'
    }
    setErrorChange('')
    changeTextField(value, parameter_id, name, element)
    return ''
  }

  // Переключалка для горизонтального таба
  const handleChangeHoro = (event, newValue) => {
    setValue2(newValue)
  }

  // Событие на перенос списка
  const handleTransporterList = (value, id, type) => {
    let baseList =
      type === 'active_classifiers' ? props.classifier : props.parameter
    let tempListValue = value.map(objInd => baseList[objInd].id)
    changeTextField(tempListValue, '', type, '')
  }
  // -------------------------------------------------------------------------------
  //
  //                                    Рендеринг
  //
  // -------------------------------------------------------------------------------

  return props.essence ? (
    <Context.Provider
      value={{ handleChangeFFTextFields, handleTransporterList }}
    >
      <Paper
        elevation={3}
        style={{ margin: 8, padding: 8 }}
        // className={classes.end}
        className={[classes.tree, classes.scroller, classes.end]}
      >
        <Grid container item xs={12} spacing={1}>
          {metaTable.map(metaColumn => {
            // console.log(metaColumn)
            return (
              <Grid item xs={metaColumn.widthColumn}>
                {metaColumn.data.map(metaCell => {
                  return (
                    <FFRouterComponents
                      style={{ margin: 10 }}
                      parameter={props.essence}
                      meta_cell={metaCell}
                    />
                  )
                })}
              </Grid>
            )
          })}
          <Grid xs={12}>
            <Tabs
              value={value2}
              onChange={handleChangeHoro}
              indicatorColor='primary'
              textColor='primary'
              variant='fullWidth'
              // className={classes.main}
              aria-label='full width tabs example'
            >
              <Tab label='Параметры' {...a11yProps(0)} />
            </Tabs>
            <TabPanelHor value={value2} index={0}>
              <FFTransportList
                cls={props.essence.active_parameters}
                cls_main={props.parameter}
                type={'active_parameters'}
                id={props.essence.id}
                label={['Реестр параметров', 'Используемые параметры']}
              />
            </TabPanelHor>
          </Grid>
        </Grid>
      </Paper>
    </Context.Provider>
  ) : null
}
