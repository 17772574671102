import React, { useContext, useState } from 'react'
import Context from './context'
import Particles from 'react-particles-js'
import {urlAuth } from './ApiConnectNew'
import { useDispatch } from "react-redux";
import CryptoJS from 'crypto-pbkdf2';
import clsx from 'clsx'

import cookie from 'react-cookies'

import IconButton from '@material-ui/core/IconButton'
import Input from '@material-ui/core/Input'
import FilledInput from '@material-ui/core/FilledInput'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import InputLabel from '@material-ui/core/InputLabel'
import InputAdornment from '@material-ui/core/InputAdornment'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControl from '@material-ui/core/FormControl'
import TextField from '@material-ui/core/TextField'
import Card from '@material-ui/core/Card'

import { SnackbarProvider, useSnackbar } from 'notistack'

import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'

import { makeStyles } from '@material-ui/core/styles'
import { Button, Grid, Typography } from '@material-ui/core'

import { jwtDecode } from "jwt-decode";
import { setIsFull, setIsLogin } from '../features';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  margin: {
    margin: theme.spacing(1)
  },
  withoutLabel: {
    marginTop: theme.spacing(3)
  },
  textField: {
    width: '-webkit-fill-available'
  }
}))

const FF_Login = props => {
  const dispatch = useDispatch();
  const classes = useStyles()

  const [password, setPassword] = useState({ value: '', isShow: false })

  const [error, setError] = useState(false)

  const [login, setLogin] = useState(
    cookie.load('login') ? cookie.load('login') : ''
  )

  const { isLogin } = useContext(Context)

  const onChangeLogin = e => {
    setLogin(e.target.value)
    cookie.save('login', e.target.value);
  }

  const handleClickShowPassword = e => {
    setPassword({ value: password.value, isShow: !password.isShow })
  }

  const onChangePassword = e => {
    // let temp = password
    setPassword({ value: e.target.value, isShow: password.isShow })
  }

  const goLogin = () => {
    var key = CryptoJS.PBKDF2(password.value, 'ie-vuex', {
      keySize: 512 / 32,
      iterations: 1000
    }).toString(CryptoJS.enc.Base64)
    let data = `client_id=ServiceClient&grant_type=password&scope=openid&username=${login}&password=${password.value}`
    fetch(`${urlAuth}`, {
      method: 'post',
      body: data,
      headers: new Headers({ 'Content-Type': 'application/x-www-form-urlencoded' })
    })
      .then(response => response.json())
      .then(temp =>
        setTimeout(() => {
          if ('access_token' in temp) {
            const decoded = jwtDecode(temp['access_token']);
            if (decoded['realm_access']['roles'].includes("CONFIGURATOR_APP_full")) {
              dispatch(setIsFull(true));
            }
            cookie.save('access_token', temp['access_token'], { path: '/' })
            cookie.save('login', login);
            isLogin(true)
            setError(false)
            dispatch(setIsLogin(false));
            dispatch(setIsLogin(true));
          } else {
            setError(true)
            handleClickVariant('error', temp['msg'])
            setPassword({ value: '', isShow: password.isShow })
          }
        }, 1)
      )
  }

  const particlesOpt = {
    particles: {
      number: {
        value: 150,
        density: {
          enable: true,
          value_area: 800
        }
      },
      size: {
        value: 5
      }
    },
    interactivity: {
      events: {
        onhover: {
          enable: true,
          mode: 'repulse'
        }
      }
    }
  }

  const { enqueueSnackbar } = useSnackbar()
  const handleClickVariant = (variant, msg) => {
    // variant could be success, error, warning, info, or default
    enqueueSnackbar(
      msg,
      { variant },
      {
        anchorOrigin: { vertical: 'top', horizontal: 'right' }
      }
    )
  }

  const toGo = event => {
    if (event.key === 'Enter') {
      cookie.save('login', login, {path: '/'});
      goLogin()
    }
  }

  return (
    <div style={{ background: '#114666' }}>
      {/* <h1>Landing Page</h1> */}
      <Particles
        style={{ height: '100vh', minHeight: '100vh' }}
        params={particlesOpt}
      />
      <Grid container spacing={3}>
        <Grid item xs></Grid>
        <Grid item xs={3}>
          <Card style={{ position: 'fixed', top: '30%', width: '25%' }}>
            <Typography style={{ margin: 15 }} variant='h5' component='h3'>
              Интернет Энергии
            </Typography>
            <FormControl
              className={clsx(classes.margin, classes.textField)}
              variant='filled'
            >
              <InputLabel htmlFor='standard-adornment-password'>
                Логин
              </InputLabel>
              <FilledInput
                id='standard-adornment-password'
                type={'text'}
                value={login}
                onChange={onChangeLogin}
              />
            </FormControl>

            <FormControl
              className={clsx(classes.margin, classes.textField)}
              variant='filled'
            >
              <InputLabel htmlFor='standard-adornment-password'>
                Пароль
              </InputLabel>
              <FilledInput
                id='standard-adornment-password'
                type={password.isShow ? 'text' : 'password'}
                value={password.value}
                onChange={onChangePassword}
                onKeyDown={toGo}
                endAdornment={
                  <InputAdornment position='end'>
                    <IconButton
                      // aria-label='toggle password visibility'
                      onClick={handleClickShowPassword}
                      // onMouseDown={handleMouseDownPassword}
                    >
                      {password.isShow ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>

            <Button
              key={'Login' + error}
              color='primary'
              className={clsx(classes.margin, classes.textField)}
              onClick={e => goLogin()}
              variant='contained'
            >
              Войти
            </Button>
          </Card>
        </Grid>
        <Grid item xs></Grid>
      </Grid>
    </div>
  )
}

export default FF_Login
