import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Tree, Tag } from "antd";
import { DownOutlined, CheckCircleTwoTone } from "@ant-design/icons";
import {
  entitiesSelector,
  selectNode,
  getTelemetryClassifiers,
  treeOpenSelector,
} from "../../slice";
import { treeUtils, treeStatistics } from "./utils";
import { Header } from "./header";
import { setConsumer as setConsumerEssence } from "../../../mapping-essence-to-essence/slice";
import { setConsumer as setConsumerDC } from "../../../mapping-essence-to-dc/slice";

const FIELD_NAMES = {
  title: "label",
  key: "id",
  children: "children",
};

export const TreeComponent = ({ data }) => {
  const dispatch = useDispatch();

  const open = useSelector(treeOpenSelector);
  const { data: entities } = useSelector(entitiesSelector);
  const { statClient, statEntity } = treeStatistics(entities);

  const [search, setSearch] = useState("");
  const [isChecked, setIsChecked] = useState(false);

  const handleSelect = (ids) => {
    if (ids.length) {
      const node = treeUtils.getNodeByKey(data, ids[0]);
      if (node && node?.type !== "client") {
        dispatch(setConsumerEssence(node));
        dispatch(setConsumerDC(node));
      }
      dispatch(getTelemetryClassifiers(ids[0]));
      dispatch(selectNode({ node }));
      return;
    }

    dispatch(selectNode({ node: undefined }));
  };

  const createTreeData = () => {
    const searchNode = (node) => {
      if (node.label.toLowerCase().includes(search.toLowerCase())) {
        if (isChecked && statEntity.includes(node.id)) {
          return true;
        } else if (!isChecked) {
          return true;
        }
      }
      return false;
    };

    return treeUtils.filterNodes(data, searchNode);
  };

  const getIcon = (node) => {
    if (statEntity.includes(node.id)) {
      return <CheckCircleTwoTone twoToneColor="#52c41a" style={{width: 14, marginRight: 5}} />;
    }
  };

  const getTitleRender = (node) => {
    let icon = null;
    if (statClient[node.id]) {
      icon = (
        <Tag
          size="small"
          color="blue"
          style={{ marginLeft: 10, borderRadius: 15 }}
        >
          {statClient[node.id]}
        </Tag>
      );
    }
    return (
      <>
        {node.label}
        {icon}
      </>
    );
  };

  return (
    <div style={{ diplay: open ? "" : "none" }}>
      <Header
        search={search}
        setSearch={setSearch}
        isChecked={isChecked}
        setIsChecked={setIsChecked}
      />
      <Tree
        showIcon
        showLine
        switcherIcon={<DownOutlined style={{color: '#a7a9ab'}} />}
        onSelect={handleSelect}
        treeData={createTreeData()}
        titleRender={getTitleRender}
        icon={getIcon}
        fieldNames={FIELD_NAMES}
      />
    </div>
  );
};
