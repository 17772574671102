import React, { useEffect, useState, useContext } from "react";

import {
  Paper,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
  Backdrop,
} from "@material-ui/core/";

import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import highchartsMore from "highcharts/highcharts-more.js";
import TreemapModule from "highcharts/modules/treemap";
import SolidModule from "highcharts/modules/solid-gauge";

import { Alert, AlertTitle } from "@material-ui/lab";
import { makeStyles, withStyles } from "@material-ui/core/styles";

/*
 **-------------------- My Components ---------------------------
 */

import FF_History from "./FF_History";
import FF_EditBaseObject from "./FF_EditBaseLine";
import FFTextField from "../../../libft/FFTextField";
import FFContainer from "../../../libft/FFContainer";
import FFTreeEssence from "../../../libft/FFTreeEssence";
import FFActionButton from "../../../libft/FFActionButton";
import FFSelect from "../../../libft/FFSelect";
import FFLoader from "../../../libft/FFLoader";
import FFLoaderComponent from "../../../libft/FFLoaderComponent";

/*
 **------------------------- Utils ------------------------------
 */
import Context from "../../../context";
import cookie from "react-cookies";
import {
  ApiConnect,
  urlConfiguration,
  urlBaseLine,
  urlPortal,
} from "../../../ApiConnectNew";
import { SnackbarProvider, useSnackbar } from "notistack";
import TreeNodeUtils from "tree-node-utils";
import moment from "moment";
import "moment/locale/ru";
import { Loader } from "rsuite";
import { useRef } from "react";
import FFDialogDelete from "../../../libft/FFDialogDelete";
import { api } from "../../../api/api";
import {v4 as uuidv4} from 'uuid';


highchartsMore(Highcharts);
TreemapModule(Highcharts);
SolidModule(Highcharts);


const useStyles = makeStyles((theme) => ({
  root: {
    overflow: "auto",
    height: "100%",
    margin: 0,
  },
  margin: {
    margin: theme.spacing(1),
  },
  defaultText: {
    color: theme.palette.grey[200],
  },
  isActiveRowTable: {
    background: theme.palette.primary.light,
  },
  isExpiredRotTable: {
    background: theme.palette.error.light,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

export default function FF_BaseLine(props) {
  const { enqueueSnackbar } = useSnackbar();
  const handleClickVariant = (variant, msg) => {
    enqueueSnackbar(
      msg,
      { variant },
      {
        anchorOrigin: { vertical: "top", horizontal: "right" },
      }
    );
  };

  const config = {
    childrenField: "children",
    keyField: "id",
  };

  const treeUtils = new TreeNodeUtils(config);

  const classes = useStyles();

  const [scenarios, setScenarios] = useState([]);
  const [loadingScenarios, setLoadingScenarios] = useState(false);
  const [activeScenario, setActiveScenario] = useState(null);
  const [deviceActiveScenario, setDeviceActiveScenario] = useState(null);
  const [activePage, setActivePage] = useState("visible"); // Идентификатор нашего положения на странице
  const [essence, setEssence] = useState(null);
  const [essenceLoading, setEssenceLoading] = useState(false);
  const [activeEssence, setActiveEssence] = useState(null);
  const [childrenActiveEssence, setChildrenActiveEssence] = useState(null);
  const [devicesScenarioAll, setDevicesScenarioAll] = useState(null);
  const [dataToModifyHistory, setDataToModifyHistory] = useState(null); // Промежуточная штука на модификацию

  const [modelInterface, setModelInterface] = useState(null); // Для создания нового сценария
  const [saveObj, setSaveObj] = useState([]); // Очередь на сохранение
  const [saveObjAlgorithm, setSaveObjAlgorithm] = useState([]); // Очередь на сохранение

  const [parameters, setParameters] = useState(null); // Параметры полученные из сущностей
  const [openIsNotSave, setOpenIsNotSave] = useState(null); // Состояние диалога при агрессивном выходе
  const [openComment, setOpenComment] = useState(null); // Состояние диалога для ввода комментария при сохранении
  const [openSettings, setOpenSettings] = useState(null); // Состояние диалога для ввода комментария при сохранении
  const [openModelInterface, setOpenModelInterface] = useState([]); // Состояние диалога для выбора модельного интерфейса при создании
  const [updateHard, setUpdateHard] = useState(uuidv4()); // Принудительное обновление компонентов
  const [getCsv, setGetCsv] = useState(null);
  const [openBackDrop, setOpenBackDrop] = useState(false);
  const [openCalc, setOpenCalc] = useState(null);
  const [openChart, setOpenChart] = useState(null);
  const [modelId, setModelId] = useState(null);
  const [streamParam, setStreamParam] = useState(null);
  const [isOpenDialogDelete, setIsOpenDialogDelete] = useState(false); // Флаг для показа диалогового окна удаления
  const baseModel_v1 = "31902740-b30b-4be6-b9ae-05fd26c7b34a";
  const ListModel = [
    {
      id: "369666b5-adaf-44b2-8d91-b901a331a758",
      label: "Базовая Линия Версия 2",
    },
    {
      id: "3cf44d7a-3020-45b9-b7c4-5d98d4da668c",
      label: "Базовая Линия Версия 2",
    },
    {
      id: "31902740-b30b-4be6-b9ae-05fd26c7b34a",
      label: "Базовая Линия Версия 1",
    },
  ];

  const { isLogin } = useContext(Context);

  // Новый сценарий для устройства
  const constructDeviceScenario = (idDevice) => {
    return {
      actual_value: null,
      algorithm: "",
      device: idDevice,
      device_model: null,
      formula: null,
      formula_parameters: null,
      id: uuidv4(),
      is_device_active: false,
      scenario: activeScenario.id,
      schedule: null,
      is_device_active: false,
      isNew: true,
    };
  };

  // Проверка нужно ли создавать пустышку или нет
  const checkDevice = (idDevice) => {
    let tempDevice = constructDeviceScenario(idDevice);
    let find = deviceActiveScenario.filter((obj) => obj.device === idDevice);
    if (find.length) {
      tempDevice = find[0];
      tempDevice["isNew"] = false;
      let temp = tempDevice["schedule_value"];
      let tempDate = {};
      if (temp && Object.keys(temp).length) {
        if (Object.keys(temp)[0].indexOf("-") > -1) tempDate = temp;
        else {
          Object.keys(temp).map((y) => {
            Object.keys(temp[y]).map((m) => {
              Object.keys(temp[y][m]).map((d) => {
                tempDate[
                  moment(`${d}-${m}-${y}`, "E-WW-YYYY").format("YYYY-MM-DD")
                ] = temp[y][m][d];
              });
            });
          });
        }
      }
      tempDevice["schedule_value"] = tempDate;
    }
    return tempDevice;
  };

  useEffect(() => {
    setDevicesScenarioAll(null);
    if (deviceActiveScenario) {
      let all = childrenActiveEssence.map((device) => {
        return { ...device, ...checkDevice(device.id) };
      });
      all.sort((a, b) => {
        if (a.label > b.label) {
          return 1;
        }
        if (a.label < b.label) {
          return -1;
        }
        return 0;
      });
      setDevicesScenarioAll(all);
    }
  }, [deviceActiveScenario]);

  useEffect(() => {
    if (childrenActiveEssence) {
      ApiConnect({
        name: "getDataPost",
        url: urlConfiguration + "/api/mapping/?entity=essence",
        objToGet: {
          id: childrenActiveEssence.map((obj) => {
            return obj.id;
          }),
          mapping: [["parameter_values", "parameter_registry"]],
        },
        setterEssence: setParameters,
        isLogin: isLogin,
        clickHandle: handleClickVariant,
      });
    }
  }, [childrenActiveEssence]);

  // Получаем для выбранного сценария заведенные в базу базовые линии для устройств
  useEffect(() => {
    setDeviceActiveScenario(null);
    setSaveObj([]);
    setModelId(null);
    if (activeScenario) {
      ApiConnect({
        name: "getDataPost",
        url: urlConfiguration + "/api/search/?entity=device_settings_0line",
        objToGet: { scenario: activeScenario.id },
        setterEssence: setDeviceActiveScenario,
        isLogin: isLogin,
        clickHandle: handleClickVariant,
      });
      ApiConnect({
        name: "getDataPost",
        url: urlConfiguration + "/api/search/?entity=model_interface",
        objToGet: {
          // model: '31902740-b30b-4be6-b9ae-05fd26c7b34a',
          id: activeScenario.model_interface,
          // is_active: true,
          is_deleted: false,
        },
        setterEssence: (temp) => {
          // console.log(temp)
          temp.length
            ? setModelId(temp[0].model)
            : handleClickVariant(
                "warning",
                "Для выбранного сценария модельный интерфейс не актуален"
              );
        },
        isLogin: isLogin,
        clickHandle: handleClickVariant,
      });
    }
  }, [activeScenario]);

  // Получаем все сценарии для выбранной сущности
  useEffect(() => {
    setActiveScenario(null);
    setActivePage("visible");
    setStreamParam(null);
    if (activeEssence) {
      setLoadingScenarios(false);
      setChildrenActiveEssence(
        getChildrenList(
          activeEssence,
          [activeEssence],
          "type-essence",
          "device"
        )
      );
      ApiConnect({
        name: "getData",
        url: urlConfiguration + "/api/base_line/?id=" + activeEssence.id,
        setterEssence: (temp) =>
          setScenarios(JSON.parse(JSON.stringify(temp).replace(/ GMT/g, ""))),
        setterLoading: setLoadingScenarios,
        isLogin: isLogin,
        clickHandle: handleClickVariant,
      });
      ApiConnect({
        name: "getData",
        url: urlConfiguration + "/api/bl2_params?object_id=" + activeEssence.id,
        setterEssence: setStreamParam,
        // setScenarios(JSON.parse(JSON.stringify(temp).replace(/ GMT/g, ''))),
        // setterLoading: setLoadingScenarios,
        isLogin: isLogin,
        clickHandle: handleClickVariant,
      });
    }
  }, [activeEssence]);

  // Получаем дерево сущностей
  useEffect(() => {
    api.essence.get_tree({
      isLogin,
      callback: setEssence,
      callbackLoading: setEssenceLoading,
    });
    // ApiConnect({
    //   name: 'getData',
    //   url:
    //     urlConfiguration +
    //     '/api/support/info/?entity=essence&action=select&info=tree',
    //   setterEssence: setEssence,
    //   setterLoading: setEssenceLoading,
    //   isLogin: isLogin,
    //   clickHandle: handleClickVariant
    // })
    // ApiConnect({
    //   name: 'getData',
    //   url:
    //     urlConfiguration +
    //     '/api/support/info/?entity=essence&action=select&info=tree',
    //   setterEssence: setEssence,
    //   setterLoading: setEssenceLoading,
    //   isLogin: isLogin,
    //   clickHandle: handleClickVariant
    // })
  }, []);

  // Утилита для получения всех потомков
  const getChildrenList = (node, childList, nameAttr, isValue) => {
    if (node[nameAttr] == isValue) childList.push(node);
    node.children.map((obj) => {
      getChildrenList(obj, childList, nameAttr, isValue);
    });
    return childList;
  };

  // Событие клик на дерево
  const doClickActiveEssence = (id, obj, type) => {
    if (saveObj.length === 0 || id === "notSave") {
      setActiveEssence(obj);
    } else {
      setOpenIsNotSave(obj);
    }
  };

  const doGoPositionScroll = (tempPosition) => {};

  const doClickMetaSelect = (tempSelect) => {};

  // Клик на удаление
  const doDelete = () => {
    setScenarios(scenarios.filter((obj) => obj.id !== activeScenario.id));
    ApiConnect({
      name: "deleteDate",
      url:
        urlConfiguration +
        "/api/?entity=scenarios_model&id=" +
        activeScenario.id,
      isLogin: isLogin,
      clickHandle: handleClickVariant,
    });
    setActiveScenario(null);
  };

  // ---------------------------Создание нового сценария---------------------------
  //
  const doAdd = () => {
    if (activeEssence) {
      getModelInterface();
    } else {
      handleClickVariant("warning", "Выберите сущность");
    }
  };

  const getModelInterface = () => {
    setModelInterface(null);
    console.log(activeEssence);

    ApiConnect({
      name: "getDataPost",
      url: urlConfiguration + "/api/search/?entity=model_interface",
      objToGet: {
        essence: activeEssence.id,
        is_deleted: false,
      },
      setterEssence: (temp) => {
        let modelIntId = ListModel.map((obj) => obj.id);
        let currentModelInt = temp.filter(
          (obj) => modelIntId.indexOf(obj.model) > -1
        );
        currentModelInt.length
          ? setOpenModelInterface(currentModelInt)
          : handleClickVariant(
              "warning",
              "Для выбранной сущности активных интерфейсов не найдено"
            );

        // () => {setModelInterface(temp[0])}
      },
      setterLoading: null,
      isLogin: isLogin,
      clickHandle: handleClickVariant,
    });
  };

  useEffect(() => {
    if (modelInterface) {
      let tempId = uuidv4();
      let temp = {
        active_essences: null,
        author: cookie.load("login"),
        comment: "Новый сценарий",
        date_creation: new Date().toGMTString(),
        date_edition: new Date().toGMTString(),
        date_start_calc: null,
        date_validation_calc: null,
        description: "Описание к новому сценарию",
        devices_schedule: null,
        editor: cookie.load("login"),
        id: tempId,
        is_active: false,
        is_deleted: false,
        is_main: false,
        label: "Новый сценарий_" + tempId.split("-")[0],
        name: "ScenariosModel_" + tempId.split("-")[0],
        label_eng: "ScenariosModel_" + tempId.split("-")[0],
        model_interface: modelInterface,
        kpi: {
          savings: [
            {
              label: "%",
              label_eng: "%",
              system_name: "percent",
              value: 12,
            },
            {
              label: "кВтч",
              label_eng: "kWth",
              system_name: "kwth",
              value: 1200,
            },
            {
              label: "кВт",
              label_eng: "kWt",
              system_name: "kwt",
              value: 120,
            },
          ],
        },
      };
      setScenarios([...scenarios, { ...temp }]);
      Object.keys(temp).map((obj) => {
        if (obj.indexOf("date_") !== -1) {
          temp[obj] = new Date(temp[obj]).toLocaleString();
        }
      });
      ApiConnect({
        name: "createData",
        url: urlConfiguration + "/api/?entity=scenarios_model",
        objToCreate: temp,
        isLogin: isLogin,
        clickHandle: handleClickVariant,
      });
    }
  }, [modelInterface]);
  //
  // ------------------------------------------------------------------------

  // Клик на сохранение при редактировании сценария
  const doSaveScenario = () => {
    if (saveObj.length) {
      setOpenComment("edit");
    }
  };

  // Клик на сохранение при редактировании алгоритма расчета
  const doSaveAlgorithm = () => {
    if (saveObjAlgorithm) {
      setOpenComment("edit.algorithm");
    }
  };

  // Функция для отправки сценария устройства для обновления или созданий
  const postPutDeviceScenario = (temp) => {
    temp["isNew"]
      ? ApiConnect({
          name: "createData",
          url: urlConfiguration + "/api/?entity=device_settings_0line",
          objToCreate: temp,
          isLogin: isLogin,
          clickHandle: handleClickVariant,
        })
      : (() => {
          setOpenBackDrop(true);
          ApiConnect({
            name: "updateDate",
            url:
              urlConfiguration +
              "/api/?entity=device_settings_0line&id=" +
              temp.id,
            objToUpdate: temp,
            setterEssence: (temp) => {
              // console.log(temp.msg, temp.msg.indexOf('Не могу'), openComment)
              setOpenBackDrop(false);
              if (
                !(
                  temp?.msg?.indexOf("Не могу") === 0 &&
                  openComment === "edit.algorithm"
                )
              ) {
                // console.log('!!!')
                saveObjAlgorithm["isNew"] = false;
                setDevicesScenarioAll(
                  devicesScenarioAll.map((obj) => {
                    if (obj.id === saveObjAlgorithm.id) return saveObjAlgorithm;
                    return obj;
                  })
                );
                setSaveObj(saveObj.filter((id) => id !== saveObjAlgorithm.id));
                setUpdateHard(uuidv4());
                setSaveObjAlgorithm(null);
                setActivePage("edit");
              }
            },
            isLogin: isLogin,
            clickHandle: handleClickVariant,
          });
        })();
  };

  // Функция для отправки сценария на обновление
  const putScenario = (tempScenario) => {
    tempScenario.editor = cookie.load("login");
    tempScenario.date_edition = new Date().toGMTString();
    Object.keys(tempScenario).map((obj) => {
      if (obj.indexOf("date_") !== -1) {
        tempScenario[obj] = new Date(tempScenario[obj]).toLocaleString();
      }
    });
    ApiConnect({
      name: "updateDate",
      url:
        urlConfiguration + "/api/?entity=scenarios_model&id=" + tempScenario.id,
      objToUpdate: tempScenario,
      isLogin: isLogin,
      clickHandle: handleClickVariant,
    });
  };

  const doAddComment = (comment) => {
    activeScenario.comment = comment;
    putScenario({ ...activeScenario });
    if (openComment === "edit.algorithm") {
      postPutDeviceScenario(saveObjAlgorithm);
      // saveObjAlgorithm['isNew'] = false
      // setDevicesScenarioAll(
      //   devicesScenarioAll.map(obj => {
      //     if (obj.id === saveObjAlgorithm.id) return saveObjAlgorithm
      //     return obj
      //   })
      // )
      // setSaveObj(saveObj.filter(id => id !== saveObjAlgorithm.id))
      // setUpdateHard(uuidv4())
      // setSaveObjAlgorithm(null)
      // setActivePage('edit')
    } else if (openComment === "edit") {
      devicesScenarioAll.filter((obj) => {
        if (saveObj.indexOf(obj.id) > -1) {
          postPutDeviceScenario(obj);
          obj["isNew"] = false;
        }
      });
      setSaveObj([]);
    }
    setOpenComment(null);
  };

  // Контекст который добавляет в очередь на сохранение
  const toGoSave = (toSave) => {
    let temp = saveObj;
    if (temp.indexOf(toSave.id) === -1) {
      temp.push(toSave.id);
      setSaveObj(temp);
    }
  };

  const toGoSaveAlgorithm = (toSave) => {
    setSaveObjAlgorithm(toSave);
  };

  // Функция при клике на другую сущность а у нас еще есть данные для сохранения
  const doNotSave = () => {
    doClickActiveEssence("notSave", openIsNotSave, "");
    setOpenIsNotSave(null);
  };

  const doDialogEditSettings = (settings) => {
    activeScenario.kpi = {
      savings: settings.map((sett) => {
        delete sett["id"];
        if (!isNaN(Number(sett["value"]))) {
          sett["value"] = Number(sett["value"]);
          return sett;
        }
      }),
    };
    toGoSave(activeScenario.id);
    setOpenSettings(false);
  };

  // Нажатие на сохранение ретроспектива -> модификация базовой линии
  const onClickSaveToData = () => {
    ApiConnect({
      name: "updateDate",
      url: urlBaseLine + "/modify_all",
      objToUpdate: dataToModifyHistory,
      isLogin: isLogin,
      clickHandle: handleClickVariant,
    });
    setDataToModifyHistory(null);
  };

  // Роутер Кнопок
  const routerButtonByActivePage = () => {
    let listButtons = [];
    if (activePage === "visible") {
      listButtons.push(
        <FFActionButton
          type={"add"}
          onClick={() => doAdd()}
          tooltip={"Создать новое расписание"}
          className={classes.margin}
        />
      );

      if (activeScenario) {
        listButtons.push(
          <FFActionButton
            type={"history"}
            onClick={() => setActivePage("history")}
            tooltip={"Ручная корректировка"}
            className={classes.margin}
          />
        );
        listButtons.push(
          <FFActionButton
            type={"edit"}
            onClick={() => setActivePage("edit")}
            tooltip={"Редактировать"}
            className={classes.margin}
          />
        );
        listButtons.push(
          <FFActionButton
            type={"delete"}
            // onClick={() => doDelete()}
            onClick={() => setIsOpenDialogDelete(true)}
            tooltip={"Удалить расписание"}
            className={classes.margin}
            color={"error"}
          />
        );
      }
    } else if (activePage === "edit") {
      listButtons.push(
        <FFActionButton
          type={"save"}
          onClick={() => doSaveScenario()}
          tooltip={"Сохранить"}
          className={classes.margin}
        />
      );
      listButtons.push(
        <FFActionButton
          type={"cancel"}
          onClick={() => {
            setActivePage("visible");
            setSaveObj([]);
          }} //goBack}
          tooltip={"Назад (отменить)"}
          className={classes.margin}
        />
      );
      listButtons.push(
        <FFActionButton
          type={"settings"}
          onClick={() => setOpenSettings(true)}
          tooltip={"Изменение целевых показателей"}
          className={classes.margin}
        />
      );
      listButtons.push(
        <FFActionButton
          type={"calc"}
          onClick={() => setOpenCalc(true)}
          tooltip={"Пересчет базовой линии"}
          className={classes.margin}
        />
      );
      listButtons.push(
        <FFActionButton
          type={"chart"}
          onClick={() => setOpenChart(true)}
          tooltip={"Открыть график"}
          className={classes.margin}
        />
      );
    } else if (activePage === "history") {
      listButtons.push(
        <FFActionButton
          type={"save"}
          onClick={() => onClickSaveToData()}
          tooltip={"Сохранить"}
          className={classes.margin}
        />
      );
      listButtons.push(
        <FFActionButton
          type={"cancel"}
          onClick={() => setActivePage("visible")}
          tooltip={"Назад (отменить)"}
          className={classes.margin}
        />
      );
    } else if (activePage.indexOf("edit.") > -1) {
      listButtons.push(
        <FFActionButton
          type={"save"}
          onClick={() => doSaveAlgorithm()}
          tooltip={"Сохранить"}
          className={classes.margin}
        />
      );
      listButtons.push(
        <FFActionButton
          type={"cancel"}
          onClick={() => {
            setSaveObjAlgorithm(null);
            setUpdateHard(uuidv4());
            setActivePage("edit");
          }} //goBack}
          tooltip={"Назад (отменить)"}
          className={classes.margin}
        />
      );
    }
    return listButtons;
  };

  // Роутер компонентов
  const routerComponent = () => {
    if (activePage === "visible") {
      return (
        <FFLoaderComponent
          loading={scenarios.length}
          isLoader={
            <Typography
              variant="h1"
              component="h2"
              className={classes.defaultText}
            >
              {"Доступные сценарии отсутствуют"}
            </Typography>
          }
          component={
            <FF_TableScenarios
              scenarios={scenarios}
              activeScenario={activeScenario}
              setActiveScenario={setActiveScenario}
              metaParam={streamParam}
            />
          }
        />
      );
    } else if (activePage === "history") {
      return (
        <FF_History
          key={"FF_History"}
          devices={childrenActiveEssence}
          setDataToModifyHistory={setDataToModifyHistory}
          isLogin={isLogin}
          handleClickVariant={handleClickVariant}
        />
      );
    } else {
      return (
        <FF_EditBaseObject
          key={"FF_EditBaseObject"}
          key={updateHard}
          isTypeModel={modelId === baseModel_v1 ? 0 : 1}
          isLogin={isLogin}
          scenario={activeScenario}
          devicesScenarioAll={devicesScenarioAll}
          devices={childrenActiveEssence}
          devicesScenario={deviceActiveScenario}
          handleClickVariant={handleClickVariant}
          activePage={activePage}
          setActivePage={setActivePage}
          activeEssence={activeEssence}
          parameters={parameters}
        />
      );
    }
  };
  return (
    <Context.Provider
      value={{
        doClickActiveEssence,
        doGoPositionScroll,
        doClickMetaSelect,
        toGoSave,
        toGoSaveAlgorithm,
      }}
    >
      <Backdrop className={classes.backdrop} open={openBackDrop}>
        <FFLoader />
      </Backdrop>

      {isOpenDialogDelete && (
        <FFDialogDelete
          open={isOpenDialogDelete}
          setOpen={setIsOpenDialogDelete}
          isAgree={(e) => doDelete()}
        />
      )}

      {openIsNotSave ? (
        <DialogIsNotSave
          open={openIsNotSave}
          setOpen={setOpenIsNotSave}
          doNotSave={doNotSave}
        />
      ) : null}
      {openChart ? (
        <DialogOpenChart
          open={openChart}
          setOpen={setOpenChart}
          activeScenario={activeScenario}
          activeEssence={activeEssence}
          doNotSave={doNotSave}
        />
      ) : null}
      {openCalc ? (
        <DialogCalc
          open={openCalc}
          setOpen={setOpenCalc}
          activeScenario={activeScenario}
        />
      ) : null}

      {openComment ? (
        <DialogComment
          scenarios={scenarios}
          activeScenario={activeScenario}
          open={openComment}
          setOpen={setOpenComment}
          doAddComment={(comment) => doAddComment(comment)}
        />
      ) : null}
      {openModelInterface.length ? (
        <DialogModelInterface
          open={openModelInterface}
          ListModel={ListModel}
          setOpen={setOpenModelInterface}
          doSelectModelInterface={(modelInterface) => {
            setOpenModelInterface([]);
            // console.log(modelInterface)
            setModelInterface(modelInterface);
          }}
        />
      ) : null}
      {openSettings ? (
        <DialogEditSettings
          open={openSettings}
          setOpen={setOpenSettings}
          settings={activeScenario?.kpi?.savings}
          doDialogEditSettings={(value) => doDialogEditSettings(value)}
          // doAddComment={comment => doAddComment(comment)}
        />
      ) : null}

      <Grid container>
        <Grid item xs={0}></Grid>
        <Grid item xs={12} align="right">
          {routerButtonByActivePage().map((buttonByActivePage) => {
            return buttonByActivePage;
          })}
        </Grid>
      </Grid>
      <Paper className={classes.root}>
        <FFContainer
          is_row={true}
          id={"all"}
          components={[
            {
              width: 3,
              elevation: 2,
              component: (
                <FFContainer
                  is_row={true}
                  id={"tree"}
                  components={[
                    {
                      width: 12,
                      elevation: 0,
                      style: !essenceLoading
                        ? { justifyContent: "center", alignItems: "center" }
                        : { justifyContent: "stretch", alignItems: "stretch" },
                      component: (
                        <FFLoaderComponent
                          key={"FFLoaderComponent"}
                          loading={essenceLoading}
                          isLoader={<FFLoader />}
                          component={
                            <FFTreeEssence essence={essence} doubleClick={1} />
                          }
                        />
                      ),
                    },
                  ]}
                />
              ),
            },
            {
              width: 9,
              elevation: 2,
              style:
                activeEssence && loadingScenarios && scenarios.length
                  ? { justifyContent: "stretch", alignItems: "stretch" }
                  : {
                      justifyContent: "center",
                      alignItems: "center",
                      textAlign: "center",
                    },
              component: (
                <FFLoaderComponent
                  loading={activeEssence}
                  isLoader={
                    <Typography
                      variant="h1"
                      component="h2"
                      className={classes.defaultText}
                    >
                      {"Объект не выбран"}
                    </Typography>
                  }
                  component={
                    <FFLoaderComponent
                      loading={loadingScenarios}
                      isLoader={<FFLoader />}
                      component={routerComponent()}
                    />
                  }
                />
              ),
            },
          ]}
        />
      </Paper>
    </Context.Provider>
  );
}

const FF_TableScenarios = ({
  scenarios,
  activeScenario,
  setActiveScenario,
  metaParam,
}) => {
  const classes = useStyles();

  const headColumns = [
    "Наименование сценария",
    "Описание",
    "Сроки проекта",
    "Целевые показатели",
    "Дата валидирования",
  ];

  const TableCellStyle = withStyles({})((props) => (
    <TableCell
      {...props}
      style={{ fontWeight: props.isBold ? "bold" : "normal" }}
      align="left"
    />
  ));

  return (
    <React.Fragment>
      {metaParam ? (
        <div style={{ display: "flex", flexDirection: "row" }}>
          {Object.keys(metaParam).map((name) => {
            return (
              <div
                style={{ display: "flex", flexGrow: 1, alignItems: "stretch" }}
              >
                <FFTextField
                  style={{ flexGrow: 1 }}
                  label={name}
                  value={metaParam[name]}
                  is_disabled={true}
                />
              </div>
            );
          })}
        </div>
      ) : null}
      <Table stickyHeader size="small" aria-label="FF_TableScenarios">
        <TableHead>
          <TableRow>
            {headColumns.map((label) => {
              return (
                <TableCell key={label} align="center">
                  {label}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {scenarios.map((scenario) => (
            <TableRow
              key={"TableRow" + scenario.id}
              onClick={(e) => setActiveScenario(scenario)}
              className={
                activeScenario?.id === scenario.id
                  ? classes.isActiveRowTable
                  : new Date(scenario.date_validation_calc).getTime() <
                    new Date().getTime()
                  ? classes.isExpiredRotTable
                  : null
              }
            >
              <TableCellStyle key={"label"} isBold={scenario.is_active}>
                {scenario.label}
              </TableCellStyle>

              <TableCellStyle key={"description"} isBold={scenario.is_active}>
                {scenario.description}
              </TableCellStyle>

              <TableCellStyle key={"date_calc"} isBold={scenario.is_active}>
                {`${new Date(
                  scenario.date_start_calc
                ).toLocaleString()} - ${new Date(
                  scenario.date_validation_calc
                ).toLocaleString()}`}
              </TableCellStyle>

              <TableCellStyle key={"kpi"} isBold={scenario.is_active}>
                {scenario.kpi
                  ? scenario.kpi.savings
                    ? scenario.kpi.savings.map((obj, index) => (
                        <React.Fragment key={"kpi_" + uuidv4()}>
                          {`${obj.value} (${obj.label})`}
                          <br />
                        </React.Fragment>
                      ))
                    : null
                  : null}
              </TableCellStyle>

              <TableCellStyle key={"author"} isBold={scenario.is_active}>
                {`Автор: ${scenario.author}, Дата создания: ${new Date(
                  scenario.date_creation
                ).toLocaleString()}`}
              </TableCellStyle>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </React.Fragment>
  );
};

const DialogIsNotSave = ({ open, setOpen, doNotSave, ...props }) => {
  return (
    <Dialog open={open ? true : false} maxWidth={"sm"} fullWidth={true}>
      <DialogTitle>
        {"Вы действительно не хотите сохранить измененные сведения?"}
      </DialogTitle>
      <DialogActions style={{ justifyContent: "center" }}>
        <Button onClick={(e) => doNotSave()} color="secondary">
          {"Уйти без сохранения"}
        </Button>
        <Button onClick={(e) => setOpen(null)} color="primary">
          {"Вернутся к редактированию"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const DialogComment = ({
  open,
  setOpen,
  doAddComment,
  scenarios,
  activeScenario,
  ...props
}) => {
  const [comment, setComment] = useState(
    "Новый коментарий " + new Date().toLocaleString()
  );
  const changeValue = (value, id, name, element) => {
    setComment(value);
  };

  const counter = scenarios.filter(
    (obj) =>
      obj.model_interface === activeScenario.model_interface &&
      obj.is_active === true
  );
  const temp = scenarios.find(
    (obj) =>
      obj.model_interface === activeScenario.model_interface &&
      obj.id !== activeScenario.id &&
      obj.is_active === true
  );
  return (
    <Context.Provider value={{ changeValue }}>
      <Dialog open={open ? true : false} maxWidth={"sm"} fullWidth={true}>
        <DialogTitle>{"Введите комментарий для сохранения?"}</DialogTitle>
        <DialogContent>
          <DialogContentText
            style={{ flexDirection: "column", display: "flex" }}
          >
            {counter.length > 1 ? (
              <Alert severity="warning">
                <AlertTitle>{"Внимание"}</AlertTitle>
                {
                  "Текущий сценарий использует модельный интрефейс, который используется \
другим активным сценарием."
                }
                &nbsp;
                <strong>
                  {`При сохрании активный сценарий (${temp.label}) перестанет быть активным.`}
                </strong>
              </Alert>
            ) : null}
            <FFTextField
              value={comment}
              id={"comment"}
              name={"commnet"}
              label={"Комментариий"}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={(e) => doAddComment(comment)} color="primary">
            {"Сохранить"}
          </Button>
          <Button onClick={(e) => setOpen(null)} color="primary">
            Вернутся к редактированию
          </Button>
        </DialogActions>
      </Dialog>
    </Context.Provider>
  );
};

const DialogModelInterface = ({
  open,
  setOpen,
  doSelectModelInterface,
  ...props
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const handleClickVariant = (variant, msg) => {
    enqueueSnackbar(
      msg,
      { variant },
      {
        anchorOrigin: { vertical: "top", horizontal: "right" },
      }
    );
  };

  const [modelIntId, setModelIntId] = useState(null);

  const changeValue = (value, id, name, element) => {
    setModelIntId(value);
  };

  return (
    <Context.Provider value={{ changeValue }}>
      <Dialog
        open={open.length ? true : false}
        maxWidth={"sm"}
        fullWidth={true}
      >
        <DialogTitle>{"Выбирите модельный интерфейc?"}</DialogTitle>
        <DialogContent>
          <DialogContentText
            style={{ flexDirection: "column", display: "flex" }}
          >
            <FFSelect
              value={modelIntId}
              label={"Наименование модели"}
              options={open}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={(e) => {
              modelIntId
                ? doSelectModelInterface(modelIntId)
                : handleClickVariant("warning", "Выберите модельный интерфей");
            }}
            color="primary"
          >
            {"Сохранить"}
          </Button>
          <Button onClick={(e) => setOpen([])} color="primary">
            Вернутся к редактированию
          </Button>
        </DialogActions>
      </Dialog>
    </Context.Provider>
  );
};

const DialogEditSettings = ({
  open,
  setOpen,
  doDialogEditSettings,
  ...props
}) => {
  const classes = useStyles();

  const [settings, setSettings] = useState([]);

  const [tempSett, setTempSett] = useState(null);

  useEffect(() => {
    setTempSett(constructSetting());
  }, [settings]);

  useEffect(() => {
    setSettings(
      props.settings.map((sett) => {
        return { ...sett, id: uuidv4() };
      })
    );
  }, []);

  const constructSetting = () => {
    return {
      id: uuidv4(),
      label: "",
      label_eng: "",
      system_name: "",
      value: 0,
    };
  };

  const goDelete = (id) => {
    setSettings(settings.filter((obj) => obj.id !== id));
  };

  const changeValue = (value, id, name, element) => {
    let temp = settings.filter((obj) => obj.id === id);

    if (!temp.length) {
      tempSett[name] =
        name === "value" && !isNaN(Number(value)) ? Number(value) : value;
      setSettings([...settings, { ...tempSett }]);
      setTempSett(null);
    } else {
      temp[0][name] =
        name === "value" && !isNaN(Number(value)) ? Number(value) : value;
    }
    return name === "value" && isNaN(Number(value)) ? "Введите число" : "";
  };

  const rowSetting = (sett) => {
    if (sett)
      return (
        <Grid item xs={12} container id={sett.id}>
          <Grid item xs={3} container direction={"column"}>
            <FFTextField
              value={sett.label}
              id={sett.id}
              name={"label"}
              label={"Наименование"}
            />
          </Grid>
          <Grid item xs={3} container direction={"column"}>
            <FFTextField
              value={sett.label_eng}
              id={sett.id}
              name={"label_eng"}
              label={"Наименование лат."}
            />
          </Grid>
          <Grid item xs={3} container direction={"column"}>
            <FFTextField
              value={sett.system_name}
              id={sett.id}
              name={"system_name"}
              label={"Сис. наим."}
            />
          </Grid>
          <Grid item xs={2} container direction={"column"}>
            <FFTextField
              value={sett.value}
              id={sett.id}
              name={"value"}
              label={"Значение"}
            />
          </Grid>
          <Grid item xs={1} container direction={"column"}>
            <FFActionButton
              type={"delete"}
              onClick={() => goDelete(sett.id)} //goDelete}
              tooltip={"Удалить строку"}
              className={classes.margin}
              color={"error"}
            />
          </Grid>
        </Grid>
      );
  };

  return (
    <Context.Provider value={{ changeValue }}>
      <Dialog open={open ? true : false} maxWidth={"sm"} fullWidth={true}>
        <DialogTitle>{"Введите комментарий?"}</DialogTitle>
        <DialogContent>
          <Grid item xs={12} container>
            {settings.map((sett) => {
              return rowSetting(sett);
            })}
            {rowSetting(tempSett)}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={(e) => doDialogEditSettings(settings)}
            color="secondary"
          >
            Сохранить
          </Button>
          <Button onClick={(e) => setOpen(false)} color="primary">
            Не сохранять
          </Button>
        </DialogActions>
      </Dialog>
    </Context.Provider>
  );
};

const DialogCalc = ({ open, setOpen, ...props }) => {
  console.log(props);
  const { enqueueSnackbar } = useSnackbar();
  const handleClickVariant = (variant, msg) => {
    enqueueSnackbar(
      msg,
      { variant },
      {
        anchorOrigin: { vertical: "top", horizontal: "right" },
      }
    );
  };

  const { isLogin } = useContext(Context);

  const [startCalc, setStartCalc] = useState(
    moment().startOf("month").format("YYYY-MM-DD")
  );
  const [finishCalc, setFinishCalc] = useState(moment().format("YYYY-MM-DD"));
  const [te, setTe] = useState(null);
  const changeValue = (value, id, name, elem) => {
    if (name === "startCalc") setStartCalc(value);
    if (name === "finishCalc") setFinishCalc(value);
  };

  return (
    <Context.Provider value={{ changeValue }}>
      <Dialog open={open}>
        <DialogTitle>{"Пересчет базовой линии"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Grid container item xs={12} spacing={1}>
              <Grid item xs={6}>
                <FFTextField
                  value={startCalc}
                  id={"startCalc"}
                  isDate="date"
                  name={"startCalc"}
                  label={"Начало"}
                />
              </Grid>
              <Grid item xs={6}>
                <FFTextField
                  value={finishCalc}
                  id={"finishCalc"}
                  isDate="date"
                  name={"finishCalc"}
                  label={"Конец"}
                />
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={startCalc === "" && finishCalc === "" ? true : false}
            onClick={(e) => {
              // setBlockButton(1)
              ApiConnect({
                name: "getData",
                url:
                  urlBaseLine +
                  "/calc?sc_id=" +
                  props.activeScenario.id +
                  "&start_date=" +
                  startCalc +
                  "&finish_date=" +
                  finishCalc,
                setterEssence: setTe,
                setterLoading: null,
                isLogin: isLogin,
                clickHandle: handleClickVariant,
              });
              handleClickVariant("success", "Запрос на пересчет отправлен");
              setOpen(false);

              // getData(
              //   'http://213.59.222.245:8878/calc?sc_id=' +
              //     baseObj.filter(obj => obj.id === activeRow)[0].id +
              //     '&start_date=' +
              //     startCalc +
              //     '&finish_date=' +
              //     finishCalc,
              //   null,
              //   setBlockButton
              // )
            }}
            color="secondary"
          >
            Пересчет
          </Button>
          <Button onClick={(e) => setOpen(false)} color="primary">
            Вернутся к редактированию
          </Button>
        </DialogActions>
      </Dialog>
    </Context.Provider>
  );
};

const DialogOpenChart = ({ open, setOpen, ...props }) => {
  const { enqueueSnackbar } = useSnackbar();
  const handleClickVariant = (variant, msg) => {
    enqueueSnackbar(
      msg,
      { variant },
      {
        anchorOrigin: { vertical: "top", horizontal: "right" },
      }
    );
  };

  const { isLogin } = useContext(Context);

  const [startCalc, setStartCalc] = useState(
    moment().add(-1, "month").startOf("month").format("YYYY-MM-DD")
  );
  const [finishCalc, setFinishCalc] = useState(moment().format("YYYY-MM-DD"));
  const [seriesByActiveEssence, setSeriesByActiveEssence] = useState(undefined);
  const changeValue = (value, id, name, elem) => {
    if (name === "startCalc") setStartCalc(value);
    if (name === "finishCalc") setFinishCalc(value);
  };

  const getCharts = (width, height, type, title) => {
    let tempMeta = {
      chart: {
        // id: uuidv4(),
        type: type,
        height: height,
        width: width,
        zoomType: "x",
        //   margin: [0, 0, 0, 0]
      },
      title: {
        text: title,
        style: {
          fontSize: "1.5em",
          fontFamily: "roboto",
        },
      },
      // colors: Highcharts.map(Highcharts.getOptions().colors, function (color) {
      //   return {
      //     radialGradient: {
      //       cx: 0.5,
      //       cy: 0,
      //       r: 1
      //     },
      //     stops: [
      //       [0, color],
      //       [
      //         1,
      //         Highcharts.color(color)
      //           .brighten(0.3)
      //           .get('rgb')
      //       ]
      //     ]
      //   }
      // }),
      xAxis: {
        // gridLineColor: styleTheme.default,
        // minorTickInterval: 'auto',
        labels: "",
      },
      yAxis: {
        // startOnTick: false,
        // endOnTick: false,
        // visible: false,
        // min: 0,
        // max: sum * 10,
        title: {
          text: "",
        },
      },
      legend: {
        enabled: false,
      },
      plotOptions: {
        series: {
          borderRadius: 4,
        },
      },
      series: [],
    };
    return tempMeta;
  };

  const getChartMetricActiveEssence = () => {
    if (seriesByActiveEssence) {
      let tempChart = getCharts(500, 400, "line", props.activeEssence.label);
      tempChart.xAxis = [
        {
          minorTickInterval: "auto",
          type: "datetime",
          reversed: false,
        },
      ];
      tempChart.legend.enabled = true;
      tempChart.series = [
        {
          name: "Базовая линия",
          data: seriesByActiveEssence["bl_data"].map((obj) => [obj[1], obj[0]]),
        },
        {
          name: "Потребление",
          data: seriesByActiveEssence["input_data"].map((obj) => [
            obj[1],
            obj[0],
          ]),
        },
        {
          name: "БЛ генерирующая мощность",
          data: seriesByActiveEssence["bl_gen_data"].map((obj) => [
            obj[1],
            obj[0],
          ]),
        },
        {
          name: "БЛ сетевая мощность",
          data: seriesByActiveEssence["bl_net_data"].map((obj) => [
            obj[1],
            obj[0],
          ]),
        },
        {
          name: "Генерирующая мощность",
          data: seriesByActiveEssence["gen_data"].map((obj) => [
            obj[1],
            obj[0],
          ]),
        },
        {
          name: "Сетевая мощность",
          data: seriesByActiveEssence["net_data"].map((obj) => [
            obj[1],
            obj[0],
          ]),
        },
      ];
      return <HighchartsReact key={tempChart?.chart?.id} options={tempChart} />;
    }
    if (seriesByActiveEssence === null) {
      return <FFLoader />;
    }
    return null;
  };

  return (
    <Context.Provider value={{ changeValue }}>
      <Dialog open={open}>
        <DialogTitle>{"График"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Grid container item xs={12} spacing={1}>
              <Grid item xs={5} style={{ display: "flex" }}>
                <FFTextField
                  value={startCalc}
                  style={{ flexGrow: 1 }}
                  id={"startCalc"}
                  isDate="date"
                  name={"startCalc"}
                  label={"Начало"}
                />
              </Grid>
              <Grid item xs={5} style={{ display: "flex" }}>
                <FFTextField
                  style={{ flexGrow: 1 }}
                  value={finishCalc}
                  id={"finishCalc"}
                  isDate="date"
                  name={"finishCalc"}
                  label={"Конец"}
                />
              </Grid>
              <Grid
                item
                xs={2}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Button
                  variant="outlined"
                  disabled={
                    startCalc === "" || finishCalc === "" ? true : false
                  }
                  onClick={(e) => {
                    setSeriesByActiveEssence(null);
                    ApiConnect({
                      name: "getData",
                      url: `${urlBaseLine}/graph?sc_id=${props.activeScenario.id}&start_date=${startCalc}&end_date=${finishCalc}`,
                      setterEssence: setSeriesByActiveEssence,
                      setterLoading: null,
                      isLogin: isLogin,
                      clickHandle: handleClickVariant,
                    });
                  }}
                  color="secondary"
                >
                  Загрузить
                </Button>
              </Grid>
              <Grid
                item
                xs={12}
                alignItems="center"
                justify="center"
                style={{ display: "flex" }}
              >
                {getChartMetricActiveEssence()}
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={(e) => setOpen(false)} color="primary">
            Вернутся к редактированию
          </Button>
        </DialogActions>
      </Dialog>
    </Context.Provider>
  );
};
