import React, { useEffect, useState, useContext } from "react";
import Context from "./context";
import FFCreatePanel from "./TestConfig";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { api } from "./api/api";

import {
  Paper,
  Typography,
  TableContainer,
  Grid,
  Divider,
  IconButton,
  Button,
  Tooltip,
  Drawer,
  // Dialog import
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  // Card import
  Card,
  CardHeader,
  CardMedia,
  CardContent,
  CardAction,
  Collapse,
  Avatar,
  Backdrop,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
} from "@material-ui/core";

import { red } from "@material-ui/core/colors";

import IconSettings from "@material-ui/icons/Settings";
import IconDynamicFeed from "@material-ui/icons/DynamicFeed";
import IconDelete from "@material-ui/icons/Delete";
import IconLibraryAdd from "@material-ui/icons/LibraryAdd";
import IconAdd from "@material-ui/icons/Add";
import IconSave from "@material-ui/icons/Save";
import IconCancel from "@material-ui/icons/Cancel";
import IconRefresh from "@material-ui/icons/Refresh";
import IconAccountTree from "@material-ui/icons/AccountTree";

import FavoriteIcon from "@material-ui/icons/Favorite";
import IconLaunch from "@material-ui/icons/Launch";

import ShareIcon from "@material-ui/icons/Share";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import FFSelectTree from "./components/FFSelectTree";

import FFLoaderComponent from "./components/FFLoaderComponent";
import FFToggle from "./components/FFToggleSimple";
import FFTextFields from "./components/FFTextFields";
import FFRouterComponents from "./components/FFRouterComponents";
import FFTreeEssence from "./components/FFTreeEssence";
import FFTreeEssenceChecked from "./components/FFTreeEssenceChecked";
import FFPanels from "./components/FFPanels";

import FFSwitch_N from "./components/FFSwitch_N";
import FFToogleStyle from "./components/FFToogleStyle";

import { SnackbarProvider, useSnackbar } from "notistack";
import TreeNodeUtils from "tree-node-utils";

import clsx from "clsx";

import cookie from "react-cookies";
import Loader from "./Loader";

import { ApiConnect, urlConfiguration } from "./ApiConnectNew";
import { useSelector } from "react-redux";
import { getIsFull } from "../features";

import {v4 as uuidv4} from 'uuid'

const useStyles = makeStyles({
  margin: {
    marginTop: "10px",
    marginLeft: "10px",
    marginBottom: "5px",
  },
  flex: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  flexRow: {
    display: "flex",
    flexDirection: "row",
    height: "100%",
  },
  end: {
    flexGrow: 3,
  },
  tree: {
    padding: 8,
    margin: 8,
  },
  scroller: {
    // maxHeight: '-webkit-fill-available',
    overflow: "overlay",
  },
  backdrop: {
    zIndex: 101,
    color: "#C0C0C0",
  },
});

const useStylesPanels = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  expandHeader: {
    backgroundColor: theme.palette.primary.light,
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
  },
}));

const drawerWidth = 360;

const useStylesDrav = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));

export default function FF_Essence(props) {
  // -------------------------------------------------------------------------------
  //
  //                                   Стили
  //
  // -------------------------------------------------------------------------------
  // console.log(props)
  const classes = useStyles();
  const classesDrav = useStylesDrav();

  const config = {
    childrenField: "children",
    keyField: "id",
  };
  const treeUtils = new TreeNodeUtils(config);

  // -------------------------------------------------------------------------------
  //
  //                                 Состояния
  //
  // -------------------------------------------------------------------------------

  const strAuthorization = "Bearer " + cookie.load("access_token");
  const { isLogin } = useContext(Context);
  const [essence, setEssence] = useState([]);
  const [essenceLoading, setEssenceLoading] = useState(false);
  const [activeEssence, setActiveEssence] = useState(null);
  const [classifierTypeData, setClassifierTypeData] = useState([]);
  const [panels, setPanels] = useState([]);
  const [modernPanel, setModernPanel] = useState([]);
  const [te, setTe] = useState(0);
  const [activePanel, setActivePanel] = useState(null);
  const [openTree, setOpenTree] = useState(true);
  const [edit, setEdit] = useState(false);
  const [open, setOpen] = useState(false);
  const [depthMax, setDepthMax] = useState(1);
  const [curParent, setCurParent] = useState("");
  const [position, setPosition] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [select, setSelect] = useState("label");
  const [openBackDrop, setOpenBackDrop] = useState(false);
  const isFull = useSelector(getIsFull);

  // useEffect(() => {
  //   if (panels && panels.length)
  //   setOpenBackDrop(false)
  // }, [panels])

  useEffect(() => {
    if (
      JSON.parse(localStorage.getItem("powerInputsByManage")) &&
      essence.length
    ) {
      let temp = JSON.parse(localStorage.getItem("powerInputsByManage"));
      doClickActiveEssence(temp.id, temp.obj);
    }
  }, [essence]);

  useEffect(() => {
    // ApiConnect({
    //   name: 'getData',
    //   url:
    //     urlConfiguration +
    //     '/api/support/info/?entity=essence&action=select&info=tree&parameters=true',
    //   setterEssence: setClassifierTypeData,
    //   // setterLoading: setEssenceLoading,
    //   isLogin: isLogin
    // })
    doRefresh();
  }, []);

  // -------------------------------------------------------------------------------
  //
  //                                 Снэк Бар
  //
  // -------------------------------------------------------------------------------

  const { enqueueSnackbar } = useSnackbar();
  const handleClickVariant = (variant, msg) => {
    // variant could be success, error, warning, info, or default
    enqueueSnackbar(
      msg,
      { variant },
      {
        anchorOrigin: { vertical: "top", horizontal: "right" },
      }
    );
  };

  function sortByLabel(arr) {
    arr.sort((a, b) => (a.label > b.label ? 1 : -1));
  }

  // -------------------------------------------------------------------------------
  //
  //                                 Обработчики событий
  //
  // -------------------------------------------------------------------------------

  // Рекурсивное получение потомков от нажатого элемента
  const getRecurEssence = (node, ids, depth) => {
    if ("type-essence" in node && node["type-essence"] !== "client") {
      ids.push(node.id);
    }
    // if (depth < depthMax) {
    node.children.map((nodeTemp) => {
      getRecurEssence(nodeTemp, ids, depth + 1);
    });
    // }
  };

  // Нажатие на классификационное значение в меню
  const doClickActiveEssence = (objId, obj) => {
    if (!isFull) {
      enqueueSnackbar(`Недостаточно прав`,{ variant: 'error' });
      return;
    }
    let ids = [];
    localStorage.removeItem("powerInputsByManage");
    let node = treeUtils.getNodeByKey(essence, objId);
    if (node) {
      getRecurEssence(node, ids, 0);
    }

    if (ids.length > 10) {
      handleClickVariant(
        "warning",
        `Вы хотите загрузить ${ids.length} панелей, пока мы можем грузить до 10 панелей`
      );
      return;
    }

    if (ids.length > 0) {
      setPanels([]);
      setModernPanel([]);
      setOpenBackDrop(true);
      setIsOpen(false);
      setActiveEssence(obj);

      // updateModernPanel(obj)
      setActivePanel(null);
      ApiConnect({
        name: "getDataPost",
        url: urlConfiguration + "/api/control/modern_panels/",
        objToGet: { essences: ids },
        setterEssence: (value) => {
          sortByLabel(value["obj"]);
          setPanels(value["obj"]);
        },
        setterLoading: () => setOpenBackDrop(false),
        // setTimeout(() => {
        //   setOpenBackDrop(false)
        // }, 5000),
        clickHandle: handleClickVariant,
        isLogin: isLogin,
      });
    } else {
      // console.log('Click ', obj)
      // setClient(obj)
      setActiveEssence(null);
    }
    // console.log(activeEssence)
  };

  // Обработчик для обновления
  const doRefresh = () => {
    setTimeout(() => {
      // getData(
      //   '/api/support/info/?entity=essence&action=select&info=tree',
      //   setEssence,
      //   setEssenceLoading
      // )
      // getData('/api/?entity=template_essence', setTemplates, null)
      // ApiConnect({
      //   name: 'getData',
      //   url:
      //     urlConfiguration + '/api/support/info/?entity=essence&action=select&info=tree',
      //   setterEssence: setEssence,
      //   setterLoading: setEssenceLoading,
      //   isLogin: isLogin
      // })
      // api.essence.get_tree({
      //   isLogin,
      //   callback: setEssence,
      //   callbackLoading: setEssenceLoading,
      // });
      api.essence.filter({
        body: { is_panel: true },
        callback: setEssence,
        callbackLoading: setEssenceLoading,
        isLogin: isLogin,
      });
      setTe(te + 1);
    }, 100);
  };

  const doGoPositionScroll = (tempPosition) => {
    // console.log(position)
    setPosition(tempPosition);
  };

  const doClickMetaSelect = (tempSelect) => {
    setSelect(tempSelect);
  };
  // const doAddEssence = () => {
  //   if (activeEssence) {
  //     let tempId = uuidv4()
  //     let es = activeEssence.id
  //     let tempPanel = {
  //       children: [],
  //       client: activeEssence.client,
  //       connections: {},
  //       description: 'new_description' + tempId.split('-')[0],
  //       essence: activeEssence.id,
  //       id: tempId,
  //       is_complex: false,
  //       is_deleted: false,
  //       label: 'new_label' + tempId.split('-')[0],
  //       label_eng: 'new_label_eng' + tempId.split('-')[0],
  //       model: null,
  //       name: 'new_name' + tempId.split('-')[0],
  //       product: null,
  //       type: 'panel',
  //       position: {
  //         w: 10,
  //         h: 40,
  //         x: 0,
  //         y: 0,
  //         static: true
  //       }
  //     }
  //     tempPanel.connections[activeEssence.id] = {
  //       simpleParam: [],
  //       streamParam: [],
  //       timeStreamParam: []
  //     }
  //     ApiConnect({
  //       name: 'createData',
  //       url: urlConfiguration + '/api/?entity=modern_panel',
  //       objToCreate: {
  //         ...tempPanel,
  //         meta: {
  //           position: {
  //             w: 10,
  //             h: 40,
  //             x: 0,
  //             y: 0,
  //             static: true
  //           },
  //           children: [],
  //           type: 'panel'
  //         }
  //       },
  //       // setterLoading: setEssenceLoading,
  //       isLogin: isLogin,
  //       clickHandle: handleClickVariant
  //     })
  //     // updateModernPanel()
  //     setModernPanel([...modernPanel, tempPanel])
  //     // console.log(panels)
  //   } else {
  //     handleClickVariant('warning', 'Выберите сущность')
  //   }
  // }

  // const updateModernPanel = obj => {
  //   // setTimeout(() => {

  //   if (obj === null) {
  //     obj = activeEssence
  //   }
  //   if (obj) {
  //     ApiConnect({
  //       name: 'getData',
  //       url: urlConfiguration + '/api/?entity=modern_panel',
  //       setterEssence: allPanels => {
  //         setModernPanel(allPanels.filter(temp => temp.essence === obj.id))
  //       },
  //       // setterLoading: setEssenceLoading,
  //       isLogin: isLogin
  //     })
  //   }
  //   // }, 0)
  // }

  // const doSaveEssence = obj => {
  //   // // console.log(activePanel)
  //   // console.log({
  //   //   ...activePanel,
  //   //   meta: {
  //   //     position: activePanel.position,
  //   //     children: activePanel.children,
  //   //     type: activePanel.type
  //   //   }
  //   // })
  //   if (activePanel) {
  //     ApiConnect({
  //       name: 'updateDate',
  //       url: urlConfiguration + '/api/?entity=modern_panel',
  //       objToUpdate: {
  //         ...activePanel,
  //         meta: {
  //           position: activePanel.position,
  //           children: activePanel.children,
  //           type: activePanel.type
  //         }
  //       },
  //       // setterLoading: setEssenceLoading,
  //       isLogin: isLogin,
  //       clickHandle: handleClickVariant
  //     })
  //     // updateModernPanel()
  //   } else {
  //     handleClickVariant('warning', 'Выберите панель управления')
  //   }
  // }

  // const doDeleteEssence = () => {
  //   if (activePanel) {
  //     // setPanels([])
  //     ApiConnect({
  //       name: 'deleteDate',
  //       url: '/api/?entity=modern_panel&id=' + activePanel.id,
  //       objToDelete: { id: activePanel.id },
  //       // setterLoading: setEssenceLoading,
  //       isLogin: isLogin,
  //       clickHandle: handleClickVariant
  //     })
  //     setActivePanel(null)

  //     // updateModernPanel()
  //     setModernPanel(modernPanel.filter(obj => obj.id !== activePanel.id))
  //     setPanels(panels.filter(obj => obj.id !== activePanel.id))
  //   } else {
  //     handleClickVariant('warning', 'Выберите панель управления')
  //   }
  //   // doRefresh()
  // }

  // useEffect(() => {
  //   let temp = modernPanel.fil
  //   {
  //     ...activePanel,
  //     position: activePanel.position,
  //     type: activePanel.type,
  //     children: activePanel.children
  //   }
  // }, [activePanel])

  // const doChangeSelect = (par, obj) => {
  //   console.log(par, obj)
  //   setCurParent(obj)
  // }

  // -------------------------------------------------------------------------------
  //
  //                                    Рендеринг
  //
  // -------------------------------------------------------------------------------

  return (
    <Context.Provider
      value={{
        doClickActiveEssence,
        doClickMetaSelect,
        doGoPositionScroll,
        // doClickCheckedEssence,
        // changeTextField,
        // getBackToParent
      }}
    >
      <Backdrop className={classes.backdrop} open={openBackDrop}>
        <Grid>
          <Grid style={{ textAlign: "center" }}>
            <Loader />
          </Grid>

          <Grid>
            <Typography variant="h4">
              {"Мы загружаем панели управления"}
            </Typography>
          </Grid>
        </Grid>
      </Backdrop>
      {/* <Dialog
        open={open}
        onClose={e => {
          setOpen(false)
          setCurParent('')
        }}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>
          {'Копирование панелей'}
        </DialogTitle>
        <DialogContent>
          <Grid container item xs={12}>
            <Grid item xs={12}>
              <Context.Provider value={{ doChangeSelect }}>
                <FFSelectTree
                  key={'FFSelectTree_' + 'temp'}
                  stateMeta={{ open: false, active: -1, label: '' }}
                  idCls={'temp'}
                  selectValue={curParent}
                  selectMetaLabel={'Куда скопировать?'}
                  selectNodes={essence}
                />
              </Context.Provider>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={e => {
              setOpen(false)
              setCurParent('')
            }}
            color='error'
          >
            Отмена
          </Button>
          <Button
            onClick={e => {
              setOpen(false)
              setCurParent('')
            }}
            color='primary'
            autoFocus
          >
            Создать
          </Button>
        </DialogActions>
      </Dialog> */}
      <Grid container>
        <Grid item xs={4} align="left">
          <Tooltip title="Отобразить дерево">
            <IconButton
              aria-label="filter"
              onClick={(e) => setOpenTree(!openTree)}
              className={classes.margin}
              // style={{ visibility: isEdit === 0 ? 'visible' : 'hidden' }}
            >
              <IconAccountTree color="primary" fontSize="large" />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item xs={8} align="right">
          {panels.length ? (
            <Tooltip title={isOpen ? "Скрыть панели" : "Раскрыть панели"}>
              <IconButton
                aria-label="filter"
                onClick={(e) => setIsOpen(!isOpen)}
                className={classes.margin}
                // style={{ visibility: isEdit === 0 ? 'visible' : 'hidden' }}
              >
                <IconLaunch
                  color={isOpen ? "disabled" : "primary"}
                  fontSize="large"
                />
              </IconButton>
            </Tooltip>
          ) : null}
          {/* {true === true ? (
            <Tooltip title='Массовое заполнение параметров'>
              <IconButton
                aria-label='filter'
                onClick={e => {
                  setClickChecked(!clickChecked)
                  setListChecked([])
                  setActiveEssence(null)
                  setActiveParameter('')
                  setEssenceParameter([])
                  localStorage.removeItem('parameterByEssences')
                }}
                className={classes.margin}
                style={{ visibility: isEdit === 0 ? 'visible' : 'hidden' }}
              >
                <IconDynamicFeed color='primary' fontSize='large' />
              </IconButton>
            </Tooltip>
          ) : null} */}
          {/* {edit === false ? (
            <Tooltip title='Создать сущность'>
              <IconButton
                aria-label='filter'
                onClick={e => doAddEssence()}
                className={classes.margin}
                // style={{ visibility: isEdit === 0 ? 'visible' : 'hidden' }}
              >
                <IconAdd color='primary' fontSize='large' />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title='Назад (отменить)'>
              <IconButton
                aria-label='filter'
                className={classes.margin}
                onClick={e => {
                  updateModernPanel()
                  setEdit(!edit)
                  // console.log(!edit)
                }}
              >
                <IconCancel color='primary' fontSize='large' />
              </IconButton>
            </Tooltip>
          )} */}
          {/* {true ? (
            <Tooltip title='Создать копию панели'>
              <IconButton
                aria-label='filter'
                onClick={e => doAddCopyEssence()}
                className={classes.margin}
                // style={{ visibility: isEdit === 0 ? 'visible' : 'hidden' }}
              >
                <IconLibraryAdd color='primary' fontSize='large' />
              </IconButton>
            </Tooltip>
          ) : null} */}
          {/* {activePanel !== null ? (
            <Tooltip title='Сохранить'>
              <IconButton
                aria-label='filter'
                onClick={e => doSaveEssence()}
                className={classes.margin}
              >
                <IconSave color='primary' fontSize='large' />
              </IconButton>
            </Tooltip>
          ) : null} */}
          {/* {!clickChecked && essenceParameter.length ? (
            <IconButton
              aria-label='filter'
              onClick={e => doSaveEssenceParameter()}
              className={classes.margin}
            >
              <IconSave color='primary' fontSize='large' />
            </IconButton>
          ) : null}  */}
          {/* {edit === false ? (
            <Tooltip title='Обновить'>
              <IconButton
                aria-label='filter'
                // onClick={e => doRefresh()}
                className={classes.margin}
                // style={{ visibility: isEdit === 0 ? 'visible' : 'hidden' }}
              >
                <IconRefresh color='primary' fontSize='large' />
              </IconButton>
            </Tooltip>
          ) : null} */}
          {/* {edit === false && activePanel !== null ? (
            <Tooltip title='Удалить'>
              <IconButton
                aria-label='filter'
                onClick={e => doDeleteEssence()}
                className={classes.margin}
                // style={{ visibility: isEdit === 0 ? 'visible' : 'hidden' }}
              >
                <IconDelete color='error' fontSize='large' />
              </IconButton>
            </Tooltip>
          ) : null} */}
        </Grid>
      </Grid>

      <TableContainer
        className={clsx(classes.flex, classes.end)}
        // style={{ overflow: 'hidden' }}
      >
        {/* <Drawer
          className={classesDrav.drawer}
          variant='persistent'
          anchor='left'
          open={openTree}
          classes={{
            paper: classesDrav.drawerPaper
          }}
          onClose={e => setOpenTree(false)}
          // anchor={'left'}
          // open={openTree}
          // onClose={e => setOpenTree(false)}
        >
          <Paper
            elevation={3}
            id={'bodyTree_paper'}
            className={clsx(classes.flex, classes.end)}
            style={{
              padding: 10,
              margin: 10,
              width: 360,
              overflow: 'overlay',
              overflowX: 'hidden'
            }}
          >
            <FFLoaderComponent
              key={'treeEssence'}
              loading={essenceLoading}
              isLoader={<Loader />}
              component={
                <FFTreeEssence
                  essence={essence}
                  doubleClick={1}
                  metaSelect={select}
                  active={activeEssence?.id}
                  position={position}
                />
              }
            />
          </Paper>
        </Drawer> */}
        <Grid
          container
          id={"body_grid"}
          className={[classes.flexRow, classes.end]}
        >
          {openTree ? (
            <Grid
              item
              xs={3}
              id={"bodyTree_grid"}
              style={{ padding: 10 }}
              className={[classes.flex, classes.end]}
            >
              <Paper
                elevation={3}
                id={"bodyTree_paper"}
                style={{ padding: 10 }}
                className={clsx(classes.flex, classes.end)}
                // className={[classes.tree, classes.end]}
                // className={clsx(classes.flex, classes.end,classes.tree)}
              >
                <FFLoaderComponent
                  key={"treeEssence"}
                  loading={essenceLoading}
                  isLoader={<Loader />}
                  component={
                    <FFTreeEssence
                      essence={essence}
                      doubleClick={1}
                      metaSelect={select}
                      active={activeEssence?.id}
                      position={position}
                    />
                  }
                />
                {/* <FFLoaderComponent
                // key={te}
                loading={essenceLoading}
                isLoader={<Loader />}
                component={
                  <FFLoaderComponent
                    key={'treeEssence'}
                    loading={essenceLoading}
                    isLoader={<Loader />}
                    component={
                      <FFTreeEssence
                        essence={essence}
                        doubleClick={1}
                        metaSelect={select}
                        active={activeEssence?.id}
                        position={position}
                      />
                    }
                  />
                }
              /> */}
              </Paper>
            </Grid>
          ) : null}
          <Grid
            item
            xs={openTree ? 9 : 12}
            id={"bodyMain_grid"}
            style={{ padding: 10 }}
            className={clsx(classes.flex, classes.end)}
          >
            {/* <Grid item xs={9} style={{ padding: 10, overflow: 'overlay' }}> */}
            <Paper
              elevation={3}
              className={clsx(classes.flex, classes.end)}
              style={{ overflow: "overlay", margin: 8, padding: 8 }}
            >
              <FFLoaderComponent
                key={"Panels&Active"}
                loading={activeEssence}
                isLoader={
                  <Typography
                    variant="h1"
                    component="h2"
                    style={{ color: "lightgray", marginTop: "20%" }}
                  >
                    Выберите сущность
                  </Typography>
                }
                component={
                  <FFLoaderComponent
                    key={"Panels"}
                    loading={panels.length}
                    isLoader={
                      <Typography
                        variant="h1"
                        component="h2"
                        style={{ color: "lightgray", marginTop: "20%" }}
                      >
                        Доступные панели отсутствуют
                      </Typography>
                    }
                    component={
                      <FFPanels
                        // key={'panels' + isOpen}
                        isLogin={isLogin}
                        isOpen={isOpen}
                        openWidth={openTree ? 9 : 12}
                        meta={panels}
                        classifierTypeData={classifierTypeData}
                        essence={activeEssence}
                      />
                    }
                  />
                }
              />
            </Paper>
          </Grid>
        </Grid>
      </TableContainer>
    </Context.Provider>
  );
}
