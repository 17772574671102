import React, { useEffect, useState } from 'react'
import {
  Grid,
  Typography,
  Slider,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText
} from '@material-ui/core'

import { useTheme } from '@material-ui/core/styles'

/*
 **-------------------- Highcharts ---------------------------
 */
import HighchartsReact from 'highcharts-react-official'

/*
 **-------------------- My Components ---------------------------
 */

import FFTextField from '../../../libft/FFTextField'
import FFActionButton from '../../../libft/FFActionButton'

/*
 **------------------------- Utils ------------------------------
 */
import moment from 'moment'
import 'moment/locale/ru'
import Context from '../../../context'
import { SnackbarProvider, useSnackbar } from 'notistack'
import { Calendar, CalendarControls } from 'react-yearly-calendar'
import '../../../styles/Calendar.css'
import {v4 as uuidv4} from 'uuid'


const getNullValue = () => {
  let i = -1
  let value = []
  while (++i < 24) value.push(-1)
  return value
}

export default function FFTester ({ schedule, getSchedule, ...props }) {
  const defTheme = useTheme()

  const [scale, setScale] = useState(0.9)
  const [currentYear, setCurrentYear] = useState(2020)
  const [select, setSelect] = useState([moment(), moment()])
  const [changeDay, setChangeDay] = useState([])
  const [openDialogEdit, setOpenDialogEdit] = useState(false)
  const [sch, setSch] = useState(schedule)
  const [schStyle, setSchStyle] = useState(null)

  // Применение стилей при изменении расписания
  useEffect(() => {
    if (sch) {
      setSchStyle({
        imbak: sch ? Object.keys(sch) : []
      })
      getSchedule(sch)
    }
  }, [sch])

  const onPrevYear = () => {
    setCurrentYear(currentYear - 1)
  }

  const onNextYear = () => {
    setCurrentYear(currentYear + 1)
  }

  const rangePicked = (start, end) => {
    let tempChange = changeDay
    let temp_date = start
    let ones = start.valueOf() - end.valueOf()
    while (temp_date.valueOf() <= end.valueOf()) {
      tempChange.push(
        String(temp_date.month() + 1) +
          '/' +
          temp_date.date() +
          '/' +
          temp_date.year()
      )
      if (temp_date.valueOf() === end.valueOf()) {
        break
      }
      temp_date = temp_date.add(1, 'day')
    }
    if (ones === 0) {
      setOpenDialogEdit(true)
    }
    setChangeDay(Array.from(new Set(tempChange)))
    selectDays()
    setSelect([start, end])
  }

  // Выделение дней и подкраска
  const selectDays = () => {
    changeDay.map((obj, ind) => {
      let a = document.getElementById(obj)
      if (a) {
        a.style.background = defTheme.palette.primary.light
        a.style.color = defTheme.palette.text.primary
      }
    })
  }

  // Снять выделение
  const desSelectDays = () => {
    changeDay.map((obj, ind) => {
      let a = document.getElementById(obj)
      if (a) {
        if (Array.from(a.classList).indexOf('imbak') === -1)
          // a.style.color = 'black'
          // a.style.background = ''
          a.style.color = defTheme.palette.text.primary
        a.style.background = '' //defTheme.palette.background.default
      }
    })
    setChangeDay([])
  }

  // Удаление выбранных дней из расписания
  const doDeleteDay = () => {
    changeDay.map(t => {
      delete sch[moment(t).format('YYYY-MM-DD')]
    })
    setSch({ ...sch })
  }

  const getValue = () => {
    let tempValue = null
    changeDay.map(t => {
      let tempDate = moment(t).format('YYYY-MM-DD')
      if (tempDate in sch) {
        tempValue = sch[tempDate]
      }
    })
    if (!tempValue) tempValue = getNullValue()
    return tempValue
  }

  // Событие при нажатии на кнопку сохранить в диалоге
  const agreeEditSchedule = valueSchedule => {
    changeDay.map(t => {
      sch[moment(t).format('YYYY-MM-DD')] = valueSchedule
    })
    setSch({ ...sch })
  }

  return (
    <React.Fragment>
      <Grid item xs={12} container>
        <Grid item xs={12}>
          <FFActionButton
            type={'prev'}
            onClick={onPrevYear}
            tooltip={'Предыдущий год'}
          />
          {currentYear}
          <FFActionButton
            type={'next'}
            onClick={onNextYear}
            tooltip={'Следующий год'}
          />
          <FFActionButton
            type={'refresh'}
            onClick={desSelectDays}
            tooltip={'Снять выделение'}
          />
          <FFActionButton
            type={'edit'}
            onClick={() => setOpenDialogEdit(true)}
            tooltip={'Редактировать'}
          />
          <FFActionButton
            type={'delete'}
            onClick={doDeleteDay}
            tooltip={'Удалить'}
            color={'error'}
          />
        </Grid>
        <Grid
          item
          xs={12}
          style={{
            margin: defTheme.spacing(1),
            overflow: 'overlay'
          }}
        >
          <Calendar
            style={{
              transform: `scale(${scale})`,
              transformOrigin: '0 0'
            }}
            year={currentYear}
            showDaysOfWeek={true}
            firstDayOfWeek={0}
            selectRange={true}
            selectedRange={select}
            customClasses={schStyle}
            onPickRange={(start, end) => rangePicked(start, end)}
          />
        </Grid>
      </Grid>

      {openDialogEdit ? (
        <DialogEditSchedule
          tempValue={getValue()}
          open={openDialogEdit}
          setOpen={setOpenDialogEdit}
          agreeEditSchedule={agreeEditSchedule}
        />
      ) : null}
    </React.Fragment>
  )
}

const DialogEditSchedule = ({
  tempValue,
  open,
  setOpen,
  agreeEditSchedule,
  ...props
}) => {
  const [metaChart, setMetaChart] = useState({ value: null, id: uuidv4() })
  const [valueSeries, setValueSeries] = useState(tempValue)
  const [rangeTime, setRangeTime] = useState([5, 15])
  const [oneValue, setOneValue] = useState('0')

  useEffect(() => {
    console.log('temp', valueSeries)
    if (valueSeries) {
      let xAxis = []
      let i = -1
      while (++i < 24) xAxis.push(`${i < 10 ? 0 : ''}${i}:00`)
      let tempMetaChart = {
        chart: {
          type: 'line',
          height: 200
        },
        title: {
          text: ''
        },
        xAxis: {
          categories: xAxis
        },
        yAxis: {
          title: {
            text: 'кВтч'
          }
        },
        series: [{ name: 'Значение', data: valueSeries }]
      }
      setMetaChart({ value: tempMetaChart, id: uuidv4() })
    }
  }, [valueSeries])

  const { enqueueSnackbar } = useSnackbar()
  const handleClickVariant = (variant, msg) => {
    enqueueSnackbar(
      msg,
      { variant },
      {
        anchorOrigin: { vertical: 'top', horizontal: 'right' }
      }
    )
  }

  const doAgreeDialog = () => {
    if (valueSeries.filter(x => x < 0).length) {
      handleClickVariant(
        'warning',
        'Установите положительные значения для всего периода'
      )
      return
    }
    agreeEditSchedule(valueSeries)
    setOpen(false)
  }

  // Нажатие на кнопку задать значение
  const onSet = () => {
    if (isNaN(Number(oneValue))) {
      handleClickVariant('warning', 'Введите число пожалуйста')
      return
    }
    let temp = valueSeries
    let i = rangeTime[0]
    while (i <= rangeTime[1]) {
      temp[i++] = Number(oneValue)
    }
    setValueSeries([...temp])
  }

  // Изменение значения вводимого в текствое поле
  const changeValue = (value, id, name, element) => {
    if (name === 'oneValue') {
      setOneValue(value)
    }
  }

  return (
    <Dialog open={open} onClose={e => setOpen(false)}>
      <DialogTitle>{'Расписание'}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Context.Provider value={{ changeValue }}>
            <div style={{ width: '100%', minWidth: 500 }} key={metaChart?.id}>
              <HighchartsReact options={metaChart?.value} key={metaChart?.id} />
              <Grid container xs={12}>
                <Grid item xs={4} style={{ alignSelf: 'center' }}>
                  <Typography>{'Временной период'}</Typography>
                </Grid>
                <Grid item xs={4}>
                  <FFTextField
                    value={oneValue}
                    id={'oneValue'}
                    name={'oneValue'}
                    label={'Значение'}
                  />
                </Grid>
                <Grid item xs={4} style={{ alignSelf: 'center' }}>
                  <Button onClick={() => onSet()}>{'Задать значение'}</Button>
                </Grid>
              </Grid>
              <Grid item xs={12} style={{ marginTop: 24 }}>
                <Slider
                  value={rangeTime}
                  max={23}
                  step={1}
                  min={0}
                  marks
                  onChange={(event, newValue) => setRangeTime(newValue)}
                  valueLabelDisplay='on'
                />
              </Grid>
            </div>
          </Context.Provider>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={e => setOpen(false)} color='primary'>
          Отмена
        </Button>
        <Button onClick={e => doAgreeDialog()} color='primary' autoFocus>
          Создать
        </Button>
      </DialogActions>
    </Dialog>
  )
}

// ---------------------------Выделение месяца по клику---------------------------
//
//
//
//   const selectMonth = num_month => {
//     let tempChange = changeDay
//     let start = moment(num_month + '/1/' + '' + currentYear)
//     while (start.month() === num_month - 1) {
//       tempChange.push(
//         String(start.month() + 1) + '/' + start.date() + '/' + start.year()
//       )
//       if (start.month() !== num_month - 1) {
//         break
//       }
//       start.add(1, 'day')
//     }
//     setChangeDay(Array.from(new Set(tempChange)))
//     selectDays()
//   }

//   const doClick = (event, index) => {
//     selectMonth(index + 1)
//   }

//   const Fresh = () => {
//     let indexMonth = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]
//     setTimeout(() => {
//       indexMonth.map(index => {
//         let a = document.getElementById('month' + index)
//         if (a) {
//           a.onclick = e => doClick(e, index)
//         }
//       })
//     }, 1)
//   }
// -----------------------------------------------------------------------------
