import { combineReducers } from "redux";
import authLogin from "../../features/auth-login/slice";
import { EssenceToEssenceReducer } from "../../features/mapping-essence-to-essence/slice";
import mappingEssenceToDC from "../../features/mapping-essence-to-dc/slice";
import { slice as scadaCreateEssence } from "../../features/scada/slice";

export const rootReducer = combineReducers({
  authLogin: authLogin,
  mappingEssenceToEssence: EssenceToEssenceReducer,
  mappingEssenceToDC: mappingEssenceToDC,
  scadaCreateEssence: scadaCreateEssence.reducer,
});
