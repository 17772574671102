import React from "react";
import { ForceGraph2D } from "react-force-graph";
import { Drawer } from "antd";

export const Preview = ({ open, setOpen, data: initLinks }) => {
  const nodes = {};
  const links = [];
  initLinks.forEach(([source, target]) => {
    if (source && target) {
      if (!nodes[source]) {
        nodes[source] = 0;
      }
      nodes[source] += 1;
      if (!nodes[target]) {
        nodes[target] = 0;
      }
      nodes[target] += 1;
      links.push({ source, target, label: "", id: `${source}-${target}` });
    }
  });

  const data = {
    nodes: Object.keys(nodes).map((id) => ({ id: id })),
    links,
  };

  return (
    <Drawer
      title={"Превью"}
      placement="bottom"
      size={"large"}
      onClose={() => setOpen(false)}
      open={open}
    >
      <ForceGraph2D
        graphData={data}
        nodeLabel={(node) => node.id}
        // nodeCanvasObject={(node, ctx, globalScale) => {
        //   const label = node.id;
        //   const fontSize = 12 / globalScale;
        //   ctx.font = `${fontSize}px Sans-Serif`;
        //   const textWidth = ctx.measureText(label).width;
        //   const bckgDimensions = [textWidth, fontSize].map(
        //     (n) => n + fontSize * 0.2
        //   ); // some padding

        //   ctx.fillStyle = "#fafafa";
        //   ctx.fillRect(
        //     node.x - bckgDimensions[0] / 2,
        //     node.y - bckgDimensions[1] / 2,
        //     ...bckgDimensions
        //   );

        //   ctx.textAlign = "center";
        //   ctx.textBaseline = "middle";
        //   ctx.fillStyle = 'black';
        //   ctx.fillText(label, node.x, node.y);

        //   node.__bckgDimensions = bckgDimensions; // to re-use in nodePointerAreaPaint
        // }}
      />
    </Drawer>
  );
};
